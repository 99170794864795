import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useEffect, useRef } from 'react';

const isEmptyString = (data) => typeof data === "string" && data.trim().length === 0;

const RedirectForm = () => {
  const submitbtn = useRef(null);
  const { t } = useTranslation()
  const refreshToken = useSelector(state => state.ccgApiReducer.refreshToken)
  const redirectURL = useSelector(state => state.ccgApiReducer.redirectURL)
  const accessToken = useSelector(state => state.ccgApiReducer.accessToken)
  const isLoaded = useSelector(state => state.ccgApiReducer.isLoaded)

  useEffect(() => {
    if (isLoaded && redirectURL && !isEmptyString(redirectURL) && refreshToken && !isEmptyString(refreshToken) && accessToken && !isEmptyString(accessToken)) {
      submitbtn.current.click()
    }
  }, [submitbtn, isLoaded, refreshToken, redirectURL, accessToken])



  return (
    <>
      <form action={redirectURL} method="POST">
        <input type="text" name="token" defaultValue={accessToken} />
        <input type="text" name="refreshToken" defaultValue={refreshToken} />
        <input type="text" name="url" defaultValue="LEN:/api/application/RedirectApply" />
        <input type="text" name="httpMethod" defaultValue="post" />
        <button ref={submitbtn} type="submit">{t('frontend.core.submit')}</button>
      </form>
    </>
  )
}

export { RedirectForm }
