import { useEffect } from 'react'
import { useGetOrderDetails } from '@ten24/slatwall-storefront-react-private/hooks/'
import { AccountLayout } from '../AccountLayout/AccountLayout'
import { OrderInformation } from '../OrderInformation/OrderInformation'
import { ContactCard } from '../../ContactCard/ContactCard'

const AccountOrderDetail = props => {
  const orderID = props.path
  let [order, setRequest] = useGetOrderDetails()

  useEffect(() => {
    let didCancel = false
    if (!order.isFetching && !order.isLoaded && !didCancel) {
      setRequest({ ...order, isFetching: true, isLoaded: false, params: { orderID }, makeRequest: true })
    }
    return () => {
      didCancel = true
    }
  }, [order, orderID, setRequest])
  return (
    <div className="container my-lg-4">
      <div className="row account-wrapper flex-lg-row flex-column-reverse">
        <AccountLayout />
        <div className="col-sm-12 col-md-12 col-lg-8 bg-light order-sm-0 main-content p-3">
          <h5 className="ms-2 my-3 brand-blue">ORDER DETAILS</h5>
          <h4 className="ms-2 my-3">Order ID : {order.data.orderInfo?.at(0).orderNumber}</h4>
          {order?.isLoaded && <OrderInformation orderInfo={order?.data?.orderInfo} orderItems={order?.data?.orderItems} orderStatusHistory={order?.data?.orderStatusHistory} />}
          <div className="py-3">
            <ContactCard background="bg-white" />
          </div>
        </div>
      </div>
    </div>
  )
}
export { AccountOrderDetail }
