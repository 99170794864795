import { ListingPagination, ListingListView } from '@ten24/slatwall-storefront-react-private/components'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { useListing } from '@ten24/slatwall-storefront-react-private/hooks'
import { useState } from 'react'
import { ListingGrid } from '../../components/Listing/ListingGrid'
import { ListingToolBar } from '../../components/Listing/ListingToolBar'
import { Layout } from '../../components/Layout/Layout'

const preFilter = {}
const GRID = 'GRID'
const LISTING = 'LISTING'
const SearchKeyword = ({ params, linebreak=false}) => {
  const { t } = useTranslation()
  return <div className="search-title-wrapper d-lg-flex d-block">{<h1 className="h3 m-4 fw-bold font-accent brand-blue fs-13 text-uppercase">{params['keyword'] ? `${t('frontend.header.searchpage.title')}` : `${t('frontend.header.shopStore')}`} {params['keyword'] && (<>{!linebreak && <br />}<span className='h3 brand-navy fw-bold text-lowercase'>{`“${params['keyword']}”`}</span></>)}</h1>}</div>
}
const Search = () => {
  const [viewMode] = useState(GRID)
  const hide = []
  const { records, isFetching, potentialFilters, total, totalPages, setSort, updateAttribute, setPage, params } = useListing(preFilter)
  // const title = params['keyword'] ? `${t('frontend.header.searchpage.title') + ' "' + params['keyword']}"` : `${t('frontend.header.shopStore')}`

  return (
    <Layout>
      <Helmet />
      <div className="container d-none d-lg-block">
        <div className="row justify-content-between">
          <div className='col-5'>
            <SearchKeyword params={params} linebreak={true}/>
          </div>
          <div className="container d-md-block d-none product-listing product-type-relative align-items-center col-7 px-0">
            <div className="container justify-content-end align-items-center d-flex p-0">
              <div className="row search-row-wrapper flex-row-reverse justify-content-between">
                <ListingToolBar hide={hide} filtering={potentialFilters} removeFilter={updateAttribute} setSort={setSort} recordsCount={total} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container product-listing mb-5 mt-4">
        <div className="container p-0">
          <div className="search-row-wrapper flex-row-reverse d-lg-none d-sm-block text-center justify-content-center">
            <ListingToolBar hide={hide} filtering={potentialFilters} removeFilter={updateAttribute} setSort={setSort} recordsCount={total} />
            <SearchKeyword params={params} />
          </div>
        </div>
        {viewMode === LISTING && <ListingListView isFetching={isFetching} pageRecords={records} />}
        {viewMode === GRID && <ListingGrid isFetching={isFetching} pageRecords={records} />}
        <ListingPagination recordsCount={total} currentPage={params['currentPage']} totalPages={totalPages} setPage={setPage} />

      </div>
    </Layout>
  )
}
export default Search
