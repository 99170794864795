import React, { useRef, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import searchIconWhite from '../../assets/images/search-icon-white.svg'
import searchIconGrey from '../../assets/images/search-icon-grey.svg'
import axios from 'axios'
// import { useDebounce } from 'react-use'
import { SlatwalApiService } from '@ten24/slatwall-storefront-react-private/services'
import { Link } from 'react-router-dom'
import { ProductImage } from '@ten24/slatwall-storefront-react-private/components'
import { getProductRoute } from '@ten24/slatwall-storefront-react-private/selectors'
import { useSelector } from 'react-redux'
import { useFormatCurrency } from '@ten24/slatwall-storefront-react-private/hooks/'

const SearchBar = ({ redirectToSearchPage = false, setMobileModalOpen = '', setModalSearched = '', setModalHide = '', classList = '', toggle = true }) => {
  const textInput = useRef(null)
  let navigate = useNavigate()
  const { t } = useTranslation()
  const location = useLocation()
  const [searched, setSearch] = useState('')
  const wrapperRef = useRef()
  const [formatCurrency] = useFormatCurrency({})
  const headers = {}
  var searchCount = 0
  let productSearchCount = 0
  const productRouting = useSelector(getProductRoute)
  const [searchTerm, setSearchTerm] = useState('')
  const [hide, setHide] = useState(false)
  let [opened, setOpened] = useState(false)

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpened(false)
        setHide(false)
      }
    }

    if (hide || opened) document.addEventListener('mousedown', handleClickOutside)
    else document.removeEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  useEffect(() => {
    if (location.search) {
      setOpened(true)
      const { keyword } = queryString.parse(location.search)
      if (keyword) {
        textInput.current.value = keyword
      } else {
        textInput.current.value = ''
      }
    } else {
      setOpened(false)
      textInput.current.value = ''
    }
  }, [location])

  const makeSearch = searchValue => {
    if (redirectToSearchPage) {
      navigate({
        pathname: '/shop',
        search: queryString.stringify({ keyword: searchValue }, { arrayFormat: 'comma' }),
      })
      if (setMobileModalOpen !== '') {
        setMobileModalOpen(false)
      }
      return
    }
    navigate({
      search: queryString.stringify({ ...queryString.parse(location.search), keyword: searchValue }, { arrayFormat: 'comma' }),
    })
    textInput.current.value = ''
  }
  useEffect(() => {
    const getData = setTimeout(() => {
      predictiveSearch(searchTerm)
    }, 1000)
    return () => clearTimeout(getData)
    // eslint-disable-next-line
  }, [searchTerm])

  const predictiveSearch = value => {
    setHide(true)
    if (setModalHide !== '') {
      setModalHide(true)
    }
    let source = axios.CancelToken.source()
    let payload = { keyword: value }
    if (value === '') {
      setHide(false)
      if (setModalHide !== '') {
        setModalHide(false)
      }
      setSearch()
      if (setModalSearched !== '') {
        setModalSearched()
      }
      return null
    }
    SlatwalApiService.products.searchTypeahead(payload, headers, source).then(res => {
      if (res.isSuccess()) {
        setSearch(res.success().data)
        if (setModalSearched !== '') {
          setModalSearched(res.success().data)
        }
      }
    })
  }

  return (
    <form ref={wrapperRef} className={`mb-0 bg-transparent search-bar ${classList}`}>
      <div className={`input-group input-group-lg border-0 ${opened || !toggle ? '' : 'd-none'}`}>
        <button
          onClick={e => {
            e.preventDefault()
            if (textInput.current.value !== '') {
              makeSearch(textInput.current.value)
            } else {
              setOpened(false)
              if (setMobileModalOpen !== '') {
                setMobileModalOpen(false)
              }
            }
          }}
          className="btn bg-white text-black"
          type="button"
        >
          <img className="" src={searchIconGrey} alt="search icon" />
        </button>
        <input
          className={`form-control appended-form-control bg-white rounded-left `}
          type="text"
          ref={textInput}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault()
              makeSearch(textInput.current.value)
            }
          }}
          onChange={e => {
            e.preventDefault()
            setSearchTerm(e.target.value)
          }}
          placeholder={t('frontend.search.placeholdertext')}
          required
        />
        
        {hide && searched && (
          <div className="search-mobile-results-container predictive-search-container p-0 shadow-sm px-2 py-3 d-lg-flex d-none flex-column ">
            <div className="category-results-container px-2">
              <div className="category-header py-2">
                <span className="brand-blue fw-bold small">{t('frontend.blog.categories').toUpperCase()}</span>
              </div>
              <div>
                {!!searched?.potentialFilters?.category?.options && (
                  <>
                    {searched.potentialFilters?.category?.options?.map((option, idx) => {
                      ++searchCount
                      if (searchCount < 5) {
                        return (
                          <Link
                            className="d-flex align-items-center search-prediction-container"
                            to={`/shop?keyword=${option.name}`}
                            key={idx}
                            onClick={() => {
                              setHide(false)
                            }}
                          >
                            <div className="search-text-span py-2">
                              <span className="fw-bold text-dark spelling_suggestion">{option.name}</span>
                            </div>
                            <div className="ms-1">
                              <span className="text-dark spelling_suggestion text-muted">{option.count}</span>
                            </div>
                          </Link>
                        )
                      }

                      return <></>
                    })}
                  </>
                )}
              </div>
            </div>
            {!searched?.products?.elasticSearchError && searched?.products?.length !== 0 && (
              <>
                <hr className="category-product-divider" />
                <div className="products-results-container px-2">
                  <div className="products-header pb-4">
                    <span className="brand-blue fw-bold small">{t('frontend.product.products').toUpperCase()}</span>
                  </div>
                </div>
                <div className="d-flex flex-column px-2">
                  {searched?.products?.map((product, idx, { length }) => {
                    productSearchCount++
                    if (productSearchCount < 4) {
                      return (
                        <React.Fragment key={idx}>
                          <Link to={`/${productRouting}/${product.product_urlTitle}?skuid=${product.skuID}`}>
                            <div className="col-12 d-flex align-items-center justify-content-between w-100">
                              <ProductImage customClass="img-fluid search-card-image col-2" imageFile={product.imageFile} skuID={product.skuID} />
                              <span className="px-1 col-7 brand-navy search-product-name">{product.skuName}</span>
                              <div className="d-flex col-3 flex-column align-items-end">
                                <span className="h5 m-0 brand-navy">{formatCurrency(product.skuPrice)}</span>
                                <span className="text-muted small">{t('frontend.cart.per_week')}</span>
                                <span className="text-muted small">{t('frontend.cart.for_48_month')}*</span>
                              </div>
                            </div>
                          </Link>
                          {length - 1 !== idx && productSearchCount <= 2 && <hr className="m-0 category-product-divider" />}
                        </React.Fragment>
                      )
                    }
                    return <></>
                  })}
                </div>
              </>
            )}
            {!!searched?.spellingSuggestion?.[0] && (
              <Link
                to={`/shop?keyword=${searched?.spellingSuggestion?.[0]?.text}`}
                onClick={() => {
                  setHide(false)
                }}
              >
                <button className="btn btn-secondary mt-4 brand-navy w-100">{t('frontend.header.searchMobile.seeAllResults.text')}</button>
              </Link>
            )}
          </div>
        )}
      <button
        onClick={e => {
          e.preventDefault()
          setOpened(true)
          textInput.current.focus()
        }}
        className={`btn mb-0 px-2 search-btn ${!opened && toggle ? '' : 'd-none'}`}
        type="button"
      >
        <img className="" src={searchIconWhite} alt="search icon" />
      </button>
      </div>
    </form>
  )
}

export { SearchBar }
