import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import promoIcon from '../../assets/images/promo-percent-icon.png'

const CartPromoBox = ({ onApplyCode, disableInteraction = false }) => {
  const [promoCode, setPromoCode] = useState('')
  const { t } = useTranslation()
  return (
    <div className="col-md-12">
      <div className="row">
        <div className="col-sm-12 p-0">
          <div className="card mb-4 border-none promo-card">
              <form className="m-0"
                onSubmit={e => {
                  e.preventDefault()
                  onApplyCode(promoCode, setPromoCode)
                }}
              >
                
                <div className="input-group input-group-lg bg-light border-none promo-code px-2">
                <span className="d-flex align-items-center"><img src={promoIcon} width= "15px" height= "15px" alt="Promo icon" /><input disabled={disableInteraction} className="form-control appended-form-control" type="text" placeholder="Promo code" value={promoCode} required onChange={e => setPromoCode(e.target.value)} /></span>
                  <span className="input-group-append">
                    <button
                      className="btn btn-link"
                      onClick={e => {
                        e.preventDefault()
                        onApplyCode(promoCode, setPromoCode)
                      }}
                      type="button"
                    >
                      {t('frontend.cart.apply')}
                    </button>
                  </span>
                </div>
              </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export { CartPromoBox }
