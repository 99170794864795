import { useState, useEffect } from 'react'
import { axios, sdkURL, SlatwalApiService } from '@ten24/slatwall-storefront-react-private/services'
import { toast } from 'react-toastify'
import { getErrorMessage } from '@ten24/slatwall-storefront-react-private/utils'
import { getPostalCodeInfo, hasValidPostalCode, getPostalCodeInfoFromURL } from '../../../utils'
import { useDispatch } from 'react-redux'

const setShippingLocation = (postalCodeData, setRedirectRequired) => {
  return async dispatch => {
    let source = axios.CancelToken.source()
    axios({
      method: 'POST',
      withCredentials: true,
      url: `${sdkURL}api/scope/setShippingLocation`,
      cancelToken: source.token,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        countryCode: postalCodeData?.countryCode,
        postalCode: postalCodeData?.postalCode,
        stateCode: postalCodeData?.stateCode,
        city: postalCodeData?.city,
      },
    }).then(response => {
      if (response?.status === 200) {
        if (!!response?.data?.isRedirectRequired) {
          setRedirectRequired(true)
          setTimeout(() => {
            window.location.replace(`${response?.data?.redirectURL}`)
          }, 5000)
        } else if (response?.data?.addressInZone) {
          window.location.replace(window.location.href)
        } else {
          toast.error('Error')
        }
      }
    })
    return () => {
      source.cancel()
    }
  }
}

export const usePostalCode = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState([])
  const [postalCodeData, setPostalCodeData] = useState({})
  const [isRedirectRequired, setRedirectRequired] = useState(false)
  const dispatch = useDispatch()

  const dispatchShippingLocation = postalCodeData => {
    dispatch(setShippingLocation(postalCodeData, setRedirectRequired))
  }

  useEffect(() => {
    var data = hasValidPostalCode() ? getPostalCodeInfo() : getPostalCodeInfoFromURL()
    setPostalCodeData(data)
  }, [])

  const handleSearch = query => {
    setIsLoading(true)

    SlatwalApiService.sdkScope.httpService
      .slatwallResponse('get', `api/ccg/getShippingPostalCodes`, { query: query }, {}, null)
      .then(response => {
        setIsLoading(false)
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
        if (response.isSuccess() && Object.keys(response.success()?.postalCodes || {}).length) {
          var items = []
          response.success().postalCodes?.reverse().forEach(element => {
            items[`${element.address_postalCode} ${element.address_city}`] = element
          })

          setOptions(response.success().postalCodes)
        }
      })
      .catch(err => {
        setIsLoading(false)
      })
  }

  return { handleSearch: handleSearch, isLoading: isLoading, options: options, selected: selected, setSelected: setSelected, postalCodeData: postalCodeData, setPostalCode: dispatchShippingLocation, isRedirectRequired: isRedirectRequired }
}
