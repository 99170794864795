import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { Header } from '../Header/Header'
import logo from '../../assets/images/radiorentals-logo.png'
import { Footer } from '../Footer/Footer'
const Layout = ({ theme='default', classNameList = '', children }) => {
  const siteName = useSelector(state => state.configuration.site.siteName)
  return (
    <>
      {theme === 'default' && <Header logo={logo} mobileLogo={logo} />}
      <Helmet title={siteName} />
      <ToastContainer />
      <div style={{ minHeight: '600px' }} className={`${classNameList}`}>
        {children}
      </div>
      <Footer />
    </>
  )
}

export { Layout }
