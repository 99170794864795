
import { useTranslation } from 'react-i18next'
import { AccountLayout } from '../AccountLayout/AccountLayout'

const AccountMakePayment = ({ crumbs, title }) => {
  const { t } = useTranslation()

  return (
  <>
  <div className="container mt-0 mt-sm-4 text-white">
    <div className="row account-wrapper flex-lg-row flex-column-reverse">
    <AccountLayout crumbs={crumbs} title={title} />
    <div className="col-lg-8 col-md-8 col-sm-12 p-0 bg-blue account-summary px-3 py-4">
        <h2 className="h3 brand-navy d-none d-lg-block py-2 text-white">{t('frontend.account.makeAPayment.title')}</h2>
        <p className="mt-4 mb-2">{t('frontend.account.makeAPayment.pleaseContact')} <a href="tel:131181" className="text-white fw-bold" to="tel:131181">{t('frontend.account.makeAPayment.phoneNumber')}</a> {t('frontend.account.makeAPayment.toProcessPayment')}</p>
        <h5 className="brand-blue">{t('frontend.account.profileCenter.marketingPreferences')}</h5>
        <a href="tel:131181" className="col-md-6 col-sm-12 btn light-blue mt-2" >{t('frontend.account.makeAPayment.call')}</a>
      </div>
    </div>
  </div>
  </>
  )
}

export { AccountMakePayment }
