import { SlatwalApiService } from '@ten24/slatwall-storefront-react-private/services'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Layout } from '../Layout/Layout'
import { getErrorMessage } from '@ten24/slatwall-storefront-react-private/utils'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import goGreenImageRR from '../../assets/images/new-gogreen-page-banner-rr.jpg'
import goGreenImage from '../../assets/images/new-gogreen-page-banner-radio-rentals.jpg'
import { getCurrentSite } from '../../actions/configActions'

const GoGreenForm = () => {
  const { goGreen } = useSelector(state => state.configuration.forms)
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const currentSiteCode = getCurrentSite()
  const [loading, setLoading] = useState(false)
  let navigate = useNavigate()
  const goGreenSchema = Yup.object().shape(
    {
      firstname: Yup.string().required('Please enter your name.'),
      lastname: Yup.string().required('Please enter your name.'),
      dob: Yup.string().required('Please enter your dob.'),
      email: Yup.string().email('Invalid email').required('Please enter a valid email address.'),
      confirmemail: Yup.string()
        .email('Invalid email')
        .oneOf([Yup.ref('email'), null], 'Please ensure the emails are matched correctly.'),
      accountnumber: Yup.string()
        .required("This account number doesn't seem right. Please enter again.")
        .matches(/^[0-9]+$/, 'Must be only digits')
        .min(8, "This account number doesn't seem right. Please enter again.")
        .max(8, "This account number doesn't seem right. Please enter again."),
      homenumber: Yup.string().matches(phoneRegExp, 'Please enter a valid phone number. E.g. 0234567890').when(['worknumber', 'mobilenumber'], {
        is: (worknumber, mobilenumber) => !worknumber && !mobilenumber,
        then: Yup.string().required('Please enter a valid phone number. E.g. 0234567890'),
      }),
      worknumber: Yup.string().matches(phoneRegExp, 'Please enter a valid phone number. E.g. 0234567890').when(['homenumber', 'mobilenumber'], {
        is: (homenumber, mobilenumber) => !homenumber && !mobilenumber,
        then: Yup.string().required('Please enter a valid phone number. E.g. 0234567890'),
      }),
      mobilenumber: Yup.string().matches(phoneRegExp, 'Please enter a valid phone number. E.g. 0234567890').when(['homenumber', 'worknumber'], {
        is: (homenumber, worknumber) => !homenumber && !worknumber,
        then: Yup.string().required('Please enter a valid phone number. E.g. 0234567890'),
      }),
    },
    [
      ['homenumber', 'worknumber'],
      ['homenumber', 'mobilenumber'],
      ['worknumber', 'mobilenumber'],
    ]
  )
  const formik = useFormik({
    initialValues: {
      formResponse: {
        formID: goGreen,
      },
      context: 'addFormResponse',
      lastname: '',
      homenumber: '',
      accountnumber: '',
      mobilenumber: '',
      dob: '',
      email: '',
      firstname: '',
      confirmemail: '',
      worknumber: '',
      confirmationCheckbox: '',
    },
    validationSchema: goGreenSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true)
      SlatwalApiService.content.addFormResponse(values).then(response => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
        if (response.isSuccess()) {
          toast.success('Form Submitted Successfully')
          resetForm()
          setTimeout(() => {
            navigate('/')
          }, 2000)
        } else {
          setLoading(false)
          toast.error(getErrorMessage(response.success().errors))
        }
      })
    },
  })
  const { t } = useTranslation()

  return (
    <Layout>
      {/* <div className="pt-4 d-md-none d-sm-block">
        <div className="container py-4">
          <div className="order-lg-1 pr-lg-4 text-center">
            <h1 className="h2 mb-0 font-accent text-black">Complaints</h1>
          </div>
        </div>
      </div> */}
      <div className="container-xl container-custom-lg py-4">
        <div className="container-xl p-0">
        {currentSiteCode === 'rr' ? <img className="col-12" src={goGreenImageRR} alt="go green" /> : <img className="col-12" src={goGreenImage} alt="go green" />}
          <div style={{ borderStyle: 'none', borderWidth: '1px', borderRadius: '0px', margin: '0px', padding: '0px' }}>
            <h4 className="text-black" style={{ letterSpacing: 0, lineHeight: '27px', margin: '0 0 20px' }}>
              You can find your account number on any correspondence from us, or contact us on 131 181 during business hours.
            </h4>
          </div>
          <form className="go-green-form border-solid" onSubmit={formik.handleSubmit}>
            <div className="mt-4 mt-lg-0">
              <div className="row mb-4">
                <div className="col-12 col-md-6">
                  <label className="form-label fw-bold brand-blue">Account Number*</label>
                  <input className="form-control" id="accountnumber" value={formik.values.accountnumber} onBlur={formik.handleBlur} onChange={formik.handleChange} name="accountnumber" type="text" required />
                  {formik.errors.accountnumber && <span className="form-error-msg">{formik.errors.accountnumber}</span>}
                </div>
                <div className="col-12 col-md-6 mt-4">
                  Best Contact Number (only one is mandatory*) <br />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12 col-md-6">
                  <label className="form-label fw-bold brand-blue">First Name*</label>
                  <input className="form-control" id="firstname" value={formik.values.firstname} onBlur={formik.handleBlur} onChange={formik.handleChange} maxLength="255" name="firstname" size="20" type="text" required />
                  {formik.errors.firstname && <span className="form-error-msg">{formik.errors.firstname}</span>}
                </div>
                <div className="col-12 col-md-6 mt-4 mt-lg-0">
                  <label className="form-label fw-bold brand-blue" htmlFor="homenumber">
                    Home Number
                  </label>
                  <input className="form-control" id="homenumber" value={formik.values.homenumber} onBlur={formik.handleBlur} onChange={formik.handleChange} maxLength="40" name="homenumber" size="20" type="text" />
                  {formik.errors.homenumber && <span className="form-error-msg">{formik.errors.homenumber}</span>}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12 col-md-6">
                  <label className="form-label fw-bold brand-blue">Last Name*</label>
                  <input className="form-control" id="lastname" value={formik.values.lastname} onBlur={formik.handleBlur} onChange={formik.handleChange} maxLength="80" name="lastname" size="20" type="text" />
                  {formik.errors.lastname && <span className="form-error-msg">{formik.errors.lastname}</span>}
                </div>
                <div className="col-12 col-md-6 mt-4 mt-lg-0">
                  <label className="form-label fw-bold brand-blue">Work Number</label>
                  <input className="form-control" id="worknumber" value={formik.values.worknumber} onBlur={formik.handleBlur} onChange={formik.handleChange} maxLength="18" name="worknumber" size="20" type="text" />
                  {formik.errors.worknumber && <span className="form-error-msg">{formik.errors.worknumber}</span>}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12 col-md-6 mt-4 mt-lg-0">
                  <label className="form-label fw-bold brand-blue">DOB*</label>
                  <input className="form-control" id="dob" value={formik.values.dob} onBlur={formik.handleBlur} onChange={formik.handleChange} name="dob" type="date" required />
                  {formik.errors.dob && <span className="form-error-msg">{formik.errors.dob}</span>}
                </div>
                <div className="col-12 col-md-6 mt-4 mt-lg-0">
                  <label className="form-label fw-bold brand-blue">Mobile Number</label>
                  <input className="form-control" id="mobilenumber" name="mobilenumber" type="text" value={formik.values.mobilenumber} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                  {formik.errors.mobilenumber && <span className="form-error-msg">{formik.errors.mobilenumber}</span>}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-6">
                  <label className="form-label fw-bold brand-blue">Email*</label>
                  <input className="form-control" id="email" value={formik.values.email} onBlur={formik.handleBlur} onChange={formik.handleChange} name="email" type="email" required />
                  {formik.errors.email && <span className="form-error-msg">{formik.errors.email}</span>}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-6">
                  <label className="form-label fw-bold brand-blue">Confirm Email*</label>
                  <input className="form-control" id="confirmemail" value={formik.values.confirmemail} onBlur={formik.handleBlur} onChange={formik.handleChange} name="confirmemail" type="email" required />
                  {formik.errors.confirmemail && <span className="form-error-msg">{formik.errors.confirmemail}</span>}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-12">
                  <label>I agree to receive electronic communications *</label>
                  <div className="d-flex">
                    <input type="checkbox" id="confirmationCheckbox" name="confirmationCheckbox" value={formik.values.confirmationCheckbox} style={{ alignSelf: 'baseline', margin: '12px 0px'}} required />
                    <label className="p-2" htmlFor="confirmationCheckbox">
                      {' '}
                      By ticking this box I consent to receive documents by electronic communication such as email. Electronic communications must be regularly checked for documentation. Your consent may be withdrawn at any time. I have read and understood the privacy authorisation in relation to the use of my personal details.
                    </label>
                    <br />
                  </div>
                  <Link target="_blank" to={'/privacy'}>
                    View Privacy Authorisation
                  </Link>
                  {formik.errors.confirmemail && <span className="form-error-msg">{formik.errors.confirmemail}</span>}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12 col-md-6">
                  <button className="btn btn-primary custom-btn disabled-spinner" type="submit" disabled={loading} name="submit">
                    {t('frontend.contact.form.submit')}
                    <i class="spinner-border spinner-border-sm"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export { GoGreenForm }
