import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getErrorMessage } from '@ten24/slatwall-storefront-react-private/utils'
import { axios, SlatwalApiService } from '@ten24/slatwall-storefront-react-private/services'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'
import { sdkURL } from '@ten24/slatwall-storefront-react-private/services/index'
import { getStateCodeOptionsByCountryCode } from '@ten24/slatwall-storefront-react-private/actions'
import { SwSelect } from '@ten24/slatwall-storefront-react-private/components'
import { getCurrentSite } from '../../actions/configActions'
import { Link } from 'react-router-dom'

const ContactFormSalesforce = () => {
  const [validRecaptcha, setValidRecaptcha] = useState(false)
  const [loading, setLoading] = useState(false)
  const recaptchaSitekey = useSelector(state => state?.configuration?.site?.settings?.siteStorefrontConfig?.siteRecaptchaSiteKey)
  const { t } = useTranslation()
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const stateCodeOptions = useSelector(state => state.content.stateCodeOptions)
  const currentSite = getCurrentSite()
  useEffect(() => {
    dispatch(getStateCodeOptionsByCountryCode('AU'))
    // eslint-disable-next-line
  }, [])
  const { contact } = useSelector(state => state.configuration.forms)
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      postcode: '',
      suburb: '',
      email: '',
      phone: '',
      enquiries: '',
      address: '',
      formResponse: {
        formID: contact,
      },
    },
    onSubmit: (values, { resetForm }) => {
      if (!validRecaptcha) return null
      setLoading(true)
      SlatwalApiService.content.addFormResponse(values).then(response => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
        if (response.isSuccess()) {
          toast.success('Form Submitted Successfully')
          resetForm()
          window.grecaptcha.reset();
          setTimeout(() => {
            navigate('/')
          }, 2000)
        } else {
          toast.error(getErrorMessage(response.success().errors))
        }
        setLoading(false)
      })
    },
  })
  const onChange = recaptchaToken => {
    axios({
      method: 'POST',
      withCredentials: true,
      url: `${sdkURL}api/scope/verifyRecaptcha`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        recaptchaToken,
      },
    }).then(response => {
      if (response?.status === 200 && response?.data?.captchaResult) {
        setValidRecaptcha(true)
      }
    })
  }

  const onExpired = () => {
    setValidRecaptcha(false)
  }

  return (
    <>
      <div className="container-xl p-0 d-none d-lg-block">
        <div className="row">
          <div className="col-md-6 col-sm-12 py-3">
            <div className="d-block mb-3 mb-lg-0 pt-lg-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item ">
                    <Link className="text-nowrap" to="/contact">
                      Contact Us
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xl contact-info p-4 p-lg-5 mb-3">
        {/* <div className="row text-primary">
          <h5>{t('frontend.contact.title')}</h5>
        </div> */}
        <div className="row px-2 px-lg-0">
          <h3 className="brand-navy">CONTACT INFORMATION</h3>
        </div>
        <form className="contact-form" onSubmit={formik.handleSubmit}>
          <input type="hidden" name="orgid" value={formik.values.orgid} />
          <input type="hidden" name="retURL" value={formik.values.retURL} />
          <div className="mt-4  mt-4 mt-lg-0">
            <div className="row mb-4">
              <div className="col-12 col-md-6">
                <label htmlFor="firstName" className="form-label brand-navy">
                  {t('frontend.contact.form.firstName')}*
                </label>
                <input placeholder={t('frontend.contact.form.firstName')} className="form-control" id="firstName" value={formik.values.firstName} onChange={formik.handleChange} maxLength="255" name="firstName" type="text" required />
              </div>
              <div className="col-12 col-md-6 mt-4  mt-4 mt-lg-0">
                <label className="form-label brand-navy">{t('frontend.contact.form.lastName')}*</label>
                <input placeholder={t('frontend.contact.form.lastName')} className="form-control" id="lastName" value={formik.values.lastName} onBlur={formik.handleBlur} onChange={formik.handleChange} maxLength="255" name="lastName" size="20" type="text" required />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12 col-md-6">
                <label className="form-label brand-navy">{t('frontend.contact.form.phoneNumber.title')}*</label>
                <input placeholder={t('frontend.contact.form.phoneNumber.placeholder')} className="form-control" id="phone" value={formik.values.phone} onBlur={formik.handleBlur} onChange={formik.handleChange} maxLength="40" name="phone" size="20" type="text" required />
              </div>
              <div className="col-12 col-md-6 mt-4  mt-4 mt-lg-0">
                <label className="form-label brand-navy">{t('frontend.contact.form.address')}*</label>
                <input placeholder={t('frontend.contact.form.address')} className="form-control" id="address" value={formik.values.address} onBlur={formik.handleBlur} onChange={formik.handleChange} maxLength="255" name="address" size="20" type="text" required />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12 col-md-6">
                <label className="form-label brand-navy" htmlFor="state">
                  {t('frontend.contact.form.state')}*
                </label>
                <SwSelect
                  id="state"
                  required={true}
                  value={formik.values.state}
                  onChange={e => {
                    e.preventDefault()
                    formik.handleChange(e)
                  }}
                  options={currentSite === 'rr' ? [{ name: 'South Australia', value: 'SA' }] : stateCodeOptions['AU']}
                />
              </div>
              <div className="col-12 col-md-6 mt-4  mt-4 mt-lg-0">
                <label className="form-label brand-navy">{t('frontend.contact.form.postcode')}*</label>
                <input placeholder={t('frontend.contact.form.postcode')} className="form-control" id="postcode" value={formik.values.postcode} onBlur={formik.handleBlur} onChange={formik.handleChange} maxLength="18" name="postcode" size="20" type="text" required />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12 col-md-6">
                <label className="form-label brand-navy">{t('frontend.contact.form.suburb')}*</label>
                <input placeholder={t('frontend.contact.form.suburb')} className="form-control" id="suburb" value={formik.values.suburb} onBlur={formik.handleBlur} onChange={formik.handleChange} maxLength="80" name="suburb" size="20" type="text" required />
              </div>
              <div className="col-12 col-md-6 mt-4  mt-4 mt-lg-0">
                <label className="form-label brand-navy">{t('frontend.contact.form.subject')}*</label>
                <input placeholder={t('frontend.contact.form.subject')} className="form-control" id="subject" value={formik.values.subject} onBlur={formik.handleBlur} onChange={formik.handleChange} maxLength="255" name="subject" size="20" type="text" required />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12 col-md-6">
                <label className="form-label brand-navy">{t('frontend.contact.form.emailAddress')}*</label>
                <input placeholder={t('frontend.contact.form.placeholder.emailAddress')} className="form-control" id="email" value={formik.values.email} onBlur={formik.handleBlur} onChange={formik.handleChange} maxLength="80" name="email" size="20" type="email" required />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-12">
                <label className="form-label brand-navy">{t('frontend.contact.form.message')}*</label>
                <textarea placeholder={t('frontend.contact.form.message_placeholder')} className="form-control" id="enquiries" name="enquiries" rows="3" type="text" wrap="soft" value={formik.values.enquiries} onBlur={formik.handleBlur} onChange={formik.handleChange}></textarea>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12 col-md-6">
                <div className="col-12 col-md-6 mb-3 recaptcha">
                  <div className="">{!!recaptchaSitekey && <ReCAPTCHA sitekey={recaptchaSitekey} onChange={onChange} onExpired={onExpired} />}</div>
                </div>
                <div className="col dis-flex justify-content-start">
                  <button className="btn btn-primary custom-btn disabled-spinner" type="submit" name="submit" disabled={loading}>
                    {loading ? t('frontend.contact.form.saving') : t('frontend.contact.form.submit')}
                    <i className="spinner-border spinner-border-sm"></i>
                  </button>
                  <button onClick={() => navigate('/')} className="btn btn-secondary cancel-btn " disabled={loading}>
                    {t('frontend.postcode.cancel')}
                  </button>
                </div>
              </div>
            </div>
            <div className="row contact-footer-contact bg-white">
              <div className="col-md-12">
                {t('frontend.contact.form.footer')} <span className="font-weight-bold brand-navy"><a href='tel:131 181'>{t('frontend.contact.form.footerContact')}</a></span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
export default ContactFormSalesforce
