import { axios, sdkURL } from '@ten24/slatwall-storefront-react-private/services'
import { toast } from 'react-toastify'
import { getErrorMessage } from '@ten24/slatwall-storefront-react-private/utils'
import { Link } from 'react-router-dom'
import { requestCart, receiveCart, addToOrder, getOrder, clearOrderData, removePromoCodeFromOrder } from './orderActions'

const getCookie = cname => {
  let name = cname + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

const addAffiliate = (affiliateCode, skuCode, requestCartRefresh = true) => {
  return async dispatch => {
    if (requestCartRefresh) dispatch(requestCart())
    return await axios({
      method: 'POST',
      withCredentials: true,
      url: `${sdkURL}api/scope/setAffiliate`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        affiliateCode: affiliateCode,
        skuCode: skuCode,
        returnJSONObjects: requestCartRefresh ? 'cart' : '',
      },
    }).then(response => {
      if (response?.status === 200) {
        if (requestCartRefresh) dispatch(receiveCart(response.data.cart))
      }
      return response
    })
  }
}

const getCart = () => {
  return async dispatch => {
    return dispatch(getOrder({})).then(response => {
      if (response.isSuccess() && response.success().errors) toast.error(getErrorMessage(response.success().errors))
      return response
    })
  }
}

const clearCartData = () => {
  return async dispatch => {
    return dispatch(clearOrderData({})).then(response => {
      if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
      return response
    })
  }
}

const removePromoCode = (promotionCode, promotionCodeID, successMsg = '') => {
  return async dispatch => {
    return dispatch(
      removePromoCodeFromOrder({
        params: {
          promotionCode,
          promotionCodeID,
        },
      })
    ).then(response => {
      if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
      if (response.isSuccess()) {
        if (successMsg !== '' && !Object.keys(response.success()?.errors || {}).length) {
          toast.success(successMsg)
        }
      }
      return response
    })
  }
}

const addToCart = (skuID, productName = '', quantity = 1) => {
  return async (dispatch, getState) => {
    const accountCode = getState().cartReducer?.affiliateAccount?.accountCode
    const affiliateAccountCookie = getCookie('affiliateCode')

    if (!accountCode?.length && affiliateAccountCookie.length) {
      const codePair = affiliateAccountCookie.split('=')
      dispatch(addAffiliate(codePair[1], '', false))
    }

    return dispatch(
      addToOrder({
        params: {
          skuID,
          quantity,
        },
      })
    ).then(response => {
      if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
      if (productName !== '')
        if (response.isSuccess())
          toast.success(() => (
            <div className="col-11 d-lg-flex align-items-center">
              <div className="col-md-9 col-sm-12 m-1">
                <p>
                  <b>{productName}</b> was added to your shopping cart
                </p>{' '}
              </div>
              <div className="col-md-3 col-sm-12 m-1">
                <Link className="btn btn-primary btn-block px-4" to="/shopping-cart">
                  View Shopping Cart
                </Link>
              </div>
            </div>
          ))
        else if (response.isSuccess()) toast.success('Added to Cart')
      if (!response.isSuccess()) toast.error('Error')

      return response
    })
  }
}


export { addToCart, getCart, clearCartData, removePromoCode }
