import { AccountLayout } from '../AccountLayout/AccountLayout'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getTranslatedMessage } from '../../../utils'
import { Button } from '@ten24/slatwall-storefront-react-private/components'
import { toast } from 'react-toastify'
import { useState } from 'react'

const AccountProfileCenter = ({ contentBody, contentTitle, crumbs, title }) => {
  const { t } = useTranslation()
  const user = useSelector(state => state.userReducer)
  const [isCheckbox, setCheckboxForMarketing] = useState(false)
  return (
    <div className="container mt-0 mt-sm-4">
      <div className="row account-wrapper flex-lg-row flex-column-reverse">
        <AccountLayout crumbs={crumbs} title={title} />

        <div className="col-sm-12 col-md-12 col-lg-8 bg-light order-sm-0 main-content p-3">
          <h2 className="h3 brand-navy d-lg-block pt-3">{t('frontend.account.profileCenter.title')}</h2>
          <p className="mt-4 mb-3">{t(getTranslatedMessage('frontend.account.profileCenter.blurb'))}</p>

          <div className="container p-3 bg-white rounded">
            <h5 className="brand-blue">{t('frontend.account.profileCenter.marketingPreferences')}</h5>
            <div className="row align-right-responsive">
              <div className="col-4 py-2">
                <span className="fs-14">{t('frontend.contact.form.emailAddress')}</span>
              </div>
              <div className="col-8 py-2 d-table">
                <span className="fs-14">{user?.primaryEmailAddress?.emailAddress}</span>
              </div>
            </div>
            <p className="mb-0 text-muted fs-12">{t(getTranslatedMessage('frontend.account.personal.disclaimer'))}</p>
          </div>
          <div className="row my-2 pe-2">
            <div className="col-sm-12">
              <input className="form-check-input me-2" type="checkbox" value={isCheckbox} id="marketing-consent" onChange={e => setCheckboxForMarketing(e.target.checked)} />
              {t('frontend.account.profileCenter.checkboxText')}
            </div>
            <label className="form-check-label" htmlFor="marketing-consent">
              <Button classList="col-12 col-lg-6 btn btn-primary mt-4" disabled={!!isCheckbox} onClick={() => toast.success('Your account has been unsubscribe successfully!')}>
                {t('frontend.account.profileCenter.unsubscribe')}
              </Button>
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export { AccountProfileCenter }
