import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useFormatCurrency, useFormatDate } from '@ten24/slatwall-storefront-react-private/hooks'
import { useTranslation } from 'react-i18next'
import { isVatCountry } from '@ten24/slatwall-storefront-react-private/selectors'
import { isAuthenticated } from '@ten24/slatwall-storefront-react-private/utils'
import { Button, SimpleImage } from '@ten24/slatwall-storefront-react-private/components'
import { getProductRoute } from '@ten24/slatwall-storefront-react-private/selectors'
import { axios, sdkURL } from '@ten24/slatwall-storefront-react-private/services'
import { ShippingAddressDetails } from '../..'

const OrderItem = ({ quantity, sku_skuID, sku_product_productName, sku_product_urlTitle, images, BrandName, isSeries, ProductSeries, calculatedExtendedPriceAfterDiscount, sku_calculatedSkuDefinition, sku_imageFile, price, sku_product_productID, orderItemID, files, orderChildItems, showActions = true }) => {
  const [formatCurrency] = useFormatCurrency({})
  const { t } = useTranslation()
  const childBundleItems = orderChildItems?.filter(item => item?.parentOrderItem_orderitemID === orderItemID)

  const productRouting = useSelector(getProductRoute)

  return (
    <div className="row border-bottom py-3 justify-content-between align-items-center">
      <div className="col-sm-2 col-3">
        <Link to={`/${productRouting}/${sku_product_urlTitle}?skuid=${sku_skuID}`} className="mx-auto mr-sm-4">
          {images && images?.length > 0 && <SimpleImage className="img-fluid  m-auto image_container productImage w-100" src={images?.at(0)} alt={sku_product_productName} type="product" />}
        </Link>
      </div>
      <div className="col-sm-4 col-9">
        {isSeries && <span className="product-meta d-block font-size-xs pb-1">{ProductSeries}</span>}
        {/* <!--- only show this span if part of a bundled product? ---> */}
        <h3 className="product-title font-size-base mb-2 h5">
          <Link className="link" to={`/${productRouting}/${sku_product_urlTitle}?skuid=${sku_skuID}`}>
            {sku_product_productName}
          </Link>
        </h3>
        {/* <!--- product title ---> */}
        <div className=" font-size-sm">
          {BrandName} <span className="text-muted mr-2">{sku_calculatedSkuDefinition}</span>
        </div>
        {/* <!--- brand / sku ---> */}
        {files && files.length > 0 && isAuthenticated() && (
          <div>
            {files
              .filter(file => file.baseID === sku_product_productID)
              .map(file => (
                <span
                  className="link-primary pe-auto text-decoration-underline"
                  key={file.file_fileID}
                  onClick={e => {
                    e.preventDefault()

                    axios({
                      method: 'POST',
                      url: `${sdkURL}api/scope/downloadFile?fileID=${file.file_fileID}`,
                      responseType: 'blob', // had to add this one here
                    }).then(response => {
                      const url = window.URL.createObjectURL(new Blob([response.data]))
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', `${file.file_fileName}.${file.file_fileType}`)
                      document.body.appendChild(link)
                      link.click()
                    })
                  }}
                >
                  {t('frontend.cart.download')}
                </span>
              ))}
          </div>
        )}
      </div>
      <div className="col-sm-12 col-md-6 d-none d-sm-block">
        <div className="row">
          <div className="col-sm-4">
            <span className="d-block">{`${formatCurrency(price)}`}</span>
            <span className="d-block">{/* <span className="text-muted mr-2">{`($${listPrice} list)`}</span> */}</span>
            {/* <!--- each / list price ---> */}
          </div>
          <div className="col-sm-4">
            <small>{t('frontend.cart.quantity')}</small> {quantity}
          </div>
          <div className="col-sm-4">
            <div className="row">
              <div className="col-12">
                {formatCurrency(calculatedExtendedPriceAfterDiscount)}
                {/* <!--- total ---> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        {childBundleItems &&
          childBundleItems.length > 0 &&
          childBundleItems.map((childBundleItem, key) => {
            return key !== 0 ? (
              <>
                <i className="bi bi-plus-circle col-1 align-self-center"></i>
                <Link key={childBundleItem.OrderItemID} className="col-2" to={`/${productRouting}/${childBundleItem.sku_product_urlTitle}`}>
                  <SimpleImage className="img-fluid  m-auto image_container productImage border border-light" src={childBundleItem.images?.at(0)} alt={childBundleItem?.sku_product_productName} type="product" />
                  <span className="text-dark"> {`${formatCurrency(childBundleItem.price)} x ${childBundleItem.quantity}`}</span>
                  <p>{childBundleItem?.sku_product_productName}</p>
                </Link>
              </>
            ) : (
              <>
                <Link key={childBundleItem.OrderItemID} className="col-2" to={`/${productRouting}/${childBundleItem.sku_product_urlTitle}`}>
                  <SimpleImage className="img-fluid  m-auto image_container productImage border border-light" src={childBundleItem.images?.at(0)} alt={childBundleItem?.sku_product_productName} type="product" />
                  <span className="text-dark"> {`${formatCurrency(childBundleItem.price)} x ${childBundleItem.quantity}`}</span>
                  <p>{childBundleItem?.sku_product_productName}</p>
                </Link>
              </>
            )
          })}
      </div>
    </div>
  )
}

const OrderInformation = ({ orderInfo, orderItems, orderStatusHistory }) => {
  const [formatCurrency] = useFormatCurrency({})
  const [formatDate] = useFormatDate({})
  const showVat = useSelector(isVatCountry)
  const user = useSelector(state => state.userReducer)
  const { t } = useTranslation()
  return (
    <div className="m-2">
      <div className="p-0">
        <div className="row">
          <div className="col-md-12 container">
            <div className="accordion-items bg-white p-3 rounded mb-4 radio-rentals-accordion">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed p-0" type="button" data-bs-toggle="collapse" data-bs-target="#accordion_delivery_status" aria-expanded="true" aria-controls="accordion_delivery_status">
                  Delivery History
                </button>
              </h2>
              <div id="accordion_delivery_status" className="accordion-collapse collapse show" aria-labelledby="">
                <div className="accordion-body shopping-rental-product-page p-0">
                  <div className="d-flex border-bottom py-3 align-items-baseline">
                    <div className="col-8 d-flex align-items-baseline">
                      <div className="p-3 text-success">
                        <i className="bi bi-check-lg"></i>
                      </div>
                      <div className="">
                        <span className="product-title font-size-base mb-2 h5">
                          <h5 className="brand-blue">Order Recieved</h5>
                        </span>
                        <div className=" font-size-sm">
                          {' '}
                          <span className="text-muted mr-2">We’ve got your order!</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 text-right">
                      <span className="text-muted">{formatDate(orderInfo?.at(0)?.orderOpenDateTime)}</span>
                    </div>
                  </div>
                  {orderStatusHistory?.map(status => {
                    return (
                      <>
                        <div className="d-flex border-bottom py-3 align-items-baseline col-12">
                          <div className="col-8 d-flex align-items-baseline">
                            <div className="p-3 text-success">
                              <i className="bi bi-check-lg"></i>
                            </div>
                            <div className="">
                              <span className="product-title font-size-base mb-2 h5">
                                <h5 className="brand-blue">{status?.orderStatusHistoryType_typeName}</h5>
                              </span>
                              <div className=" font-size-sm">
                                {' '}
                                <span className="text-muted mr-2">We’ve got your order!</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-4 text-right">
                            <span className="text-muted">{formatDate(status?.createdDateTime)}</span>
                          </div>
                        </div>
                      </>
                    )
                  })}
                  {/* <button type="button" className="btn btn-primary btn-block">
                <i className="bi bi-arrow-up-right"></i> &nbsp;&nbsp; Track Delivery
              </button> */}
                </div>
              </div>
            </div>
            <div className="bg-white p-3 rounded mb-4">
              <div className="d-flex justify-content-between mb-3">
                <h2 className="h5 text-primary">{t('frontend.order.success.orderSummary')}</h2>
                <span className="small">
                  {orderItems?.length}
                  {orderInfo?.length !== 1 ? <span> {' ' + t('frontend.checkout.items')}</span> : <span> {' ' + t('frontend.checkout.item')}</span>}
                </span>
              </div>
              {orderItems &&
                orderItems.map(orderItem => {
                  return <OrderItem key={orderItem.orderItemID} {...orderItem} showActions={false} />
                })}
            </div>
          </div>
          <div className="col-md-6">
            <div className="bg-white p-3 rounded mb-4">
              <h3 className="h5 text-primary">{t('frontend.checkout.shipping')}</h3>
              <p className="small">
                <strong>{user?.firstName + ' ' + user?.lastName}</strong>
                <br />
                {user?.primaryPhoneNumber?.phoneNumber}
              </p>
              <div className="small m-0">
                <strong>Standard Delivery Included</strong>
                <ShippingAddressDetails />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <ul className="list-group list-group-flush">
              <li className="d-flex justify-content-between align-items-center px-0 py-2">
                <h6 className="my-0 brand-navy subtotal fs-20"> {t('frontend.checkout.subTotal')}</h6>
                <span className="float-end subtotal brand-navy">
                  {orderInfo?.at(0)?.calculatedSubTotal > 0 ? formatCurrency(orderInfo?.at(0)?.calculatedSubTotal) : '--'} <span className="cart-per-Week text-muted">{t('frontend.cart.per_week')}</span>
                </span>
              </li>
              <li className="d-flex justify-content-between align-items-center px-0 py-2">
                <h6 className="my-0 brand-navy small"> {t('frontend.cart.shippingDelivery')}</h6>
                <span className="float-end text-end small">
                  <span className="postcode-included text-muted"> {t('frontend.cart.included')}</span>
                </span>
              </li>
              <li className="d-flex justify-content-between px-0 py-2 border-top">
                <h6 className="my-0 total brand-navy font-weight-bold fs-20">{t('frontend.cart.total')}</h6>
                <span className="subtotal brand-navy small">
                  <span className="font-weight-bold fs-20">{orderInfo?.at(0)?.calculatedTotal > 0 ? formatCurrency(orderInfo?.at(0)?.calculatedTotal) : '--'}</span> <span className="cart-per-Week text-muted">{t('frontend.cart.per_week')}</span>
                </span>
              </li>
              {showVat && (
                <li className="d-flex justify-content-between px-0 py-2">
                  <h6 className="my-0">{t('frontend.cart.vat')}</h6>
                  <span className="float-end">
                    <strong>{orderInfo?.at(0)?.calculatedVATTotal > 0 ? formatCurrency(orderInfo?.at(0)?.calculatedVATTotal) : '--'}</strong>
                  </span>
                </li>
              )}
            </ul>
            <Button classList="btn btn-secondary w-100" onClick={() => window.print()}>
              {t('frontend.account.accountSummary.viewInvoice')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export { OrderInformation }
