import { Button, Overlay, SimpleImage } from '@ten24/slatwall-storefront-react-private/components'
// import { getProductRoute} from '@ten24/slatwall-storefront-react-private/selectors'
import { Link, useLocation } from 'react-router-dom'
import { useAccountOverview, useFormatCurrency, useGetOrderDetails } from '@ten24/slatwall-storefront-react-private/hooks/'
import { useSelector, useDispatch } from 'react-redux'
import { AccountLayout } from '../../../components/Account/AccountLayout/AccountLayout'
import { useState } from 'react'
import { t } from 'i18next'
// import { ShippingAddressDetails } from '../..'
// import { usePostalCode } from '../../../hooks'
import { useTranslation } from 'react-i18next'
import { logout } from '@ten24/slatwall-storefront-react-private/actions/'
import { getMyAccountMenu, getProductRoute, isVatCountry } from '@ten24/slatwall-storefront-react-private/selectors/'
import { useMediaQuery } from 'react-responsive'
import { ShippingAddressDetails } from '../../Checkout/Review/ShippingAddressDetails'
import { useEffect } from 'react'
const isSelectedClass = 'active'

const OrderCard = ({ orderItem, orderInfo }) => {
  const { sku_product_productName, images, sku_product_urlTitle, sku_skuID } = orderItem
  const Orderclass = orderInfo.orderStatusType_typeName === 'Processing' ? 'badge bg-light font-weight-semibold order-status' : 'badge bg-light text-success'
  const productRouting = useSelector(getProductRoute)
  const [formatCurrency] = useFormatCurrency({})

  return (
    <div className="row border-bottom p-3">
      <div className="col-sm-3 col-3">
        <Link to={`/${productRouting}/${sku_product_urlTitle}?skuid=${sku_skuID}`} className="mx-auto mr-sm-4">
          {images && images?.length > 0 && <SimpleImage className="img-fluid  m-auto image_container productImage" src={images?.at(0)} alt={sku_product_productName} type="product" />}
        </Link>
      </div>
      <div className="col-sm-6 col-6">
        <Link className="link" to={`/${productRouting}/${sku_product_urlTitle}?skuid=${sku_skuID}`}>
          {sku_product_productName}
        </Link>
        <br />
        <br />
        <span className={Orderclass}>{orderInfo.orderStatusType_typeName.toUpperCase()}</span>
      </div>
      <div className="col-sm-3 col-3 text-right">
        <span>
          <span className="brand-navy font-weight-bold">{orderInfo?.calculatedTotal > 0 ? formatCurrency(orderInfo?.calculatedTotal) : '--'} </span> <span className="cart-per-Week text-muted">{t('frontend.cart.per_week')}</span>
        </span>
      </div>
    </div>
  )
}

const OrderDetails = ({ orderID }) => {
  let [order, setRequest] = useGetOrderDetails()
  const [formatCurrency] = useFormatCurrency({})
  const showVat = useSelector(isVatCountry)
  const user = useSelector(state => state.userReducer)
  useEffect(() => {
    let didCancel = false
    if (!order.isFetching && !order.isLoaded && !didCancel) {
      setRequest({ ...order, isFetching: true, isLoaded: false, params: { orderID }, makeRequest: true })
    }
    return () => {
      didCancel = true
    }
  }, [order, orderID, setRequest])

  // let orderFulfillmentGroups = formatOrderDetails(order.data)
  let orderInfo = order?.data?.orderInfo?.at(0)
  let orderItems = order?.data.orderItems
  return (
    <Overlay active={!order.isLoaded} spinner>
      {orderItems &&
        orderItems.length > 0 &&
        orderItems.map(orderItem => {
          return <OrderCard orderItem={orderItem} orderInfo={orderInfo} key={orderItem.sku_skuID} />
        })}
      <div className="row p-3">
        <div className="col-md-6">
          <div className="bg-white p-3 rounded mb-4">
            <h3 className="h5 text-primary">{t('frontend.checkout.shipping')}</h3>
            <p className="small">
              <strong>{user?.firstName + ' ' + user?.lastName}</strong>
              <br />
              {user?.primaryPhoneNumber?.phoneNumber}
            </p>
            <span className="small m-0">
              <strong>Standard Delivery Included</strong>
              <ShippingAddressDetails />
            </span>
          </div>
        </div>
        <div className="col-md-6">
          <ul className="list-group list-group-flush">
            <li className="d-flex justify-content-between align-items-center px-0 py-2">
              <h6 className="my-0 brand-navy subtotal fs-20"> {t('frontend.checkout.subTotal')}</h6>
              <span className="float-end subtotal brand-navy">
                {orderInfo?.calculatedSubTotal > 0 ? formatCurrency(orderInfo?.calculatedSubTotal) : '--'} <span className="cart-per-Week text-muted">{t('frontend.cart.per_week')}</span>
              </span>
            </li>
            <li className="d-flex justify-content-between align-items-center px-0 py-2">
              <h6 className="my-0 brand-navy small"> {t('frontend.cart.shippingDelivery')}</h6>
              <span className="float-end text-end small">
                <span className="postcode-included text-muted"> {t('frontend.cart.included')}</span>
              </span>
            </li>
            <li className="d-flex justify-content-between px-0 py-2 border-top">
              <h6 className="my-0 total brand-navy font-weight-bold fs-20">{t('frontend.cart.total')}</h6>
              <span className="subtotal brand-navy small">
                <span className="font-weight-bold fs-20">{orderInfo?.calculatedTotal > 0 ? formatCurrency(orderInfo?.calculatedTotal) : '--'}</span> <span className="cart-per-Week text-muted">{t('frontend.cart.per_week')}</span>
              </span>
            </li>
            {showVat && (
              <li className="d-flex justify-content-between px-0 py-2">
                <h6 className="my-0">{t('frontend.cart.vat')}</h6>
                <span className="float-end">
                  <strong>{orderInfo?.calculatedVATTotal > 0 ? formatCurrency(orderInfo?.calculatedVATTotal) : '--'}</strong>
                </span>
              </li>
            )}
          </ul>
          <Button classList="btn btn-secondary w-100" onClick={() => window.print()}>
            {t('frontend.account.accountSummary.viewInvoice')}
          </Button>
        </div>
      </div>
    </Overlay>
  )
}

const RecentOrders = ({ order }) => {
  const Orderclass = order.orderStatusType_typeName === 'Processing' ? 'badge bg-light order-status font-weight-semibold' : 'badge bg-light text-success'
  const [isLoaded, setIsLoaded] = useState(false)
  let isMobile = useMediaQuery({ query: '(max-width: 992px)' })
  var Orderdate = new Date(order.createdDateTime)
  const [formatCurrency] = useFormatCurrency({})
  const formattedDate = Orderdate.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }).replace(/ /g, ' ')
  return (
    <div className="accordion-item bg-white my-3 border-0">
      <div className="accordion-header">
        <div className="d-flex">
          <Link className="w-100 d-flex justify-content-between pt-3 px-3 pb-2 pb-lg-0 nav-link-style font-weight-medium font-size-sm" to={isMobile ? `/my-account/orders/${order.orderID}` : ''} state={{ pageTitleText: 'My Account' }}>
            <h5 className="mb-0 col brand-navy">
              {t('frontend.account.orderID')} : {order.orderNumber}
            </h5>
            {isMobile && <i className="bi bi-chevron-right fw-bold brand-navy d-flex align-items-center order-select-right-chevron"></i>}
            {!isMobile && <button className="accordion-button collapsed p-3 col pt-0 order-history-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#acc_${order.orderID}`} aria-expanded="true" aria-controls={`acc_${order.orderID}`} onClick={() => setIsLoaded(true)}></button>}
          </Link>
        </div>
        <div className="px-3">
          <hr className="m-0 mb-1" style={{ height: '0.5px' }} />
        </div>
        <table className="table order-detail-table px-2 m-0">
          {isMobile && (
            <tbody>
              <tr>
                <td className="border-0 py-2 px-3 w-25">
                  <span className={Orderclass}>{order.orderStatusType_typeName.toUpperCase()}</span>
                </td>
                <td className="border-0 py-2 w-25">{formattedDate}</td>
              </tr>
              <tr>
                <td className="border-0 py-2 px-3 w-25">
                  {order.calculatedTotalItemQuantity} {order.calculatedTotalItemQuantity === 1 ? 'item' : 'items'}
                </td>
                <td className="border-0 py-2">
                  <span className="d-flex align-items-baseline">
                    <h5 className="me-2 brand-navy">{formatCurrency(order.calculatedTotal)} </h5>
                    <span className="text-muted fw-normal">{t('frontend.cart.per_week')}</span>
                  </span>
                </td>
              </tr>
            </tbody>
          )}
          {!isMobile && (
            <tbody>
              <tr>
                <td className="border-0 py-2 px-3 w-25">
                  <span className={Orderclass}>{order.orderStatusType_typeName.toUpperCase()}</span>
                </td>
                <td className="border-0 py-2 px-3 w-25">
                  {order.calculatedTotalItemQuantity} {order.calculatedTotalItemQuantity === 1 ? 'item' : 'items'}
                </td>
                <td className="border-0 py-2 w-25">{formattedDate}</td>

                <td className="border-0 py-2">
                  <span className="d-flex align-items-baseline">
                    <h5 className="me-2 brand-navy">{formatCurrency(order.calculatedTotal)} </h5>
                    <span className="text-muted fw-normal">{t('frontend.cart.per_week')}</span>
                  </span>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      {!isMobile && (
        <div id={`acc_${order.orderID}`} className="accordion-collapse collapse" aria-labelledby="">
          <div className="px-3">
            <hr className="my-1" style={{ height: '0.5px' }} />
          </div>
          <div className="accordion-body">{<OrderDetails isLoaded={isLoaded} orderID={order.orderID} />}</div>
        </div>
      )}
    </div>
  )
}

const AccountSidebar = () => {
  const { t } = useTranslation()
  const accountMenu = useSelector(getMyAccountMenu)
  let loc = useLocation()
  const content = useSelector(state => state.content[loc.pathname.substring(1)])
  const user = useSelector(state => state.userReducer)
  const dispatch = useDispatch()
  const [disableButton, setdisableButton] = useState(false)
  return (
    <div className="d-lg-none d-block p-3 no-print">
      <div className="flex-column align-items-start mb-5 text-left">
        <h4 className="my-3 text-black">{t('frontend.account.accountsettings')}</h4>
        <div className="w-100" id="navbarNav">
          <ul className="list-unstyled p-0">
            {content?.menu?.menuItems?.length > 0 &&
              content.menu.menuItems.map(({ contentID, slug, title }) => {
                return (
                  <li key={contentID} className="nav-item">
                    <Link to={`/${slug}`} state={{ pageTitleText: 'My Account' }} className={`nav-link  ${loc.pathname.startsWith(`/${slug}`) && isSelectedClass}`}>
                      <i className="far pr-2" /> {title}
                    </Link>
                  </li>
                )
              })}
            {accountMenu.length > 0 &&
              accountMenu.map(({ contentID, urlTitlePath, title, menuIconClass, urlTitle }) => {
                if (user.isLegacyCustomer) {
                  if (urlTitle === 'orders') {
                    urlTitlePath = 'my-account/legacy-orders'
                    title = t('frontend.account.legacyorderhistory')
                  } else if (urlTitle === 'account') {
                    title = t('frontend.account.isLegacy.accountTitle')
                  }
                }
                return (
                  <Link key={contentID} to={`/${urlTitlePath}`} state={urlTitlePath === 'my-account/account' ? { pageTitleText: 'Account Settings' } : { pageTitleText: 'My Account' }} className={`nav-link d-flex justify-content-between p-2 ${loc.pathname.startsWith(`/${urlTitlePath}`) && isSelectedClass}`}>
                    <li className="">
                      <img alt={title} className="me-2" src={`../../assets/images/home/${menuIconClass}`} /> {title}
                    </li>
                    <img alt={title} className="" src={`../../assets/images/home/arrow.svg`} />
                  </Link>
                )
              })}
            <Button
              disabled={disableButton}
              onClick={() => {
                setdisableButton(true)
                dispatch(logout(t('frontend.account.logout_success'), t('frontend.account.logout_failure')))
              }}
              classList="nav-link btn-link d-flex flex-row-reverse justify-content-end p-2 border-0 bg-transparent"
            >
              {t('frontend.core.sign_out')}
              <li className="">
                <img className="me-2 pe-2" alt="Sign out" src="../../assets/images/home/sign-out-icon.svg" />
              </li>
            </Button>
          </ul>
        </div>
      </div>
    </div>
  )
}

const AccountOverview = ({ contentBody, crumbs, title, contentTitle }) => {
  const { mostRecentCount } = useSelector(state => state.configuration.myAccount)
  const { orders } = useAccountOverview()
  const user = useSelector(state => state.userReducer)
  return (
    <>
      <div className="container mt-0 mt-sm-4">
        <div className="row account-wrapper flex-column-reverse flex-lg-row-reverse">
          <div className="col-sm-12 col-md-12 col-lg-8 bg-light order-sm-0 main-content p-3">
            <h4 className="ms-2 my-3 font-weight-bold">{user.isLegacyCustomer ? 'Recent Rentals' : 'Recent Purchases'}</h4>
            {orders.data && orders.data.ordersOnAccount && orders.data.ordersOnAccount.length <= 0 && <p className="ms-2 my-3">{user.isLegacyCustomer ? 'No recent rentals' : 'No recent purchases'}</p>}
            {orders.data && orders.data.ordersOnAccount && orders.data.ordersOnAccount.length > 0 && (
              <>
                {orders.isLoaded &&
                  orders.data.ordersOnAccount
                    .filter((_, index) => index < mostRecentCount)
                    .map(order => {
                      return <RecentOrders order={order} key={order.orderID} />
                    })}
              </>
            )}
          </div>
          <AccountLayout showWalletBox={true} crumbs={crumbs} />
        </div>

        <AccountSidebar />
      </div>
    </>
  )
}

export { AccountOverview }
