import { AccountLayout } from '../AccountLayout/AccountLayout'
import { AccountContent } from '../AccountContent/AccountContent'

const AccountFaqs = ({ contentBody, contentTitle }) => {
  return (
    <div className="container mt-0 mt-sm-4">
      <div className="row account-wrapper flex-lg-row flex-column-reverse">
        <AccountLayout />
        <div className="col-sm-12 col-md-12 col-lg-8 bg-light order-sm-0 main-content p-3">
          <AccountContent contentBody={contentBody} contentTitle={contentTitle} />
        </div>
      </div>
    </div>
  )
}

export { AccountFaqs }
