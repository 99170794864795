import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { addToCart } from '../../actions/cartActions'
import { useProductPrice } from '@ten24/slatwall-storefront-react-private/hooks'
import { ProductQuantityInput } from '@ten24/slatwall-storefront-react-private/components'
import { toBoolean } from '@ten24/slatwall-storefront-react-private/utils'
import { ButtonWithIcon } from '../ButtonWithIcon/ButtonWithIcon'

const ProductQuantityMessage = () => {
  const { t } = useTranslation()
  return (
    <div className='mt-4'>
      <span className="text-accent btn border border-secondary disabled"> {t('frontend.checkout.stock')}</span>
    </div>
  )
}

const ProductForm = ({ sku, isDisabled, isLoading, productName, setInStock }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { showAddToCart } = useProductPrice({ salePrice: sku?.salePrice, listPrice: sku?.listPrice })
  const [quantity, setQuantity] = useState(1)
  const [addToCartTitle, setAddToCartTitle] = useState(t('frontend.product.add_to_cart'))
  const [showCheckIcon, setCheckIcon] = useState(false)

  if (!sku || sku?.qualifiedQATS < 1) {
    setInStock(false);
    return <ProductQuantityMessage />
  }
  if (toBoolean(sku?.settings?.skuAllowOrderTemplateFlag)) return null
  return (
    <div className="d-flex align-items-end ">
      <form
        className="d-flex align-items-end col-12 mb-0"
        onSubmit={event => {
          event.preventDefault()
        }}
      >
        {showAddToCart && (
          <div className="form-group me-2 mb-4 col-12">
            <ProductQuantityInput setQuantity={setQuantity} quantity={quantity} sku={sku} stock={sku.qualifiedQATS || sku.stocks_calculatedQATS} />

            <ButtonWithIcon
              disabled={isDisabled || quantity < 1}
              isLoading={isLoading}
              label={addToCartTitle}
              showIconOnSuccess={showCheckIcon}
              iconClass="bi bi bi-check2 m-1 text-white font-weight-bold"
              className="btn btn-primary btn-block my-3 w-100"
              onClick={event => {
                event.preventDefault()

                dispatch(addToCart(sku.skuID, productName, quantity)).then(response => {
                  if (response.isSuccess && response.success()) {
                    setCheckIcon(true)
                    setAddToCartTitle(t('frontend.product.addToCartAdded'))
                    setTimeout(() => {
                      setCheckIcon(false)
                      setAddToCartTitle(t('frontend.product.add_to_cart'))
                    }, 5000)
                  }
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  })
                })
              }}
            />
          </div>
        )}
      </form>
    </div>
  )
}
export { ProductForm }
