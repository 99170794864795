import { useTranslation } from 'react-i18next'
import { Button } from '@ten24/slatwall-storefront-react-private/components'
import { useState } from 'react'
import { Modal } from '../../components'
import { axios, sdkURL } from '@ten24/slatwall-storefront-react-private/services'
import { toast } from 'react-toastify'
import { getErrorMessage } from '@ten24/slatwall-storefront-react-private/utils'
import { useNavigate } from 'react-router-dom'

const ContractSlide = ({ placeOrder }) => {
  const { t } = useTranslation()
  const [showBuyNowModel, setShowBuyNowModel] = useState(false)
  const navigate = useNavigate()
  const [loanChecks, setLoanChecks] = useState({
    check1: false,
    check2: false,
    check3: false,
    requirements: '',
  })

  const rejectLoanOffer = () => {
    axios({
      method: 'POST',
      url: `${sdkURL}api/scope/resetShoppingLimit`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(response => {
      if (response?.status === 200 && Object.keys(response.data?.data?.errors || {}).length) toast.error(getErrorMessage(response.data?.data?.errors))
      if (response?.status === 200) {
        setShowBuyNowModel(false)
        navigate(`/shopping-cart`)
      }
    })
  }

  return (
    <>
      <Modal show={showBuyNowModel} setShow={setShowBuyNowModel} title="" size="medium" modalClassList="bg-light">
        <div className="col-sm-12">
          <h1 className="page-title mb-4 text-center text-primary">{t('frontend.checkout.contract.areyousure')}</h1>

          <h4 className="text-primary">{t('frontend.checkout.contract.rejectMessage.continuemsg')}</h4>
          <p>{t('frontend.checkout.contract.rejectMessage.callusOn')}</p>

          <Button classList="btn btn-primary w-100 mt-2">{t('frontend.checkout.contract.goBack')}</Button>
          <Button classList="btn border-primary w-100 mt-2 text-primary" onClick={() => rejectLoanOffer()}>
            {t('frontend.checkout.contract.yesiamsure')}
          </Button>
        </div>
      </Modal>
      <h1 className="page-title mb-4 text-center">{t('frontend.checkout.contract.headerTitle')}</h1>
      <p>{t('frontend.checkout.contract.title')}</p>
      <Button classList="btn btn-bordered border-primary btn-sm text-primary m-auto">
        Download contract in PDF <i className="bi bi-download ps-4 text-muted"></i>
      </Button>
      <div className="py-4">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="statemenet" value="" name="check1" onClick={() => setLoanChecks({ ...loanChecks, ...{ check1: !loanChecks.check1 } })} />
          <label class="form-check-label" for="statement">
            I understand that under the terms of the <b>Secured Loan Contract</b>, I will grant to Radio Rentals security in the goods purchased to secure my payment obligations.
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="statemenet" value="" name="check2" onClick={() => setLoanChecks({ ...loanChecks, ...{ check2: !loanChecks.check2 } })} />
          <label class="form-check-label" for="statement">
            I have read and agree to be bound by the terms of the Radio Rentals <b>Secured Loan Contract</b>.
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="statemenet" value="" name="check3" onClick={() => setLoanChecks({ ...loanChecks, ...{ check3: !loanChecks.check3 } })} />
          <label class="form-check-label" for="statement">
            I authorise the direct debit and agree to the terms of this <b className="text-primary">Direct Debit Request</b> and the Direct <b className="text-primary">Debit Request Service Agreement</b>.
          </label>
        </div>
      </div>
      <h5 className="brand-navy font-weight-bold fs-20">Does the ability to purchase these products via a Secured Loan meet your requirements and objectives?</h5>
      <div class="bg-lightgray p-4">
        <div className="form-check">
          <input class="form-check-input" type="radio" id="statemenet" value="Yes" name="requirements" onClick={() => setLoanChecks({ ...loanChecks, ...{ requirements: 'Yes' } })} />
          <label class="form-check-label" for="statement">
            Yes, this meets my requirements and objectives
          </label>
        </div>
        <div className="form-check">
          <input class="form-check-input" type="radio" id="statemenet2" value="No" name="requirements" onClick={() => setLoanChecks({ ...loanChecks, ...{ requirements: 'No' } })} />
          <label class="form-check-label" for="statement2">
            No, this does not meet my requirements and objectives
          </label>
        </div>
      </div>
      <div className="col-12">
        <button className="btn btn-bordered btn-primary w-100 mt-2" onClick={placeOrder} disabled={!loanChecks.check1 || !loanChecks.check2 || !loanChecks.check3 || loanChecks.requirements !== 'Yes'}>
          {t('frontend.checkout.contract.acceptsecureloanoffer')}
        </button>
        <Button classList="btn btn-bordered border-primary w-100 mt-2 text-primary" onClick={() => setShowBuyNowModel(true)}>
          {t('frontend.checkout.contract.rejectsecureloanoffer')}
        </Button>
      </div>
    </>
  )
}

export { ContractSlide }
