import { useNavigate, useParams } from 'react-router-dom'
import { ListingPagination } from '@ten24/slatwall-storefront-react-private/components'
import { Helmet } from 'react-helmet'
import { useProductType } from '@ten24/slatwall-storefront-react-private/hooks'
import { useState } from 'react'
import { ListingGrid } from '../../components/Listing/ListingGrid'
import { ProductTypeList } from '../../components/ProductTypeList/ProductTypeList'
import { ShopCategory } from '../../components/ShopCategory/ShopCategory'
import { useTranslation } from 'react-i18next'
import { FeaturedSection } from '../../components/FeaturedSection/FeaturedSection'
import { BreadCrumb } from '../../components/BreadCrumb/BreadCrumb'
import { useSelector } from 'react-redux'
import { ListingToolBar } from '../../components/Listing/ListingToolBar'
import { ContentSlider } from '../../components/ContentSlider/ContentSlider'
import { Layout } from '../../components/Layout/Layout'
import { useListing } from '../../hooks/components/Listing/useListing'
import { InterestDisclaimer } from '../../components/InterestDisclaimer/InterestDisclaimer'

const ProductType = (showAll) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const [hide] = useState('productType')
  const [preFilter] = useState({
    productType_slug: id,
    productsListingFlag: 1, //this flag is to get all the child products with the current products
  })
  const { potentialFilters, total, setSort, updateAttribute, records, isFetching, totalPages, setPage, params } = useListing(preFilter)
  const navigate = useNavigate()
  const { productTypeRequest, productTypeData, productTypeListRequest, productTypeRoute, isError, errorMessage } = useProductType()
  const productTypeBase = useSelector(state => state.configuration.filtering.productTypeBase)
  const crumbCalculator = () => {
    return productTypeRequest.data?.ancestors
      ?.map(crumb => {
        return { title: crumb.productTypeName, urlTitle: crumb.urlTitle }
      })
      .filter(crumb => crumb.urlTitle !== productTypeBase)
      .map(crumb => {
        return { ...crumb, urlTitle: `/${productTypeRoute}/${crumb.urlTitle}` }
      })
  }
  const content = useSelector(state => state.content)
  return (
    <Layout>
      <div className='position-relative'>
        {!id && content?.['product-type'] && <div className='d-none d-lg-block d-md-block d-sm-none'><ContentSlider slider={content['product-type']?.slider} /></div>}

        {productTypeRequest.isLoaded && <Helmet title={productTypeRequest.data?.settings?.productHTMLTitleString} />}

        {crumbCalculator() && <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 py-3 p-md-4 p-sm-2">
              <BreadCrumb crumbs={crumbCalculator()} />
            </div>
            <div className="container d-md-block d-none product-listing product-type-relative col-6 align-items-center px-0">
              <div className="container justify-content-end align-items-center d-flex p-0">
                <div className="row search-row-wrapper flex-row-reverse justify-content-between">
                  <ListingToolBar hide={hide} filtering={potentialFilters} removeFilter={updateAttribute} setSort={setSort} recordsCount={total} />
                </div>
              </div>
            </div>
          </div>
        </div>}
        {isError && (
          <div className="container box-shadow-lg rounded-lg p-5">
            <div className="row">
              <div className="alert alert-info" role="alert">
                {errorMessage}
              </div>
            </div>
          </div>
        )}
        {!id && <ShopCategory showAll="false" title={t('frontend.home.shopByCategory.title')} />}

        {!id && <div className="my-4">
          <FeaturedSection />
          <div className="container px-md-4">
            <div className="row">
              <div className="col-12">
                <InterestDisclaimer />
              </div>
            </div>
          </div>
        </div>}
        {productTypeData?.childProductTypes && (
          <ProductTypeList
            isFetching={productTypeListRequest.isFetching || !productTypeRequest.isLoaded}
            onSelect={urlTitle => {
              navigate(`/${productTypeRoute}/${urlTitle}`)
            }}
            data={productTypeData}
            productTypeRoute={productTypeRoute}
          />
        )}
        {productTypeData?.childProductTypes && <ProductTypeSearchListing hide={hide} potentialFilters={potentialFilters} total={total} setSort={setSort} updateAttribute={updateAttribute} preFilter={preFilter} records={records} isFetching={isFetching} totalPages={totalPages} setPage={setPage} params={params} />}
      </div>
    </Layout>
  )
}

const ProductTypeSearchListing = ({ hide, potentialFilters, total, setSort, updateAttribute, records, isFetching, totalPages, setPage, params }) => {
  // const [preFilter] = useState({
  //   productType_slug: productType,
  //   productsListingFlag: 1, //this flag is to get all the child products with the current products
  // })

  return (
    <>
      <div className="container product-listing mb-5 product-type-relative">
        <div className="container p-0 d-md-none d-block">
          <div className="row search-row-wrapper flex-row-reverse justify-content-between">
            <ListingToolBar hide={hide} filtering={potentialFilters} removeFilter={updateAttribute} setSort={setSort} recordsCount={total} />
          </div>
        </div>
        <div className="row">
          <ListingGrid isFetching={isFetching} pageRecords={records} />
        </div>
        <ListingPagination recordsCount={total} currentPage={params['currentPage']} totalPages={totalPages} setPage={setPage} />

      </div>
    </>
  )
}

export default ProductType
