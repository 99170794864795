import { useTranslation } from 'react-i18next'
import { AccountLayout } from '../../../components/Account/AccountLayout/AccountLayout'
import { ListingGrid } from '../../../components/Listing/ListingGrid'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getItemsForDefaultWishList } from '@ten24/slatwall-storefront-react-private/selectors'
import { axios, SlatwalApiService } from '@ten24/slatwall-storefront-react-private/services'
import { toast } from 'react-toastify'
import { getErrorMessage } from '@ten24/slatwall-storefront-react-private/utils'
import { AccountContent } from '../../../components/Account/AccountContent/AccountContent'
import { ListingPagination } from '@ten24/slatwall-storefront-react-private/components'

const AccountFavorites = ({ contentBody = '', contentTitle = '' }) => {
  const items = useSelector(getItemsForDefaultWishList)
  let [productList, setProducts] = useState([])
  let [isFetching, setFetching] = useState(false)
  const [currentPage, setPage] = useState(1)
  const countToDisplay = 6
  const { t } = useTranslation()
  useEffect(() => {
    let source = axios.CancelToken.source()
    if (items?.length < 1) {
      if (productList.length > 0) setProducts([])
      return
    }
    setFetching(true)
    SlatwalApiService.general.getEntity({ entityName: 'Sku', 'f:skuID:in': items.join() }, {}, source).then(response => {
      if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
      if (response.isSuccess()) {
        const products = response.success().data.pageRecords.map(sku => {
          if (sku?.images.length > 1) {
            sku.images = [sku.images.at(1), ...sku.images]
          }
          return { ...sku, showInput: false, showInputLabel: false, brandName: sku.product_brand_brandName, brandUrlTitle: sku.product_brand_urlTitle, productName: sku.product_productName, urlTitle: sku.product_urlTitle }
        })
        setProducts(products)
      } else {
        setProducts([])
      }
      setFetching(false)
      return response
    })

    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.length])

  let totalPages = 0
  let products = []
  const start = (currentPage - 1) * countToDisplay
  const end = start + countToDisplay
  if (productList?.length > 0) {
    products = productList?.slice(start, end)
    totalPages = Math.ceil(products?.length / countToDisplay)
  }
  return (
    <div className="container mt-0 mt-sm-4">
      <div className="row account-wrapper flex-lg-row flex-column-reverse">
        <AccountLayout />
        <div className="col-sm-12 col-md-12 col-lg-8 bg-light order-sm-0 main-content p-3">
          <AccountContent />
          {!isFetching && products.length === 0 && (
            <div className="alert alert-info" role="alert">
              {t('frontend.account.favorites.none')}
            </div>
          )}
          <ListingGrid isFetching={isFetching} pageRecords={products} />
          <ListingPagination recordsCount={products.length} totalPages={totalPages} setPage={setPage} />
        </div>
      </div>
    </div>
  )
}

export { AccountFavorites }
