import { isAuthenticated } from '@ten24/slatwall-storefront-react-private/utils'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { clearCartData, removePromoCode } from '@ten24/slatwall-storefront-react-private/actions'
import { useEffect, useState } from 'react'
import { disableInteractionSelector } from '@ten24/slatwall-storefront-react-private/selectors'
import { applyPromoCode } from '@ten24/slatwall-storefront-react-private/actions/'
import { updateItemQuantity, removeItem } from '@ten24/slatwall-storefront-react-private/actions/'
import { ApplyNow } from '../../components/ApplyNow/ApplyNow'
import { validateCart } from '../../actions'
import { CartLineItem } from '../../components/Cart/CartLineItem'
import { CartPromoBox } from '../../components/Cart/CartPromoBox'
import { OrderSummary } from '../../components/Checkout/OrderSummary'
import { Layout } from '../../components/Layout/Layout'

const Cart = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  let disableInteraction = useSelector(disableInteractionSelector)
  const user = useSelector(state => state.userReducer)
  const dashboardCTA = user?.ccgDashboardCTAText ? JSON.parse(user?.ccgDashboardCTAText) : user.DashboardCTAText
  const [removeitem, setRemoveitem] = useState(false)
  let navigate = useNavigate()
  const cart = useSelector(state => state.cart)
  const { orderItems, isFetching } = cart
  const termAccountAvailableCredit = user?.termAccountAvailableCredit || 0

  useEffect(() => {
      dispatch(validateCart())
    // eslint-disable-next-line
  }, [dispatch])
  disableInteraction = termAccountAvailableCredit === 0 || disableInteraction
  return (
    <Layout>
      <div className="container my-5 pb-2">
        <h3 className="page-title mb-4">{t('frontend.cart.shoppingCart')}</h3>
        {dashboardCTA?.ShortMessage && (
          <div className="col-lg-8 col-md-12">
            <div className="card mb-4 alert-danger">
              <div className="card-body p-2 row">
                <div className="col-1 text-danger">
                  <i className="bi bi-calendar-x me-2" />
                </div>
                <div className="col-11 text-danger">
                  <span className="text-danger fw-bold">{dashboardCTA.ShortMessage}</span>
                  <br />
                  <span className="text-danger">{t('frontend.account.purchasewarning')}</span>
                </div>
                <button className="btn btn-danger col-11 m-auto">{t('frontend.account.makeapayment')}</button>
              </div>
            </div>
          </div>
        )}

        {orderItems && orderItems.length === 0 && (
          <div className="text-center p-3">
            <h3>{t('frontend.cart.empty_cart')}</h3>
          </div>
        )}
        {isFetching && removeitem && <div className="alert alert-success">{t('frontend.cart.removeCartItem')}</div>}
        <div className="row">
          <div className="col-lg-8 col-md-12">
            {termAccountAvailableCredit > 0 && termAccountAvailableCredit < cart.total && (
              <div className="card mb-4 alert-danger">
                <div className="card-body p-2 row">
                  <div className="col-1 text-danger">
                    <i className="bi bi-cart3 me-2" />
                  </div>
                  <div className="col-11 text-danger">
                    <span className="text-danger fw-bold">
                      {t('frontend.cart.creditexceedwarning')} {termAccountAvailableCredit} per week
                    </span>
                    <br />
                    <span className="text-danger">{t('frontend.cart.tryincreasingrepayments')}</span>
                  </div>
                </div>
              </div>
            )}
            {orderItems && orderItems.length > 0 && (
              <>
                <div className="card mb-4">
                  <div className="card-body py-0">
                    {orderItems &&
                      orderItems
                        .filter(item => item.parentOrderItemID === '')
                        .map((orderItem, key) => {
                          return (
                            <CartLineItem
                              key={`${orderItem.orderItemID}-${key}`}
                              orderItem={orderItem}
                              setRemoveitem={setRemoveitem}
                              childBundleItems={orderItems?.filter(item => item?.parentOrderItemID === orderItem.orderItemID)}
                              onUpdateQty={itemCount => {
                                dispatch(updateItemQuantity(orderItem.orderItemID, itemCount))
                              }}
                              onRemoveItem={event => {
                                setRemoveitem(true)
                                event.preventDefault()
                                dispatch(removeItem(orderItem.orderItemID))
                              }}
                            />
                          )
                        })}
                  </div>
                </div>
                <div className="white-background">
                  <button className="btn btn-link link-btn text-muted fs-12 p-0 bg-white d-none d-block-md" onClick={() => dispatch(clearCartData())}>
                    {t('frontend.cart.clearCart')}
                  </button>
                </div>
              </>
            )}
          </div>

          {orderItems && orderItems.length !== 0 && (
            <div className="col-lg-4 col-md-12 px-4">
              <div className="row d-flex flex-column-reverse d-lg-block">
                <div className="col-sm-12 px-0">
                  <OrderSummary
                    cart={cart}
                    disabled={disableInteraction}
                    onRemovePromoCode={(event, promotionCode) => {
                      event.preventDefault()
                      dispatch(removePromoCode(promotionCode, undefined, t('frontend.cart.promo_code_removed')))
                    }}
                  />
                </div>

                <CartPromoBox
                  disabledInteraction={!isAuthenticated() || disableInteraction}
                  onApplyCode={(promoCode, setPromoCode) => {
                    dispatch(applyPromoCode(promoCode, t('frontend.cart.promo_code_applied')))
                    setPromoCode('')
                  }}
                />
              </div>
              <div className="row">
                {/* Commenting this payment box suggested by Paul */}
                {/* {isAuthenticated() && termAccountAvailableCredit > 0 && <AccountWalletBox />} */}
                <div className="container px-0">
                  <div className="row px-0 cart-buttons">
                    <div className="col-12">
                      <div className="col-12 cart-apply-now mb-2">
                        <ApplyNow isCartPage={true} />
                      </div>
                    </div>
                  </div>
                </div>
                {isAuthenticated() && (
                  <div className="p-0">
                    <button
                      className="col-md-12 btn btn-primary w-100 mt-2 "
                      disabled={disableInteraction}
                      onClick={e => {
                        e.preventDefault()
                        navigate('/checkout')
                      }}
                    >
                      {t('frontend.order.to_checkout')}
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Cart
