import { Helmet } from 'react-helmet'
import { useBrand, useListing } from '@ten24/slatwall-storefront-react-private/hooks'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ListingPagination } from '@ten24/slatwall-storefront-react-private/components'
import { ListingGrid } from '../../components/Listing/ListingGrid'
import { ListingToolBar } from '../../components/Listing/ListingToolBar'
import { Layout } from '../../components/Layout/Layout'

const Brand = () => {
  const { brandResponse, slug } = useBrand()
  return (
    <Layout>
      {!!brandResponse.data[0]?.settings?.brandHTMLTitleString && <Helmet title={brandResponse.data[0]?.settings?.brandHTMLTitleString} />}
      {brandResponse.isLoaded && brandResponse.data.length > 0 && <BrandSearchListing brandResponse={brandResponse} brandSlug={slug} />}
    </Layout>
  )
}

const BrandSearchListing = ({ brandResponse, brandSlug }) => {
  const [hide] = useState('brands')
  const [preFilter] = useState({
    brand_slug: brandSlug,
  })
  const loc = useLocation()
  // eslint-disable-next-line
  const content = useSelector(state => state.content[loc.pathname.substring(1)])
  const { records, isFetching, potentialFilters, total, totalPages, setSort, updateAttribute, setPage, params } = useListing(preFilter)

  return (
    <>
    <div className="container">
        <div className="row justify-content-between d-md-flex d-sm-block pe-md-3 pe-sm-0 ps-md-4 ps-sm-0">
          <div className='col-md-4 col-sm-12 px-sm-2 mb-2 px-md-0'>
          <h1 className="h3 mb-0 font-accent brand-navy mt-4">{brandResponse?.data[0]?.brandName}</h1>
          </div>
          <div className="container product-listing product-type-relative align-items-center col-md-7 col-sm-12 px-0 mx-0">
            <div className="container justify-content-md-end align-items-center p-0 d-sm-block d-md-flex">
              <div className="row search-row-wrapper flex-row-reverse justify-content-between px-4 px-sm-2 mb-2">
              <ListingToolBar hide={hide} filtering={potentialFilters} removeFilter={updateAttribute} setSort={setSort} recordsCount={total} />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <ListingGrid isFetching={isFetching} pageRecords={records} />
        </div>
        <ListingPagination recordsCount={total} currentPage={params['currentPage']} totalPages={totalPages} setPage={setPage} />
      </div>
    </>
  )
}
export default Brand
