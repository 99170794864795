import { useSelector } from 'react-redux'
import ContactFormSalesforce from './ContactFormSalesforce'
import { useLocation } from 'react-router-dom'
import { useUtilities } from '@ten24/slatwall-storefront-react-private/hooks'
import { Layout } from '../../components/Layout/Layout'

const Contact = () => {
  let { eventHandlerForWSIWYG } = useUtilities()
  let { pathname } = useLocation()
  const pageData = useSelector(state => state.content[pathname.substring(1)] || {})
  return (
    <Layout>
      <div className="p-0 m-0">
        <div className="row">
          <section className="contact-section col-lg-12 p-0">
              <div
                onClick={eventHandlerForWSIWYG}
                dangerouslySetInnerHTML={{
                  __html: pageData.contentBody || '',
                }}
              />

              <ContactFormSalesforce /> 
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
