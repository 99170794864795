import { ProductPrice, SimpleImage, SwSelect } from '@ten24/slatwall-storefront-react-private/components'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormatCurrency } from '@ten24/slatwall-storefront-react-private/hooks/'
import { useState } from 'react'
import { getProductRoute } from '@ten24/slatwall-storefront-react-private/selectors'

const CartLineItem = ({ orderItem, isDisabled = false, isFetching = false, setRemoveitem = () => {}, onUpdateQty, onRemoveItem }) => {
  const [formatCurrency] = useFormatCurrency({})
  const { t } = useTranslation()
  const productRouting = useSelector(getProductRoute)
  const isBackordered = false
  const [itemCount, setItemCount] = useState(orderItem.quantity)

  return (
    <div className="d-md-flex align-items-center p-3">
      <div className="row align-items-center col-md-7 col-sm-12">
        <div className="col-4">
          <Link className="d-inline-block mx-auto mr-sm-4 image-width" to={`/${productRouting}/${orderItem?.sku?.product?.urlTitle}`}>
            {orderItem?.sku?.images && orderItem?.sku?.images.length > 0 && <SimpleImage className="img-fluid  m-auto image_container productImage" src={orderItem?.sku?.images[0]} alt={orderItem?.sku?.product?.productName} type="product" />}
          </Link>
        </div>
        <div className="col-7 px-2">
          <Link
            to={{
              pathname: `/${productRouting}/${orderItem.sku?.product?.urlTitle}`,
              state: { ...orderItem?.sku?.product },
            }}
            className="link brand-navy"
          >
            {orderItem?.sku?.product?.productName}
          </Link>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between col-md-5 ">
        <div className="col-md-6 col-sm-12 justify-content-center cart-price">
          <div className="d-flex align-items-start justify-content-start">
            <strong className="brand-navy">
              <ProductPrice type="cart" salePrice={orderItem.extendedUnitPriceAfterDiscount} salePriceSuffixKey="frontend.core.each" accentSalePrice={false} />{' '}
            </strong>
            <span className="px-1 cart-per-Week text-muted"> {t('frontend.cart.per_week')}</span>
          </div>
          <span className="cart-frequency text-muted">{t('frontend.cart.for_48_month')}</span>
        </div>
        {isBackordered && (
          <div className="col-sm-6">
            <i className="fal fa-exclamation-circle"></i>
            <p className="text-sm mb-0">{t('frontend.order.backorder')}</p>
          </div>
        )}
        {!isDisabled ? (
          <>
            <div className="col-md-5 col-sm-12 d-flex align-items-center justify-content-between gy-4 px-0">
              <span className="d-flex flex-column quantity-dropdown">
                {itemCount < 10 && (
                  <SwSelect
                    id="quantityInputChange"
                    value={itemCount}
                    onChange={e => {
                      setItemCount(e.target.value)
                      if (e.target.value !== '10') {
                        onUpdateQty(itemCount)
                      }
                    }}
                    options={[
                      { key: 1, value: 1 },
                      { key: 2, value: 2 },
                      { key: 3, value: 3 },
                      { key: 4, value: 4 },
                      { key: 5, value: 5 },
                      { key: 6, value: 6 },
                      { key: 7, value: 7 },
                      { key: 8, value: 8 },
                      { key: 9, value: 9 },
                      { key: '10+', value: 10 },
                    ]}
                  />
                )}
                {itemCount >= 10 && (
                  <>
                    <input
                      style={{ maxWidth: '5rem' }}
                      type="number"
                      className="form-control"
                      value={itemCount}
                      max="100"
                      disabled={isFetching && orderItem.sku.skuID}
                      onChange={e => {
                        setItemCount(e.target.value)
                      }}
                    />
                    <button className="btn text-muted btn-link p-1 text-end fs-12" onClick={() => onUpdateQty(itemCount)}>
                      {t('frontend.account.cart.item.updateQuantity')}
                    </button>
                  </>
                )}
              </span>
              <span className="bi bi-x clickable text-muted d-flex align-items-center justify-content-end fs-12" disabled={isFetching} onClick={event => onRemoveItem(event)}>
                {t('frontend.cart.remove')}
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="col-sm-2">
              <small>{t('frontend.cart.quantity')}</small> {orderItem.quantity}
            </div>
            <div className="col-sm-5">
              <span className="float-end subtotal brand-navy">
                <h6 className="my-0 brand-navy subtotal fs-20"> {t('frontend.checkout.subTotal')}</h6>
                <strong>{formatCurrency(orderItem.extendedPriceAfterDiscount)}</strong>
                <span className="cart-per-Week text-muted">{t('frontend.cart.per_week')}</span>
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
export { CartLineItem }
