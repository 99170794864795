import { AccountLayout } from '../AccountLayout/AccountLayout'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import dollarIcon from '../../../assets/images/dollar-icon.svg'
import chevronIcon from '../../../assets/images/arrow-icon-white.svg'
import walletIcon from '../../../assets/images/wallet-icon.svg'
import arrowIcon from '../../../assets/images/arrow-icon.svg'
import { useSelector } from 'react-redux'
import rrCoinsIcon from '../../../assets/images/rr_coins_account.svg'
import { useFormatCurrency, useFormatDate } from '@ten24/slatwall-storefront-react-private/hooks/'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'

const AccountSummary = ({ crumbs, title }) => {
  const { t } = useTranslation()
  const user = useSelector(state => state.userReducer)
  const [formatCurrency] = useFormatCurrency({})
  const [formatDate] = useFormatDate({})
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const borderRadiusClassonWallet = isTabletOrMobile ? '' : 'rounded'
  const dashboardCTA = user?.ccgDashboardCTAText ? JSON.parse(user?.ccgDashboardCTAText) : user.DashboardCTAText
  const navigate = useNavigate()

  const makeAPaymentLink = () => {
    if (user?.isLegacyCustomer) window.location.replace('https://devselfservice.rr.com.au/')
    else navigate('/my-account/make-a-payment')
  }

  return (
    <div className="container mt-0 mt-sm-4">
      <div className="row account-wrapper flex-lg-row flex-column-reverse">
        <AccountLayout crumbs={crumbs} title={title} />
        <div className="col-lg-8 col-md-8 col-sm-12 p-0 bg-blue account-summary">
          <div className="col-sm-12 col-md-12 col-lg-12 text-white p-0 bg-primary text-white p-0 pe-0 pe-lg-3">
            <div className={`col-12 ` + borderRadiusClassonWallet + ` bg-primary p-4 d-xs-none d-lg-block`}>
              <div className="py-3 d-flex">
                <img className="pe-2 my-account-coins-icon" src={rrCoinsIcon} alt="coins icon" />
                <h4 className="mb-0 d-flex align-items-center">{user?.isLegacyCustomer ? t('frontend.account.myRentalAccount') : t('frontend.account.myAccount')}</h4>
              </div>
              {['Error007_1', 'Error009', 'Error011_1', 'Error012_1'].includes(dashboardCTA?.ErrorCode) && (
                <div className="col-lg-12 col-md-12">
                  <div className="card mb-4 alert-danger">
                    <div className="card-body p-2 row">
                      {dashboardCTA?.ShortMessage && (
                        <>
                          <div className="col-1 text-danger">
                            <i className="bi bi-calendar-x me-2" />
                          </div>
                          <div className="col-11 text-danger">
                            <span className="text-danger fw-bold">{dashboardCTA.ShortMessage}</span>
                            <br />
                          </div>
                        </>
                      )}
                      {dashboardCTA?.DashboardCTAText && <button className="btn btn-danger col-11 m-auto">{dashboardCTA.DashboardCTAText}</button>}
                    </div>
                  </div>
                </div>
              )}
              <div className="mt-2 text-white rounded border border-info padding-md">
                <table className="mt-2 table text-white">
                  <tbody>
                    <tr className="py-1">
                      <td className="border-0"> {t('frontend.account.walletBox.nextRepayment')}</td>
                      <td className="border-0">
                        <span className="d-block">
                          <strong>{user.ccgNextPaymentAmount > 0 ? formatCurrency(Number(user.ccgNextPaymentAmount)) : ''}</strong>
                          {user.ccgNextPaymentFrequency}
                        </span>
                        <span className="small">
                          {t('frontend.checkout.due')} {formatDate(user.ccgNextPaymentDate)}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="p-2">
                  <button onClick={makeAPaymentLink} className="btn btn-info col-12 brand-navy w-100">
                    {t('frontend.account.makeapayment')}
                  </button>
                  {/* Commenting out pre demo
            {['Error013'].includes(dashboardCTA?.ErrorCode) && dashboardCTA?.DashboardCTAText && <button className="btn btn-info col-12 mt-2 brand-navy">{dashboardCTA.DashboardCTAText}</button>} */}
                </div>
              </div>
            </div>

            <div className="col-12 p-4 text-white">
              <Link to="#" className="d-flex justify-content-between p-2 text-white">
                <span className="d-flex">
                  <img src={dollarIcon} alt="Dollar icon" className="me-2" />
                  {t('frontend.account.accountSummary.viewStatements')}
                </span>
                <img src={chevronIcon} alt="Arrow icon" className="" />
              </Link>
            </div>
            <div className="col-12 p-4 text-white">
              <span className=" d-flex justify-content-between pb-3">
                <h4>{t('frontend.account.accountSummary.recentTransactions')}</h4>{' '}
                <Link to="#" className="d-flex justify-content-between align-items-center text-white">
                  <span className="mx-2">{t('frontend.account.accountSummary.seeAll')}</span> <img src={arrowIcon} alt="Dollar icon" width="15px" />
                </Link>
              </span>
              {/* Temporarily hardcoded for demo purposes */}
              <div className="d-flex py-2 text-white justify-content-between">
                <span className="col-sm-2 col-md-1 text-left fs-12 d-flex align-items-center">13 April </span>
                <span className="col-sm-2 col-md-1 px-2 d-flex align-items-center">
                  <img src={walletIcon} alt="Dollar icon" width="40px" />
                </span>
                <span className="col-sm-5 col-md-6">
                  Payment Recieved
                  <br />
                  Order ID:56567563r7jXg123{' '}
                </span>
                <span className="col-sm-2 col-md-3 d-flex justify-content-end"> + $59.90 </span>
              </div>
              <hr />
              <div className="d-flex py-2 text-white justify-content-between">
                <span className="col-sm-2 col-md-1 text-left fs-12 d-flex align-items-center">13 April </span>
                <span className="col-sm-2 col-md-1 px-2 d-flex align-items-center">
                  <img src={walletIcon} alt="Dollar icon" width="40px" />
                </span>
                <span className="col-sm-5 col-md-6">
                  Payment Recieved
                  <br />
                  Order ID:56567563r7jXg123{' '}
                </span>
                <span className="col-sm-2 col-md-3 d-flex justify-content-end"> + $59.90 </span>
              </div>
              <hr />
              <div className="d-flex py-2 text-white justify-content-between">
                <span className="col-sm-2 col-md-1 text-left fs-12 d-flex align-items-center">13 April </span>
                <span className="col-sm-2 col-md-1 px-2 d-flex align-items-center">
                  <img src={walletIcon} alt="Dollar icon" width="40px" />
                </span>
                <span className="col-sm-5 col-md-6">
                  Payment Recieved
                  <br />
                  Order ID:56567563r7jXg123{' '}
                </span>
                <span className="col-sm-2 col-md-3 d-flex justify-content-end"> + $59.90 </span>
              </div>
              <hr />
              <div className="d-flex py-2 text-white justify-content-between">
                <span className="col-sm-2 col-md-1 text-left fs-12 d-flex align-items-center">13 April </span>
                <span className="col-sm-2 col-md-1 px-2 d-flex align-items-center">
                  <img src={walletIcon} alt="Dollar icon" width="40px" />
                </span>
                <span className="col-sm-5 col-md-6">
                  Payment Recieved
                  <br />
                  Order ID:56567563r7jXg123{' '}
                </span>
                <span className="col-sm-2 col-md-3 d-flex justify-content-end"> + $59.90 </span>
              </div>
              <hr />
            </div>
            {/* End comment */}
          </div>
        </div>
      </div>
    </div>
  )
}

export { AccountSummary }
