import { useTranslation } from 'react-i18next'
import { useFormatCurrency, useProductPrice } from '@ten24/slatwall-storefront-react-private/hooks/'
import { Link } from 'react-router-dom'
import { isAuthenticated } from '@ten24/slatwall-storefront-react-private/utils'

const ProductPrice = ({ salePrice = 0, listPrice = 0, type = 'product', salePriceSuffixKey, accentSalePrice = true, listPriceSuffixKey = 'frontend.core.list', showloginRequired = false }) => {
  const [formatCurrency] = useFormatCurrency({})
  const { t } = useTranslation()
  const { showMissingPrice, showSalePrice, loginRequiredForPrice } = useProductPrice({ salePrice, listPrice, type, showloginRequired })
  return (
    <>
      <div className="product-price">
        {showMissingPrice && <small>{t('frontend.product.missingprice')}</small>}
       
        {showSalePrice && <span className="d-block text-muted">{!isAuthenticated() && t('frontend.productCard.fromPricetag')} <span className="price">{formatCurrency(salePrice)}</span>{t('frontend.productCard.fromPrice.perWeek')}</span>}
      </div>
      {loginRequiredForPrice && showloginRequired && (
        <div className="alert alert-warning fs-6" role="alert">
          {t('frontend.productPrice.loginToView')}
          <br />
          <Link to="/my-account/login"> {t('frontend.productPrice.loginOrCreateAccount')}</Link>
        </div>
      )}
    </>
  )
}

export { ProductPrice }