import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useLoginForm } from '../../../hooks'

const LoginForm = ({ isCheckout = false }) => {
  const { t } = useTranslation()
  const { formik } = useLoginForm()
  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      <div className="mb-5 bg-white col-md-7 login-page">
        <div className="container container-custom-xs">
          <div className="row text-center">
            <h3 className="brand-navy">{t('frontend.account.sign_in')}</h3>
            <p>{t('frontend.account.welcomeback')}</p>
          </div>

          <form
            className="login-form"
            onSubmit={e => {
              e.preventDefault()
              setIsLoading(true)
              formik.handleSubmit()
              setTimeout(() => setIsLoading(false), 1000)
            }}
          >
            <div className="row">
              <div className="mb-3">
                <label htmlFor="loginEmail">{t('frontend.account.emailLabel')}*</label>
                <input value={formik.values.loginEmail} onBlur={formik.handleBlur} onChange={formik.handleChange} autoComplete="email" className="form-control" type="email" id="loginEmail" placeholder="Email" />
                {formik.errors.loginEmail && <span className="form-error-msg">{formik.errors.loginEmail}</span>}
              </div>
              <div className="mb-2">
                <label htmlFor="loginPassword">{t('frontend.account.password')}*</label>
                <input value={formik.values.loginPassword} onBlur={formik.handleBlur} onChange={formik.handleChange} autoComplete="current-password" className="form-control" type="password" id="loginPassword" placeholder="Password" />
                {formik.errors.loginPassword && formik.touched.loginPassword && <span className="form-error-msg">{formik.errors.loginPassword}</span>}
              </div>
              <div className="mb-3 text-end">
                <Link to={`/my-account/forgotPassword`} className="nav-link-inline font-size-sm link d-flex justify-content-end">
                  {t('frontend.account.forgot_password')}
                </Link>
              </div>
              <div className="mb-3 align-items-center">
                <label htmlFor="rememberMe" className="login-checkbox">
                  <input id="rememberMe" type="checkbox" className="login-checkbox__input" value={formik.values.rememberMe} onClick={() => (formik.values.rememberMe = !formik.values.rememberMe)} />
                  <span className="login-checkbox__label">{t('frontend.account.rememberMe')}</span>
                </label>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <button className="btn btn-primary btn-lg w-100" type="submit">
                  {isLoading && <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true" />}
                  {t('frontend.account.sign_in')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

const AccountLogin = ({ isCheckout = false }) => {
  return (
    <div className="container py-4 my-4">
      <div className="row d-flex justify-content-center">
        <LoginForm isCheckout={isCheckout} />
      </div>
    </div>
  )
}

export { AccountLogin }
