import { SimpleImage } from '@ten24/slatwall-storefront-react-private/components'
import { useBasicPage, useUtilities } from '@ten24/slatwall-storefront-react-private/hooks'
import { Link } from 'react-router-dom'
import { Layout } from '../Layout/Layout'

const MakeAPayment = () => {
  const { content } = useBasicPage()
  let { eventHandlerForWSIWYG } = useUtilities()
  return (
    <Layout>
      <div class="container-xl p-0 d-none d-lg-block">
        <div class="row">
          <div class="col-md-6 col-sm-12 py-3">
            <div class="d-block mb-3 mb-lg-0 pt-lg-2">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li class="breadcrumb-item ">
                    <Link class="text-nowrap" to="/make-a-payment">
                      Make a Payment
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xl container-custom-lg py-4">
        
        {content.isMarkup && (
          <div
            className="content-body"
            onClick={eventHandlerForWSIWYG}
            dangerouslySetInnerHTML={{
              __html: content.contentBody || '',
            }}
          />
        )}

        {!content.isMarkup &&
          content.sections &&
          content.sections.map(({ title, text, imageUrl }) => {
            return (
              <div key={title}>
                <h2>{title}</h2>
                <div className="row">
                  <div className="col-md-8">{text}</div>
                  <div className="col-md-4">{imageUrl !== '' && <img className="float-end ml-md-2 mb-md-2 about-Img" src={imageUrl} alt={title} />}</div>
                </div>
              </div>
            )
          })}
        {content?.contentColumns?.columns?.length > 0 && (
          <div className="container-xl">
            <div className="row justify-content-center m-0">
              {content?.contentColumns?.columns?.map((column, index) => {
                return (
                  <div key={`${column.title}-${index}`} className={`col-lg-${12 / content?.contentColumns?.columns?.length} pr-4-lg`}>
                    <div className="border-0 bg-brand-blue text-white p-3">
                      {column.imagePath && column.imagePath.trim().length > 0 && (
                        <div className="">
                          <SimpleImage src={column.imagePath} />
                        </div>
                      )}

                      <div className="">
                        {/* {column.title && <h3 className="mb-3 mt-1 fw-light">{column.title}</h3>} */}
                        <div onClick={eventHandlerForWSIWYG} dangerouslySetInnerHTML={{ __html: column.contentBody }} />
                        {column.linkLabel && column.linkLabel.trim().length > 0 && column.linkUrl.trim().length > 0 && (
                          <div className="text-center">
                            <a onClick={eventHandlerForWSIWYG} href={`/${column.linkUrl}`} className="btn btn-primary btn-lg text-white px-4 mt-4">
                              {column.linkLabel}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}
export { MakeAPayment }
