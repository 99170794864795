import { useTranslation } from 'react-i18next'
import { useLocation, Link } from 'react-router-dom'

const ShopCategory = ({ title, showAll }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const isproductTypePage = location.pathname.includes('/product-type')

  return (
    <section className={`section-bg-color ${!showAll ? 'section-bg-blue' : ''}`}>
      <div className="container d-flex flex-column h-15 py-4">
        <div className="row">
          <div className={`col-12 p-4 text-center ${showAll ? 'd-flex justify-content-sm-start justify-content-md-center ' : ''}`}>
            <h2 className={`d-block ${!showAll ? 'text-primary' : 'text-navy'}`}>{title}</h2>
          </div>
        </div>
        <div className="row justify-content-center py-4 px-3">
          <div className="col-lg-2 col-md-2 col-sm-4 col-4 d-flex">
            <Link to={`/product-type/computer-and-gaming`} className="category-block">
            {isproductTypePage && 
              <img className="category-block-image mb-3" height="80px" alt="Computer and gaming" src="../../assets/images/home/computer-gaming-category-blue.svg" />
            }
              {!isproductTypePage && 
              <img className="category-block-image mb-3" height="80px" alt="Computer and gaming" src="../../assets/images/home/computer-gaming-category.png" />
            }
              <p className="category-text text-center pb-2">{`${t('frontend.home.shopCategory.computerGaming')}`}</p>
            </Link>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-4 col-4 d-flex">
            <Link to={`/product-type/tvs`} className="category-block">
              {isproductTypePage && 
              <img className="category-block-image mb-3" height="80px" alt="TV" src="../../assets/images/home/tv-category-blue.svg" />
              }
              {!isproductTypePage && 
              <img className="category-block-image mb-3" height="80px" alt="TV" src="../../assets/images/home/tv-category.png" />
              }
              <p className="category-text text-center pb-2">{`${t('frontend.home.shopCategory.tv')}`}</p>
            </Link>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-4 col-4 d-flex">
            <Link to={`/product-type/phones-and-tablets`} className="category-block">
            {isproductTypePage && 
              <img className="category-block-image mb-3" height="80px" alt="Phone and tablets" src="../../assets/images/home/phones-category-blue.svg" />
            }
             {!isproductTypePage && 
              <img className="category-block-image mb-3" height="80px" alt="Phone and tablets" src="../../assets/images/home/phones-category.png" />
            }
              <p className="category-text text-center pb-2">{`${t('frontend.home.shopCategory.phoneTablets')}`}</p>
            </Link>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-4 col-4 d-flex">
            <Link to={`/product-type/audio-headphones-and-music`} className="category-block">
            {isproductTypePage && 
              <img className="category-block-image mb-3" height="80px" alt="Audio" src="../../assets/images/home/audio-category-blue.svg" />
            }
              {!isproductTypePage && 
              <img className="category-block-image mb-3" height="80px" alt="Audio" src="../../assets/images/home/audio-category.png" />
            }
              <p className="category-text text-center pb-2">{`${t('frontend.home.shopCategory.audio')}`}</p>
            </Link>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-4 col-4 d-flex">
            <Link to={`/product-type/furniture`} className="category-block">
            {isproductTypePage && 
              <img className="category-block-image mb-3" height="80px" alt="Furniture" src="../../assets/images/home/furniture-category-blue.svg" />
            }
            {!isproductTypePage && 
              <img className="category-block-image mb-3" height="80px" alt="Furniture" src="../../assets/images/home/furniture-category.png" />
            }
              <p className="category-text text-center pb-2">{`${t('frontend.home.shopCategory.furniture')}`}</p>
            </Link>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-4 col-4 d-flex">
            <Link to={`/product-type/kitchen`} className="category-block">
            {isproductTypePage && 
              <img className="category-block-image mb-3" height="80px" alt="Kitchen" src="../../assets/images/home/kitchen-category-blue.svg" />
            }
             {!isproductTypePage && 
              <img className="category-block-image mb-3" height="80px" alt="Kitchen" src="../../assets/images/home/kitchen-category.png" />
            }
              <p className="category-text text-center pb-2">{`${t('frontend.home.shopCategory.kitchen')}`}</p>
            </Link>
          </div>
        </div>
        {!showAll && (
          <div className="row justify-content-center py-4">
            <div className="col-md-12 col-sm-12 text-center shop-button d-lg-flex justify-content-evenly">
              <Link to={`/product-type`} className="btn btn-secondary col-12 col-lg-4 mt-2">
                {t('frontend.home.shopCategory.button')}
              </Link>
            </div>
          </div>
    
        )}     
            </div> 
    </section>
  )
}

export { ShopCategory }
