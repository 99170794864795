import { Link } from 'react-router-dom'
import { AccountContent } from '@ten24/slatwall-storefront-react-private/components'
import { useTranslation } from 'react-i18next'
import { useFormatCurrency, useFormatDate } from '@ten24/slatwall-storefront-react-private/hooks/'
import { useLegacyOrderList } from '../../../hooks'
import { AccountLayout } from '../../../components'
import { AccountLegacyOrderDetail } from '../AccountLegacyOrderDetail/AccountLegacyOrderDetail'
import { useState } from 'react'

const OrderStatus = ({ type = 'info', text }) => {
  return <span className={`badge bg-${type} m-0`}>{text}</span>
}

const OrderListItem = props => {
  const [formatCurrency] = useFormatCurrency({})
  const [formatDate] = useFormatDate()
  const [isLoaded] = useState(false)
  const { t } = useTranslation()
  const { Contract_Number__c, Id, Disconnection_Date__c, Contract_Status__c, Weekly_Rental_Rate__c, Item_Description__c } = props
  return (
    <div className="accordion-item mb-4">
      <h2 className="accordion-header">
        {Id && (
          <Link className="accordion-button collapsed nav-link-style font-weight-medium font-size-sm" to={`/my-account/legacy-orders/${Id}`}>
            {t('frontend.account.rentalhistory.contractID')} : {Contract_Number__c}
          </Link>
        )}
        <table className="table px-2 m-0">
          <tbody>
            <tr>
             {Contract_Status__c && <td className="border-1 py-4 px-3 border-start-0 border-end-0">
                <OrderStatus text={Contract_Status__c} />
              </td>}
              <td className="border-1 p-4 border-start-0 border-end-0 w-50">
                <h5>{Item_Description__c}</h5>
              </td>
              <td className="border-1 p-4 border-start-0 border-end-0"><h5>{formatDate(Disconnection_Date__c)}</h5></td>
              <td className="border-1 p-4 border-start-0 border-end-0">
                <h5>{formatCurrency(Weekly_Rental_Rate__c)}</h5>
              </td>
            </tr>
          </tbody>
        </table>
      </h2>
      <div id={`acc_${Contract_Number__c}`} className="accordion-collapse collapse" aria-labelledby="">
        <div className="accordion-body">{isLoaded && <AccountLegacyOrderDetail orderID={Contract_Number__c} />}</div>
      </div>
    </div>
  )
}

const OrderHistoryList = ({ crumbs }) => {
  const { orders } = useLegacyOrderList({})
  return (
    <>
      {orders.isLoaded &&
        orders.data?.records.map((order, index) => {
          return <OrderListItem key={index} {...order} />
        })}
    </>
  )
}

const AccountLegacyOrder = ({ crumbs, title, orders }) => {
  const { t } = useTranslation()

  return (
    <div className="container mt-0 mt-sm-4">
      <div className="row account-wrapper flex-lg-row flex-column-reverse">
        <AccountLayout title={t('frontend.account.account_order_history')} />
        <div className="col-sm-12 col-md-12 col-lg-8 bg-light order-sm-0 main-content p-3">
          <AccountContent />
          <OrderHistoryList orders={orders}/>
        </div>
      </div>
    </div>
  )
}

export { AccountLegacyOrder }