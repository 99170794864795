import { useEffect } from 'react'
import { AccountLayout, ShippingAddressDetails } from '../../../components'
import { useGetLegacyOrderList } from '../../../hooks'
import { useTranslation } from 'react-i18next'
import { useFormatCurrency } from '@ten24/slatwall-storefront-react-private/hooks'
import { ContactCard } from '../../ContactCard/ContactCard'
import { useSelector } from 'react-redux'

const OrderDetails = ({ records }) => {
  const { Item_Description__c, Item_Brand__c, Expected_Contract_End_Date__c, Weekly_Rental_Rate__c } = records
  const [formatCurrency] = useFormatCurrency({})
  const user = useSelector(state => state.userReducer)
  const { t } = useTranslation()
  return (
    <>
      <div className="bg-white p-3 rounded mb-4">
        <h5 className="brand-blue">Rental Details</h5>
        <div className="d-flex border-bottom py-3 align-items-center">
          <div className="">
            <h3 className="product-title font-size-base mb-2 h5 brand-blue">
              <span>{Item_Description__c}</span> <span className="text-right">{formatCurrency(Weekly_Rental_Rate__c)}</span>
            </h3>
            {Item_Brand__c}
            <div className=" font-size-sm">
              {' '}
              <span className="text-muted mr-2">Contract term ends {Expected_Contract_End_Date__c}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="bg-white p-3 rounded mb-4">
            <h3 className="h5 text-primary">{t('frontend.checkout.shipping')}</h3>
            <p className="small">
              <strong>{user?.firstName + ' ' + user?.lastName}</strong>
              <br />
              {user?.primaryPhoneNumber?.phoneNumber}
            </p>
            <p className="small m-0">
              <strong>Standard Delivery Included</strong>
              <ShippingAddressDetails />
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <ul className="list-group list-group-flush">
            <li className="d-flex justify-content-between align-items-center px-0 py-2">
              <h6 className="my-0 brand-navy subtotal fs-20"> {t('frontend.checkout.subTotal')}</h6>
              <span className="float-end subtotal brand-navy">{formatCurrency(Weekly_Rental_Rate__c)}<span className='text-muted fw-normal'>per fortnight</span></span>
            </li>
            <li className="d-flex justify-content-between align-items-center px-0 py-2">
              <h6 className="my-0 brand-navy small"> {t('frontend.cart.shippingDelivery')}</h6>
              <span className="float-end text-end small">
                <span className="postcode-included text-muted"> {t('frontend.cart.included')}</span>
              </span>
            </li>
            <li className="d-flex justify-content-between px-0 py-2 border-top">
              <h6 className="my-0 total brand-navy font-weight-bold fs-20">{t('frontend.cart.total')}</h6>
              <span className="subtotal brand-navy small"><span className='fw-bold'>{formatCurrency(Weekly_Rental_Rate__c)}</span><span className='text-muted fw-normal'>per fortnight</span></span>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

const AccountLegacyOrderDetail = props => {
  const orderID = props.path
  let [order, setRequest] = useGetLegacyOrderList()
  useEffect(() => {
    let didCancel = false
    if (!order.isFetching && !order.isLoaded && !didCancel) {
      setRequest({ ...order, isFetching: true, isLoaded: false, params: { id: orderID }, makeRequest: true })
    }
    return () => {
      didCancel = true
    }
  }, [order, orderID, setRequest])

  return (
    <div className="container my-4">
      <div className="row account-wrapper flex-lg-row flex-column-reverse">
        <AccountLayout />
        <div className="col-sm-12 col-md-12 col-lg-8 bg-light order-sm-0 main-content p-3">
          <h5 className="ms-2 my-3 brand-blue"> RENTAL DETAILS</h5>
          <h4 className="ms-2 my-3 brand-blue">Contract ID : {order.data.records?.at(0)?.Contract_Number__c}</h4>
          {order.data.records?.at(0)?.Contract_Number__c && <OrderDetails records={order?.data?.records?.at(0)} />}
          <div className="py-3">
            <ContactCard background="bg-white" />
          </div>
        </div>
      </div>
    </div>
  )
}

export { AccountLegacyOrderDetail }
