import { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { logout } from '@ten24/slatwall-storefront-react-private/actions/'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import rrCoinsIcon from '../../../assets/images/rr_coins_account.svg'
import { useFormatCurrency, useFormatDate } from '@ten24/slatwall-storefront-react-private/hooks/'
import { getMyAccountMenu } from '@ten24/slatwall-storefront-react-private/selectors/'
import { useMediaQuery } from 'react-responsive'
import { Button } from '@ten24/slatwall-storefront-react-private/components'
const isSelectedClass = 'active'
/*
 * TODO: Fix content menu
 */
const AccountSidebar = () => {
  const { t } = useTranslation()
  const accountMenu = useSelector(getMyAccountMenu)
  let loc = useLocation()
  const content = useSelector(state => state.content[loc.pathname.substring(1)])
  const user = useSelector(state => state.userReducer)
  const dispatch = useDispatch()
  const [disableButton, setdisableButton] = useState(false)

  return (
    <div className="d-lg-block d-none">
      <div className="flex-column align-items-start mb-5 text-left">
        <h4 className="my-3 text-black">{t('frontend.account.accountsettings')}</h4>
        <div className="w-100" id="navbarNav">
          <ul className="list-unstyled p-0">
            {content?.menu?.menuItems?.length > 0 &&
              content.menu.menuItems.map(({ contentID, slug, title }) => {
                return (
                  <li key={contentID} className="nav-item">
                    <Link to={`/${slug}`} className={`nav-link  ${loc.pathname.startsWith(`/${slug}`) && isSelectedClass}`}>
                      <i className="far pr-2" /> {title}
                    </Link>
                  </li>
                )
              })}
            {accountMenu.length > 0 &&
              accountMenu.map(({ contentID, urlTitlePath, title, menuIconClass, urlTitle }) => {
                if (user?.isLegacyCustomer) {
                  if (urlTitle === 'orders') {
                    urlTitlePath = 'my-account/legacy-orders'
                    title = t('frontend.account.legacyorderhistory')
                  } else if (urlTitle === 'account') {
                    title = t('frontend.account.isLegacy.accountTitle')
                  }
                }
                return (
                  <Link key={contentID} to={`/${urlTitlePath}`} className={`nav-link d-flex justify-content-between p-2 ${loc.pathname.startsWith(`/${urlTitlePath}`) && isSelectedClass}`}>
                    <li className="">
                      <img alt={title} className="me-2" src={`../../assets/images/home/${menuIconClass}`} /> {title}
                    </li>
                    <img alt={title} className="" src={`../../assets/images/home/arrow.svg`} />
                  </Link>
                )
              })}
            {user?.isLegacyCustomer && (
              <>
                <a target="_blank" rel="noopener noreferrer" href="https://radio-rentals.secure.force.com/" className={`nav-link d-flex justify-content-between p-2`}>
                  <li className="">
                    <img alt="Arrange a repair or replacement" className="me-2" src={`../../assets/images/home/arrangeorrepair.svg`} /> Arrange a repair or replacement
                  </li>
                  <img alt="Arrange a repair or replacement" className="" src={`../../assets/images/home/arrow.svg`} />
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://radio-rentals.secure.force.com/" className={`nav-link d-flex justify-content-between p-2`}>
                  <li className="">
                    <img alt="End of term application" className="me-2" src={`../../assets/images/home/end-of-contract.svg`} /> End of term application
                  </li>
                  <img alt="End of term application" className="" src={`../../assets/images/home/arrow.svg`} />
                </a>
              </>
            )}
            <Button
              disabled={disableButton}
              onClick={() => {
                setdisableButton(true)
                dispatch(logout(t('frontend.account.logout_success'), t('frontend.account.logout_failure')))
              }}
              classList="nav-link btn-link d-flex flex-row-reverse justify-content-end p-2 border-0 bg-transparent"
            >
              {t('frontend.core.sign_out')}
              <li className="">
                <img className="me-2 pe-2" alt="Sign out" src="../../assets/images/home/sign-out-icon.svg" />
              </li>
            </Button>
          </ul>
        </div>
      </div>
    </div>
  )
}

const MyAccountLayout = ({ children, showWalletBox = false }) => {
  const { t } = useTranslation()
  const user = useSelector(state => state.userReducer)
  const dashboardCTA = user?.ccgDashboardCTAText ? JSON.parse(user?.ccgDashboardCTAText) : user.DashboardCTAText
  const [formatCurrency] = useFormatCurrency({})
  const [formatDate] = useFormatDate({})
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const borderRadiusClassonWallet = isTabletOrMobile ? '' : 'rounded'
  const navigate = useNavigate()

  const makeAPaymentLink = () => {
    if (user?.isLegacyCustomer) window.location.replace('https://devselfservice.rr.com.au/')
    else navigate('/my-account/make-a-payment')
  }
  return (
    <div className="col-sm-12 col-md-12 col-lg-4 text-white p-0 text-white p-0 pe-0 pe-lg-3">
      {showWalletBox && (
        <>
          <h3 className="fw-normal brand-blue pt-0 py-3 d-lg-block d-none">{`${t('frontend.core.welcome')}, ${user.firstName}`}</h3>
          <div className={`col-12 ` + borderRadiusClassonWallet + ` bg-primary p-4 d-xs-none d-lg-block`}>
            <h4 className="fw-normal text-white pt-0 d-block d-lg-none">{`${t('frontend.core.welcome')}, ${user.firstName}`}</h4>
            <div className="rounded border border-info mt-3">
              <Link to={`/my-account/account`} state={{ pageTitleText: 'Account Settings' }}>
                <div className="py-3 px-2 text-white d-flex justify-content-between">
                  <div className="d-flex">
                    <img className="pe-2 my-account-coins-icon" src={rrCoinsIcon} alt="coins icon" />
                    <h4 className="mb-0 d-flex align-items-center">{user?.isLegacyCustomer ? t('frontend.account.myRentalAccount') : t('frontend.account.myAccount')}</h4>
                  </div>
                  <i className="bi bi-chevron-right text-white fw-bold d-flex align-items-center account-select-right-chevron"></i>
                </div>
              </Link>
              {['Error007_1', 'Error009', 'Error011_1', 'Error012_1'].includes(dashboardCTA?.ErrorCode) && (
                <div className="col-lg-12 col-md-12">
                  <div className="card mb-4 alert-danger">
                    <div className="card-body p-2 row">
                      {dashboardCTA?.ShortMessage && (
                        <>
                          <div className="col-1 text-danger">
                            <i className="bi bi-calendar-x me-2" />
                          </div>
                          <div className="col-11 text-danger">
                            <span className="text-danger fw-bold">{dashboardCTA.ShortMessage}</span>
                            <br />
                          </div>
                        </>
                      )}
                      {dashboardCTA?.DashboardCTAText && <button className="btn btn-danger col-11 m-auto">{dashboardCTA.DashboardCTAText}</button>}
                    </div>
                  </div>
                </div>
              )}
              <div className="mt-2 text-white padding-md">
                <table className="mt-2 table text-white">
                  <tbody>
                    <tr className="p-5">
                      <td className="border-0"> {t('frontend.account.walletBox.nextRepayment')}</td>
                      <td className="border-0">
                        <span className="d-block">
                          <span className="d-flex">
                            <strong>{user.ccgNextPaymentAmount > 0 ? formatCurrency(Number(user.ccgNextPaymentAmount)) : ''}&nbsp; </strong>
                            <small>{t('frontend.cart.per_month')}</small>
                          </span>
                          {user.ccgNextPaymentFrequency}
                        </span>
                        <span className="small">
                          {t('frontend.checkout.due')} {formatDate(user.ccgNextPaymentDate)}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="p-2">
                  <button onClick={makeAPaymentLink} className="btn btn-info col-12 bg-light-blue-btn brand-navy w-100">
                    {t('frontend.account.makeapayment')}
                  </button>
                  {/* Commenting out pre demo
                {['Error013'].includes(dashboardCTA?.ErrorCode) && dashboardCTA?.DashboardCTAText && <button className="btn btn-info col-12 mt-2 brand-navy">{dashboardCTA.DashboardCTAText}</button>} */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <AccountSidebar />
      <section className="col-lg-8">{children}</section>
    </div>
  )
}

const PromptLayout = ({ children }) => {
  const { t } = useTranslation()
  return (
    <div className="container py-4 py-lg-5 my-4">
      <div className="row d-flex justify-content-center">
        <div className="mb-5 bg-white col-md-6 ">
          <div className="container container-custom-xs">
            <div className="text-center">
              <h1 className="display-3">{t('frontend.account.myAccount.title')}</h1>
            </div>
            <hr />
            <div className="card-body">{children}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { MyAccountLayout as AccountLayout, PromptLayout }
