import { Button } from '@ten24/slatwall-storefront-react-private/components'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { OrderSummary } from '../../components'
// https://www.digitalocean.com/community/tutorials/how-to-create-multistep-forms-with-react-and-semantic-ui
// https://github.com/srdjan/react-multistep/blob/master/react-multistep.js
// https://www.geeksforgeeks.org/how-to-create-multi-step-progress-bar-using-bootstrap/

//

const CheckoutSideBar = () => {
  const cart = useSelector(state => state.cart)
  const { isFetching } = cart
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <>
      <aside className="col-lg-4 pt-4 pt-lg-0">
        <div className="ml-lg-auto">
          <OrderSummary />
          <Button
            disabled={isFetching}
            isLoading={isFetching}
            label={t('frontend.order.complete_purchase')}
            classList="btn btn-primary btn-lg btn-block w-100"
            onClick={e => {
              e.preventDefault()
              navigate('/checkout/contract')
            }}
          />
        </div>
      </aside>
    </>
  )
}

export { CheckoutSideBar }
