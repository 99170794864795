import { useFormik } from 'formik'
import { useRedirect } from '@ten24/slatwall-storefront-react-private/hooks'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { SlatwalApiService } from '@ten24/slatwall-storefront-react-private/services'
import { AccountLayout } from '../../../components/Account/AccountLayout/AccountLayout'
import { AccountContent } from '../../../components/Account/AccountContent/AccountContent'

const UpdatePassword = ({ path, heading, redirectLocation = '/my-account/profile', contentBody, contentTitle, action = 'Update Password' }) => {
  const { t } = useTranslation()
  const [redirect, setRedirect] = useRedirect({ location: redirectLocation })
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      accountPassword: '',
      accountPasswordConfirm: '',
    },
    onSubmit: values => {
      SlatwalApiService.account
        .changePassword({
          password: values.accountPassword,
          passwordConfirm: values.accountPasswordConfirm,
        })
        .then(response => {
          if (response.isSuccess()) {
            if (response.success().successfulActions.length) {
              toast.success(t('frontend.account.password_update'))
              setRedirect({ ...redirect, shouldRedirect: true })
            } else {
              let errors = response.success().errors.password ? response.success().errors.password : response.success().errors.passwordConfirm
              toast.error(errors.join(' '))
            }
          } else {
            toast.error('Network Error')
          }
        })
    },
  })
  return (
    <div className="container mt-0 mt-sm-4">
     <div className="row">
      <AccountLayout title={`Add ${action}`} />
      <div className="col-sm-12 col-md-12 col-lg-8 bg-light order-sm-0 main-content p-3">
        <AccountContent contentBody={contentBody} contentTitle={contentTitle} />

        <form onSubmit={formik.handleSubmit}>
          <h2>{heading}</h2>
          <div className="row">
            <div className="form-group col-md-6">
              <label htmlFor="accountPassword">{t('frontend.account.password')}</label>
              <input className="form-control" type="password" id="accountPassword" onChange={formik.handleChange} />
            </div>

            <div className="form-group col-md-6">
              <label htmlFor="accountPasswordConfirm">{t('frontend.account.password_confirm')}</label>
              <input className="form-control" type="password" id="accountPasswordConfirm" onChange={formik.handleChange} />
            </div>

            <div className="col-12">
              <div className="d-flex flex-wrap justify-content-start">
                <button type="submit" className="btn btn-primary mt-3 mt-sm-0 w-25">
                  {t('frontend.core.save')}
                </button>
              </div>
            </div>
          </div>
        </form>
        </div>
        </div>
        </div>
  
  )
}

export { UpdatePassword }
