
import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/radiorentals-logo.png'
import { useFormatCurrency, useFormatDate } from '@ten24/slatwall-storefront-react-private/hooks/'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'

const SuccessfulApplication = () => {
  const { t } = useTranslation()
  const [formatDate] = useFormatDate({})
  const [formatCurrency] = useFormatCurrency({})
  const user = useSelector(state => state.userReducer)
  const cart = useSelector(state => state.cart)
  let availableToSpend = null
  if (user.termAccountAvailableCredit && cart.total) {
    availableToSpend = parseFloat(user.termAccountAvailableCredit - cart.total)
  }
  const { width, height } = useWindowSize()

  return (
    <>
    <Confetti
      width={width}
      height={height}
    />
      <div className="container-fluid success-application p-0 p-sm-5">
        <div className="row">
          <div className="col-12 text-center mt-4">
            <Link className="d-block" to="/">
              <span className="navbar-brand d-block p-2 mx-auto">
                <img src={logo} className="img-fluid py-1" alt={t('frontend.logo')} width="200px" />
              </span>
            </Link>
            <div className="p-3 text-start row w-100 m-auto successfull-application">
              <h2 className="mb-4 px-0">{t('frontend.successfullApplication.welcome_to_radio_rentals')}</h2>
              <p className="px-0">{t('frontend.successfullApplication.spend_up_to')}<span> <strong>{availableToSpend > 0 ? formatCurrency(availableToSpend) : '--'}</strong> {t('frontend.cart.per_week')}</span> {t('frontend.successfullApplication.approved_credit_limit')}</p>
              <div className="bg-transparent border border-default rounded p-3 my-3">
                <div className="d-flex justify-content-between pb-2">
                  <span className="text-white">{t('frontend.checkout.available_credit')}</span>
                  <span>
                    <strong>{user.termAccountAvailableCredit > 0 ? formatCurrency(Number(user.termAccountAvailableCredit)) : '--'}</strong> {t('frontend.cart.per_week')}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="text-white">{t('frontend.checkout.items_in_cart')}</span>
                  <span>
                    <strong>-{cart.total > 0 ? formatCurrency(cart.total) : '--'}</strong> {t('frontend.cart.per_week')}
                  </span>
                </div>
                <hr />
                <div className="d-flex justify-content-between pb-2">
                  <span className="text-white font-weight-bold">{t('frontend.checkout.available_to_spend')}</span>
                  <span>
                    <strong>{availableToSpend > 0 ? formatCurrency(availableToSpend) : '--'}</strong> {t('frontend.cart.per_week')}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="text-white font-weight-bold">{t('frontend.checkout.repayments')}</span>
                  <div className="text-right">
                    <span className="d-block">
                      <strong>{user.ccgNextPaymentAmount > 0 ? formatCurrency(Number(user.ccgNextPaymentAmount)) : ''}</strong>
                      {user.ccgNextPaymentFrequency}
                    </span>
                    <span className="small">
                      {t('frontend.checkout.due')} {formatDate(user.ccgNextPaymentDate)}
                    </span>
                  </div>
                </div>
              </div>
              <p className="fs-12 px-0">{t('frontend.cart.repayment_text')}</p>
              <p className="px-0">{t('frontend.successfullApplication.theitemsinyourcart')} {cart.total > 0 ? formatCurrency(cart.total) : '--'} {t('frontend.successfullApplication.per_week_period')} {t('frontend.successfullApplication.you_still_have')} {availableToSpend > 0 ? formatCurrency(availableToSpend) : '--'} {t('frontend.cart.per_week')} {t('frontend.successfullApplication.on_your_favourite')}</p>

              <Link className="w-100 btn btn-info brand-navy" to={`/shop`}>
                {t('frontend.successfullApplication.complete_shopping')}
              </Link>
              <h2 className="mt-4 px-0">{t('frontend.successfullApplication.complete_your_purchase')}</h2>
              <p className="px-0">{t('frontend.successfullApplication.items_are_almost_yours')}</p>
              <Link className="w-100 btn btn-info brand-navy" to={`/checkout`}>
                {t('frontend.successfullApplication.complete_purchase')}
              </Link>
            </div>
          </div>
        </div>
      </div>
  </>
  )
}

export default SuccessfulApplication
