import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormatDate, useGetAllOrders, useGetOrderDetails } from '@ten24/slatwall-storefront-react-private/hooks'
import { useEffect } from 'react'
import { confirmOrder } from '@ten24/slatwall-storefront-react-private/actions'
import { isAuthenticated } from '@ten24/slatwall-storefront-react-private/utils'
import { useCookies } from 'react-cookie'
import { SimpleImage } from '@ten24/slatwall-storefront-react-private/components'
import { useFormatCurrency } from '@ten24/slatwall-storefront-react-private/hooks/'
import { getProductRoute, isVatCountry } from '@ten24/slatwall-storefront-react-private/selectors'
import { axios, sdkURL } from '@ten24/slatwall-storefront-react-private/services'
import { Layout } from '../../components/Layout/Layout'
import { ContactCard } from '../../components/ContactCard/ContactCard'
import rrCoinsIcon from '../../assets/images/rr_coins_account.svg'
import { ShippingAddressDetails } from '../../components/Checkout/Review/ShippingAddressDetails'

const OrderItem = ({ quantity, sku_skuID, sku_product_productName, sku_product_urlTitle, images, BrandName, isSeries, ProductSeries, calculatedExtendedPriceAfterDiscount, sku_calculatedSkuDefinition, sku_imageFile, price, sku_product_productID, orderItemID, files, orderChildItems, showActions = true }) => {
  const [formatCurrency] = useFormatCurrency({})
  const { t } = useTranslation()
  const childBundleItems = orderChildItems?.filter(item => item?.parentOrderItem_orderitemID === orderItemID)

  const productRouting = useSelector(getProductRoute)

  return (
    <div className="row border-bottom py-3 justify-content-between align-items-center">
      <div className="col-sm-2 col-3">
        <Link to={`/${productRouting}/${sku_product_urlTitle}?skuid=${sku_skuID}`} className="mx-auto mr-sm-4">
          {images && images?.length > 0 && <SimpleImage className="img-fluid  m-auto image_container productImage w-100" src={images?.at(0)} alt={sku_product_productName} type="product" />}
        </Link>
      </div>
      <div className="col-sm-4 col-9">
        {isSeries && <span className="product-meta d-block font-size-xs pb-1">{ProductSeries}</span>}
        {/* <!--- only show this span if part of a bundled product? ---> */}
        <h3 className="product-title font-size-base mb-2 h5">
          <Link className="link" to={`/${productRouting}/${sku_product_urlTitle}?skuid=${sku_skuID}`}>
            {sku_product_productName}
          </Link>
        </h3>
        {/* <!--- product title ---> */}
        <div className=" font-size-sm">
          {BrandName} <span className="text-muted mr-2">{sku_calculatedSkuDefinition}</span>
        </div>
        {/* <!--- brand / sku ---> */}
        {files && files.length > 0 && isAuthenticated() && (
          <div>
            {files
              .filter(file => file.baseID === sku_product_productID)
              .map(file => (
                <span
                  className="link-primary pe-auto text-decoration-underline"
                  key={file.file_fileID}
                  onClick={e => {
                    e.preventDefault()

                    axios({
                      method: 'POST',
                      url: `${sdkURL}api/scope/downloadFile?fileID=${file.file_fileID}`,
                      responseType: 'blob', // had to add this one here
                    }).then(response => {
                      const url = window.URL.createObjectURL(new Blob([response.data]))
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', `${file.file_fileName}.${file.file_fileType}`)
                      document.body.appendChild(link)
                      link.click()
                    })
                  }}
                >
                  {t('frontend.cart.download')}
                </span>
              ))}
          </div>
        )}
      </div>
      <div className="col-sm-12 col-md-6 d-none d-sm-block">
        <div className="row">
          <div className="col-sm-4">
            <span className="d-block">{`${formatCurrency(price)}`}</span>
            <span className="d-block">{/* <span className="text-muted mr-2">{`($${listPrice} list)`}</span> */}</span>
            {/* <!--- each / list price ---> */}
          </div>
          <div className="col-sm-4">
            <small>{t('frontend.cart.quantity')}</small> {quantity}
          </div>
          <div className="col-sm-4">
            <div className="row">
              <div className="col-12">
                {formatCurrency(calculatedExtendedPriceAfterDiscount)}
                {/* <!--- total ---> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        {childBundleItems &&
          childBundleItems.length > 0 &&
          childBundleItems.map((childBundleItem, key) => {
            return key !== 0 ? (
              <>
                <i className="bi bi-plus-circle col-1 align-self-center"></i>
                <Link key={childBundleItem.OrderItemID} className="col-2" to={`/${productRouting}/${childBundleItem.sku_product_urlTitle}`}>
                  <SimpleImage className="img-fluid  m-auto image_container productImage border border-light" src={childBundleItem.images?.at(0)} alt={childBundleItem?.sku_product_productName} type="product" />
                  <span className="text-dark"> {`${formatCurrency(childBundleItem.price)} x ${childBundleItem.quantity}`}</span>
                  <p>{childBundleItem?.sku_product_productName}</p>
                </Link>
              </>
            ) : (
              <>
                <Link key={childBundleItem.OrderItemID} className="col-2" to={`/${productRouting}/${childBundleItem.sku_product_urlTitle}`}>
                  <SimpleImage className="img-fluid  m-auto image_container productImage border border-light" src={childBundleItem.images?.at(0)} alt={childBundleItem?.sku_product_productName} type="product" />
                  <span className="text-dark"> {`${formatCurrency(childBundleItem.price)} x ${childBundleItem.quantity}`}</span>
                  <p>{childBundleItem?.sku_product_productName}</p>
                </Link>
              </>
            )
          })}
      </div>
    </div>
  )
}

const OrderConfirmationDetails = orderID => {
  const user = useSelector(state => state.userReducer)
  const cart = useSelector(state => state.cart)
  let [order, setRequest] = useGetOrderDetails()
  const { t } = useTranslation()
  const { orderInfo, orderItems } = order?.data
  const [formatCurrency] = useFormatCurrency({})
  const [formatDate] = useFormatDate({})
  const showVat = useSelector(isVatCountry)
  useEffect(() => {
    let didCancel = false
    if (!order.isFetching && !order.isLoaded && !didCancel) {
      setRequest({ ...order, isFetching: true, isLoaded: false, params: { orderID }, makeRequest: true })
    }
    return () => {
      didCancel = true
    }
  }, [order, orderID, setRequest])
  return (
    <>
      <div className="col-12 col-lg-8">
        <div className="py-4">
          <h3 className="page-title mb-4">{t('frontend.order.success.title')}</h3>
          <p>{t('frontend.order.success.mainTitle.subTextOne') + ' ' + orderInfo?.at(0).orderNumber + ' ' + t('frontend.order.success.mainTitle.subTextTwo')}</p>
          <p>{t('frontend.order.success.mainTitle.paragraphText')}</p>
        </div>
        <div className="bg-lightgray p-3 rounded mb-4 container">
          <div className="d-flex justify-content-between mt-2 mb-3">
            <h2 className="h5 text-primary">{t('frontend.order.success.orderSummary')}</h2>
            <span className="small">
              {orderItems?.length}
              {orderInfo?.length !== 1 ? <span> {' ' + t('frontend.checkout.items')}</span> : <span> {' ' + t('frontend.checkout.item')}</span>}
            </span>
          </div>
          {orderItems &&
            orderItems.map(orderItem => {
              return <OrderItem key={orderItem.orderItemID} {...orderItem} showActions={false} />
            })}
        </div>
      </div>
      <div className="col-12 col-lg-4">
        <div className="col-12 p-4 bg-primary mt-4 rounded">
          <div className="border border-default rounded">
            <div className=" p-3 d-flex justify-content-between">
              <h4 className="text-white">
                <img className="my-account-coins-icon" src={rrCoinsIcon} alt="coins icon" /> My Account
              </h4>
            </div>
            <table className="mt-2 table text-white table-borderless">
              <tbody>
                <tr className="p-3">
                  <td>{t('frontend.checkout.available_credit')}</td>
                  <td className="text-right">
                    <strong>{user.termAccountAvailableCredit > 0 ? formatCurrency(Number(user.termAccountAvailableCredit)) : '--'}</strong> {t('frontend.cart.per_week')}
                  </td>
                </tr>
                <tr className="p-3">
                  <td>{t('frontend.checkout.items_in_cart')}</td>
                  <td className="text-right">
                    <strong>-{cart.total > 0 ? formatCurrency(cart.total) : '--'}</strong> {t('frontend.cart.per_week')}
                  </td>
                </tr>
                <tr className="p-5">
                  <td>Next Repayment</td>
                  <td className="text-right">
                    <div className="row justify-content-between">
                      <span className="d-block">
                        <strong>{user.ccgNextPaymentAmount > 0 ? formatCurrency(Number(user.ccgNextPaymentAmount)) : ''}</strong>
                        {user.ccgNextPaymentFrequency}
                      </span>
                      <span className="small">
                        {t('frontend.checkout.due')} {formatDate(user.ccgNextPaymentDate)}
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <ul className="list-group list-group-flush py-4">
          <li className="d-flex justify-content-between align-items-center px-0 py-2">
            <h6 className="my-0 brand-navy subtotal fs-20"> {t('frontend.checkout.subTotal')}</h6>
            <span className="float-end subtotal brand-navy">
              {orderInfo?.at(0)?.calculatedSubTotal > 0 ? formatCurrency(orderInfo?.at(0)?.calculatedSubTotal) : '--'} <span className="cart-per-Week text-muted">{t('frontend.cart.per_week')}</span>
            </span>
          </li>
          <li className="d-flex justify-content-between align-items-center px-0 py-2">
            <h6 className="my-0 brand-navy small"> {t('frontend.cart.shippingDelivery')}</h6>
            <span className="float-end text-end small">
              <span className="postcode-included text-muted"> {t('frontend.cart.included')}</span>
            </span>
          </li>
          <li className="d-flex justify-content-between px-0 py-2 border-top">
            <h6 className="my-0 total brand-navy font-weight-bold fs-20">{t('frontend.cart.total')}</h6>
            <span className="subtotal brand-navy small">
              <span className="font-weight-bold fs-20">{orderInfo?.at(0)?.calculatedTotal > 0 ? formatCurrency(orderInfo?.at(0)?.calculatedTotal) : '--'}</span> <span className="cart-per-Week text-muted">{t('frontend.cart.per_week')}</span>
            </span>
          </li>
          {showVat && (
            <li className="d-flex justify-content-between px-0 py-2">
              <h6 className="my-0">{t('frontend.cart.vat')}</h6>
              <span className="float-end">
                <strong>{orderInfo?.at(0)?.calculatedVATTotal > 0 ? formatCurrency(orderInfo?.at(0)?.calculatedVATTotal) : '--'}</strong>
              </span>
            </li>
          )}
        </ul>
      </div>
      <div className="col-lg-8 col-sm-12">
        <div className="bg-lightgray p-3 rounded mb-4 container">
          <h3 className="h5 text-primary">{t('frontend.checkout.shipping')}</h3>
          <p className="small">
            <strong>{user?.firstName + ' ' + user?.lastName}</strong>
            <br />
            {user?.primaryPhoneNumber?.phoneNumber}
          </p>
          <p className="small m-0">
            <strong>Standard Delivery Included</strong>
            <ShippingAddressDetails />
          </p>
        </div>
      </div>
      <div className="col-lg-4 col-sm-12 p-3">
        <div className="row justify-content-center py-3">
          <h3 className="page-title mb-4">{t('frontend.order.success.dontMiss')}</h3>
          <p>
            {t('frontend.order.success.dontMissout.subTextOne') + ' '} <strong>{formatCurrency(Number(user.termAccountAvailableCredit))}</strong> {t('frontend.order.success.dontMissout.subTextTwo')}
          </p>
          <Link className="btn btn-primary" to="/shop">
            {t('frontend.order.success.continueShopping')}
          </Link>
        </div>
      </div>
    </>
  )
}

const OrderConfirmation = () => {
  let [orders, setRequest] = useGetAllOrders()
  const [, , removeCookie] = useCookies()
  let dispatch = useDispatch()

  useEffect(() => {
    let didCancel = false

    if (!orders.isFetching && !orders.isLoaded && !didCancel) {
      setRequest({ ...orders, isFetching: true, isLoaded: false, params: { pageRecordsShow: 1, currentPage: 1 }, makeRequest: true })
    }
    if (!orders.isFetching && orders.isLoaded && !didCancel) {
      removeCookie('affiliateCode', { path: '/' })
    }
    dispatch(confirmOrder(false))
    return () => {
      didCancel = true
    }
  }, [orders, setRequest, removeCookie, dispatch])

  if (!isAuthenticated()) {
    return <Navigate to={'/my-account/login'} />
  }

  return (
    <Layout>
      <div className="container">
        <div className="p-0">
          {orders.isLoaded && orders.data.ordersOnAccount?.at(0) && (
            <div className="row">
              <OrderConfirmationDetails orderId={orders.data.ordersOnAccount?.at(0).orderID} />
            </div>
          )}
        </div>
        <div className="py-3">
          <ContactCard />
        </div>
      </div>
    </Layout>
  )
}

export default OrderConfirmation
