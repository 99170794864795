import { SlatwalApiService } from '@ten24/slatwall-storefront-react-private/services'

const REQUEST_CART = 'REQUEST_CART'
const RECEIVE_CART = 'RECEIVE_CART'

const requestCart = () => {
  return {
    type: REQUEST_CART,
  }
}

const receiveCart = cart => {
  return {
    type: RECEIVE_CART,
    cart,
  }
}

const setUpPayload = ({ payload = {}, isQuote = false, returnQuote = true }) => {
    if (isQuote) delete payload['returnJSONObjects']
    if (isQuote && returnQuote) payload['returnQuote'] = true
    return payload
  }
  

const addToOrder = ({ params = {}, isQuote = false, returnQuote = true }) => {
  return async dispatch => {
    if (!isQuote) dispatch(requestCart())
    const payload = setUpPayload({
      payload: {
        returnJSONObjects: 'cart',
        ...params,
      },
      isQuote,
      returnQuote,
    })
    return await SlatwalApiService[isQuote ? 'quotes' : 'cart'].addItem(payload).then(response => {
      if (response.isSuccess() && !isQuote) dispatch(receiveCart(response.success().cart))
      if (!response.isSuccess() && !isQuote) dispatch(receiveCart())
      return response
    })
  }
}

const getOrder = ({ params = {}, isQuote = false, returnQuote = true }) => {
  return async dispatch => {
    if (!isQuote) dispatch(requestCart())
    const payload = setUpPayload({
      payload: {
        returnJSONObjects: 'cart',
        ...params,
      },
      isQuote,
      returnQuote,
    })
    return await SlatwalApiService[isQuote ? 'quotes' : 'cart'].get(payload).then(response => {
      if (response.isSuccess() && !isQuote) dispatch(receiveCart(response.success().cart))
      if (!response.isSuccess() && !isQuote) dispatch(receiveCart())
      return response
    })
  }
}

const clearOrderData = ({ params = {}, isQuote = false, returnQuote = true }) => {
  return async dispatch => {
    if (!isQuote) dispatch(requestCart())
    const payload = setUpPayload({
      payload: {
        returnJSONObjects: 'cart',
        ...params,
      },
      isQuote,
      returnQuote,
    })
    return await SlatwalApiService[isQuote ? 'quotes' : 'cart'].clear(payload).then(response => {
      if (response.isSuccess() && !isQuote) dispatch(receiveCart(response.success().cart))
      if (!response.isSuccess() && !isQuote) dispatch(receiveCart())

      return response
    })
  }
}

const removePromoCodeFromOrder = ({ params = {}, isQuote = false, returnQuote = true }) => {
  return async dispatch => {
    if (!isQuote) dispatch(requestCart())
    const payload = setUpPayload({
      payload: {
        returnJSONObjects: 'cart',
        ...params,
      },
      isQuote,
      returnQuote,
    })
    return await SlatwalApiService[isQuote ? 'quotes' : 'cart'].removePromoCode(payload).then(response => {
      if (response.isSuccess() && !isQuote) dispatch(receiveCart(response.success().cart))
      if (!response.isSuccess() && !isQuote) dispatch(receiveCart())
      return response
    })
  }
}

export {
  requestCart,
  receiveCart,
  addToOrder,
  getOrder,
  clearOrderData,
  removePromoCodeFromOrder,
}
