import jwtDecode from 'jwt-decode'
import data from '../preload'
export const hasValidPostalCode = () => {
    let token = localStorage.getItem('token')
    if (token) {
        try {
            token = jwtDecode(token)
            return !!token?.sessionData?.postalCode
        } catch (error) { }
    }
    return false
}

export const getPostalCodeInfoFromURL = () => {
    //need to review this code it is for site redirection postal code set params
    const search = window.location.search.substring(1)

    let postalcode = window.location.hostname.includes('rr') ? data.defaultRRPostalcode : data.defaultCCGPostalcode
    if (search !== '') {
        const urlParams = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}')
        if ('postalCode' in urlParams) {
            postalcode = { ...postalcode, ...urlParams }
        }
    }
    ///////////
    return postalcode;


}

export const getPostalCodeInfo = () => {
    let token = localStorage.getItem('token')
    var defaultPostalcode = window.location.hostname.includes('rr') ? data.defaultRRPostalcode : data.defaultCCGPostalcode
    if (token) {
        try {
            token = jwtDecode(token)
            return {
                ...defaultPostalcode,
                countryCode: token?.sessionData?.countryCode,
                postalCode: token?.sessionData?.postalCode,
                stateCode: token?.sessionData?.stateCode,
                city: token?.sessionData?.city
            }
        } catch (error) { }
    }
    return defaultPostalcode
}

export const getTranslatedMessage = (message) => {
    const currentSiteCode = localStorage.getItem('siteCode')
    switch (currentSiteCode) {
      case 'creditcorpgroup':
        return message
      case 'rr':
        //For this sitecode we are appenidng sitecode with rb key 
        return message+'_rr'
      default:
        return message
    }
}
