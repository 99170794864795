import { Link } from 'react-router-dom'
import { Layout } from '../Layout/Layout'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import { SlatwalApiService } from '@ten24/slatwall-storefront-react-private/services'
import { toast } from 'react-toastify'
import { getErrorMessage } from '@ten24/slatwall-storefront-react-private/utils'
import { useState } from 'react'
import * as Yup from 'yup'

const RefundForm = () => {
  const { refundForm } = useSelector(state => state.configuration.forms)
  const [loading, setLoading] = useState(false)
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const bsbRegex = /^\d{3}-\d{3}$/
  const customerAccountNumberRegex = /^\d{8}$/
  const [isChecked, setIsChecked] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const todaysDate = new Date().toISOString().split('T')[0];

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  const SuccessMessage = () => {
    if (formSubmitted) {
      return (
        <div className="success-message text-center">
          <h3 className="brand-navy">Thanks! Your refund request has been submitted successfully.</h3>
          <h2 className="text-danger">Important next steps</h2>
          <h4 className="brand-navy">
            <p>Look out for an email to your inbox.</p>
            <p>We will need you to action this email before your refund can be processed. </p>
            <p>Don't forget to check your spam folder too!</p>
            <p>Please allow up to 10 days for your refund to be processed.</p>
          </h4>
          <Link to={'/'}>Back to Home</Link>
        </div>
      )
    }
  }

  const refundSchema = Yup.object().shape({
    customerAccountNumber: Yup.string().required('Complete this field.').matches(customerAccountNumberRegex, 'Kindly enter a valid customer account number'),
    fullName: Yup.string().required('Complete this field.'),
    dob: Yup.date().max(new Date(), 'Date of birth cannot be in the future').required('Complete this field.'),
    address: Yup.string().required('Complete this field.'),
    accountName: Yup.string().required('Complete this field.'),
    accountBranch: Yup.string().required('Complete this field.'),
    applicationSignedDate: Yup.string().required('Complete this field.'),
    emailAddress: Yup.string().email('Kindly enter valid email').required('Complete this field.'),
    bankAccountNumber: Yup.string().required('Complete this field.'),
    bankName: Yup.string().required('Complete this field.'),
    branchNumber: Yup.string().required('Complete this field.').matches(bsbRegex, 'Kindly enter valid branch number'),
    contactNumber: Yup.string().required('Complete this field.').matches(phoneRegExp, 'Kindly enter valid contact number'),
  })
  const formik = useFormik({
    initialValues: {
      formResponse: {
        formID: refundForm,
      },
      context: 'addFormResponse',
      address: '',
      emailAddress: '',
      contactNumber: '',
      dob: '',
      fullName: '',
      accountName: '',
      customerAccountNumber: '',
      bankAccountNumber: '',
      bankName: '',
      accountBranch: '',
      applicationSignedDate: new Date().toISOString().split('T')[0],
      branchNumber: '',
    },
    validateOnChange: true,
    validationSchema: refundSchema,
    onSubmit: (values, { resetForm }) => {
      if (!isChecked) {
        toast.error('Please tick the declaration checkbox as the account holder')
        return
      }
      if(values?.applicationSignedDate !== todaysDate){
        toast.error("You can only submit form for today's date.")
        return
      }
      setLoading(true)
      SlatwalApiService.content.addFormResponse(values).then(response => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
        if (response.isSuccess()) {
          setFormSubmitted(true)
        } else {
          setLoading(false)
          toast.error(getErrorMessage(response?.success()?.errors))
        }
      })
    },
  })
  return (
    <Layout>
      <div className="container-xl p-0 d-none d-lg-block">
        <div className="row">
          <div className="col-md-6 col-sm-12 py-3 mx-2">
            <div className="d-block mb-3 mb-lg-0 pt-lg-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item ">
                    <Link className="text-nowrap" to="/refunds">
                      Refunds
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xl contact-info p-4 p-lg-5 mb-3">
        {formSubmitted ? (
          <SuccessMessage />
        ) : (
          <>
            <div className="row px-2 px-lg-0">
              <h2 className="brand-navy text-center">REQUEST FOR A BANK REFUND FORM</h2>
            </div>
            <hr style={{ height: '0.5%' }} />

            <form className="contact-form" onSubmit={formik.handleSubmit}>
              <div className="d-flex row ">
                <div style={{ flex: '1' }}>
                  <h4 className="brand-navy">Purpose of this form</h4>
                  <p>This form is to be completed when you are requesting a refund of credit from your account.</p>
                  <p>In some instances you may only receive a portion of your credit to ensure the next payment due on your account is met.</p>
                  <p>We will not refund credits that have been applied to your account due to promotions or other offers.</p>
                  <p>If you have any questions regarding this form please contact the Customer Support Team on 13 11 81.</p>
                  <h4 className="brand-navy">Your Details</h4>
                  <hr />
                  <div className="col-12">
                    <div className="mb-1">
                      <label htmlFor="customerAccountNumber" className="form-label brand-blue ">
                        Customer Account Number (Should be 8 digits)*
                      </label>
                      <input className="form-control" id="customerAccountNumber" value={formik.values.customerAccountNumber} onChange={formik.handleChange} maxLength="8" name="customerAccountNumber" type="text" />
                      {formik.errors.customerAccountNumber && <span className="form-error-msg">{formik.errors.customerAccountNumber}</span>}
                    </div>
                    <div className="mb-1">
                      <label htmlFor="fullName" className="form-label brand-blue ">
                        Full Name*
                      </label>
                      <input className="form-control" id="fullName" value={formik.values.fullName} onChange={formik.handleChange} maxLength="255" name="fullName" type="text" />
                      {formik.errors.fullName && <span className="form-error-msg">{formik.errors.fullName}</span>}
                    </div>
                    <div className="mb-1">
                      <label htmlFor="dob" className="form-label brand-blue ">
                        DOB*
                      </label>
                      <input className="form-control" id="dob" value={formik.values.dob} onChange={formik.handleChange} name="dob" type="date" />
                      {formik.errors.dob && <span className="form-error-msg">{formik.errors.dob}</span>}
                    </div>
                    <div className="mb-1">
                      <label htmlFor="address" className="form-label brand-blue ">
                        Address*
                      </label>
                      <input className="form-control" id="address" value={formik.values.address} onChange={formik.handleChange} maxLength="255" name="address" type="text" />
                      {formik.errors.address && <span className="form-error-msg">{formik.errors.address}</span>}
                    </div>
                    <div className="mb-1">
                      <label htmlFor="contactNumber" className="form-label brand-blue ">
                        Contact Number*
                      </label>
                      <input className="form-control" id="contactNumber" value={formik.values.contactNumber} onChange={formik.handleChange} maxLength="255" name="contactNumber" type="text" />
                      {formik.errors.contactNumber && <span className="form-error-msg">{formik.errors.contactNumber}</span>}
                    </div>
                    <div className="mb-1">
                      <label htmlFor="emailAddress" className="form-label brand-blue ">
                        Email Address*
                      </label>
                      <input className="form-control" id="emailAddress" value={formik.values.emailAddress} onChange={formik.handleChange} maxLength="255" name="emailAddress" type="text" />
                      {formik.errors.emailAddress && <span className="form-error-msg">{formik.errors.emailAddress}</span>}
                    </div>
                  </div>
                  <h4 className="brand-navy mt-4">Your Bank Details</h4>
                  <hr />
                  <div className="mb-2">
                    <label htmlFor="bankName" className="form-label brand-blue ">
                      Name of bank, building society or credit union*
                    </label>
                    <input className="form-control" id="bankName" value={formik.values.bankName} onChange={formik.handleChange} maxLength="255" name="bankName" type="text" />
                    {formik.errors.bankName && <span className="form-error-msg">{formik.errors.bankName}</span>}
                  </div>
                  <div className="mb-2">
                    <label htmlFor="accountBranch" className="form-label brand-blue ">
                      Branch where the account is held*
                    </label>
                    <input className="form-control" id="accountBranch" value={formik.values.accountBranch} onChange={formik.handleChange} maxLength="255" name="accountBranch" type="text" />
                    {formik.errors.accountBranch && <span className="form-error-msg">{formik.errors.accountBranch}</span>}
                  </div>
                </div>
                <div style={{ flex: '1' }}>
                  <div className="mb-2">
                    <label htmlFor="branchNumber" className="form-label brand-blue ">
                      Branch Number (BSB) (Please enter in this format 012-345)*
                    </label>
                    <input className="form-control" id="branchNumber" value={formik.values.branchNumber} onChange={formik.handleChange} maxLength="255" name="branchNumber" type="text" />
                    {formik.errors.branchNumber && <span className="form-error-msg">{formik.errors.branchNumber}</span>}
                  </div>
                  <div className="mb-2">
                    <label htmlFor="bankAccountNumber" className="form-label brand-blue ">
                      Bank Account Number*
                    </label>
                    <input className="form-control" id="bankAccountNumber" value={formik.values.bankAccountNumber} onChange={formik.handleChange} maxLength="255" name="bankAccountNumber" type="text" />
                    {formik.errors.bankAccountNumber && <span className="form-error-msg">{formik.errors.bankAccountNumber}</span>}
                  </div>
                  <div className="mb-2">
                    <label htmlFor="accountName" className="form-label brand-blue ">
                      Account Name (s)*
                    </label>
                    <input className="form-control" id="accountName" value={formik.values.accountName} onChange={formik.handleChange} maxLength="255" name="accountName" type="text" />
                    {formik.errors.accountName && <span className="form-error-msg">{formik.errors.accountName}</span>}
                  </div>
                  <h4 className="brand-navy">Protecting your privacy</h4>
                  <hr />
                  <p>We are committed to protecting and maintaining the privacy, accuracy and security of your personal information. We are committed to complying with the Privacy Act (Cth) 1988 ("Privacy Act"), Australian Privacy Principles ("APPs").</p>

                  <h4 className="brand-navy">Account Holder Declaration</h4>
                  <hr />
                  <p>I declare that :</p>
                  <ul>
                    <li>The information I have provided in this form is complete and correct.</li>
                    <li>The bank account I have provided in this form belongs to me</li>
                  </ul>
                  <div>
                    * <input type="checkbox" id="agree" checked={isChecked} onChange={handleCheckboxChange} />
                    <label className="ms-2" htmlFor="agree">
                      Please tick this box as the account holder
                    </label>
                  </div>
                  <div className="mb-1">
                    <label htmlFor="applicationSignedDate" className="form-label brand-blue ">
                      Date*
                    </label>
                    <input className="form-control" id="date" value={formik.values.applicationSignedDate} onChange={formik.handleChange} name="applicationSignedDate" type="date" />

                    {formik.errors.applicationSignedDate && <span className="form-error-msg">{formik.errors.applicationSignedDate}</span>}
                  </div>
                  <p>Please allow up to 10 business days for the refund to be processed to your nominated bank account.</p>
                  <p>If you have any questions, please contact us on 131 181</p>
                  <button style={{ float: 'right' }} className="btn btn-primary custom-btn disabled-spinner text-right" type="submit" name="submit" disabled={loading}>
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </>
        )}

        <p className="text-center pt-2">Credit Corp Financial Services Pty Limited | ABN 39 146 525 706 | Australian Credit Licence 400871</p>
      </div>
    </Layout>
  )
}

export { RefundForm }
