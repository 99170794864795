import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import { useMediaQuery } from 'react-responsive'

const ProductTypeList = ({ productTypeRoute, data, isFetching = true }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const sliderSettings = {
    dots: false,
    slidesToShow: 8,
    variableWidth: true,
    infinite: false,
  }
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' })

  return (
    <>
      {!isFetching && (
        <div className="container px-md-4 px-sm-0">
          <div className="row">
            {data?.productTypeName && <h1 className="display-6">{data?.productTypeName}</h1>}
            <div
              className="lead mb-0"
              dangerouslySetInnerHTML={{
                __html: data?.productTypeDescription,
              }}
            />
            {data?.childProductTypes.length > 0 && (
              <>
                <hr />
                <div className="row text-primary">
                  <h5>
                    {t('frontend.product.type.browse')} {data?.productTypeName}
                  </h5>
                </div>
                {isMobile ? (
                  <div className="w-100 d-flex overflow-auto px-0 mb-2">
                    {data?.childProductTypes
                      .sort((a, b) => (a.productTypeName > b.productTypeName ? 1 : -1))
                      .map(({ productTypeID, productTypeName, urlTitle }) => {
                        return (
                          <div key={productTypeID}>
                            <button
                              className="btn btn-light m-2 text-nowrap"
                              onClick={() => {
                                navigate(`/${productTypeRoute}/${urlTitle}`)
                              }}
                            >
                              {productTypeName}
                            </button>
                          </div>
                        )
                      })}
                  </div>
                ) : (
                  <div className="product-category-slick">
                    <Slider {...sliderSettings}>
                      {data?.childProductTypes
                        .sort((a, b) => (a.productTypeName > b.productTypeName ? 1 : -1))
                        .map(({ productTypeID, productTypeName, urlTitle }) => {
                          return (
                            <div key={productTypeID}>
                              <button
                                className="btn btn-light m-2 text-nowrap"
                                onClick={() => {
                                  navigate(`/${productTypeRoute}/${urlTitle}`)
                                }}
                              >
                                {productTypeName}
                              </button>
                            </div>
                          )
                        })}
                    </Slider>
                  </div>
                )}
                <hr />
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}
export { ProductTypeList }
