import { SlatwalApiService } from '@ten24/slatwall-storefront-react-private/services'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { getCurrentSite } from '../../actions/configActions'
import { Layout } from '../Layout/Layout'
import { getErrorMessage } from '@ten24/slatwall-storefront-react-private/utils'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import ComplaintPdfForRR from '../../assets/pdf/Complaint-Resolution-Procedure-RadioRentals-digi-March-2023.pdf'
import ComplaintPdfForRadioRentals from '../../assets/pdf/Complaint-Resolution-Procedure-RadioRentals-digi-March-2023.pdf'
const ComplaintsForm = () => {
  const currentSite = getCurrentSite()
  let navigate = useNavigate()
  const { complaint } = useSelector(state => state.configuration.forms)
  const [loading, setLoading] = useState(false)
  let downloadPDFLink = currentSite === 'rr' ? ComplaintPdfForRR : ComplaintPdfForRadioRentals
  const formik = useFormik({
    initialValues: {
      formResponse:{
        formID: complaint,
      },
      context:"addFormResponse",
      name: '',
      telephone: '',
      accountnumber: '',
      dob: '',
      email: '',
      postcode: '',
      address: '',
      comment: '',
    },
    onSubmit: (values , { resetForm }) => {
      setLoading(true)
      SlatwalApiService.content.addFormResponse(values).then(response => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
        if (response.isSuccess()) {
          toast.success("Form Submitted Successfully")
          resetForm()
          setTimeout(() => {
            navigate('/')
          }, 2000)
        } else {
          setLoading(false)
          toast.error(getErrorMessage(response.success().errors))
        }
      })
    },
  })
  const { t } = useTranslation()

  return (
    <Layout>
      <div className="pt-4 d-md-none d-sm-block">
        <div className="container py-4">
          <div className="order-lg-1 pr-lg-4 text-center">
            <h1 className="h2 mb-0 font-accent text-black">Complaints</h1>
          </div>
        </div>
      </div>
      <div className="container-xl container-custom-lg py-4">
        <div className="container-xl p-0 d-none d-lg-block">
          <div className="row">
            <div className="col-md-6 col-sm-12 py-3 mx-2">
              <div className="d-block mb-3 mb-lg-0 pt-lg-2">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item ">
                      <Link className="text-nowrap" to="/complaints">
                        Complaints
                      </Link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="container-xl p-0">
          <div style={{ borderStyle: 'none', borderWidth: '1px', borderRadius: '0px', margin: '0px', padding: '0px' }}>
            <h2 className='brand-blue' style={{ fontSize: '1.5rem', letterSpacing: 0, lineHeight: '27px', margin: '0 0 20px' }}>Please help us improve our service to you</h2>

            <p>{currentSite === 'rr' ? 'RR' : 'Radio Rentals'} strives for best practice in providing quality service to its customers. We know that sometimes things don’t go as they should and problems arise. If this happens to you please let us know so we can fix the problem.</p>

            <p>&nbsp;</p>
          </div>
          <form className="complaints-form" onSubmit={formik.handleSubmit}>
            <h2 className="brand-blue">
              Use the form below to send a complaint
            </h2>
            <div className="mt-4 mt-lg-0">
              <div className="row mb-4">
                <div className="col-12 col-md-6">
                  <label htmlFor="name" className="form-label fw-bold brand-blue">
                    Name*
                  </label>
                  <input className="form-control" id="name" value={formik.values.name} onChange={formik.handleChange} maxLength="255" name="name" type="text" required />
                </div>
                <div className="col-12 col-md-6 mt-4 mt-lg-0">
                  <label className="form-label fw-bold brand-blue" htmlFor="telephone">
                    Telephone*
                  </label>
                  <input className="form-control" id="telephone" value={formik.values.telephone} onBlur={formik.handleBlur} onChange={formik.handleChange} maxLength="40" name="telephone" size="20" type="text" required />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12 col-md-6">
                  <label className="form-label fw-bold brand-blue">Email Address*</label>
                  <input className="form-control" id="email" value={formik.values.email} onBlur={formik.handleBlur} onChange={formik.handleChange} maxLength="255" name="email" size="20" type="email" required />
                </div>
                <div className="col-12 col-md-6 mt-4 mt-lg-0">
                  <label className="form-label fw-bold brand-blue">{t('frontend.contact.form.postcode')}*</label>
                  <input className="form-control" id="postcode" value={formik.values.postcode} onBlur={formik.handleBlur} onChange={formik.handleChange} maxLength="18" name="postcode" size="20" type="text" required />
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12 col-md-6">
                  <label className="form-label fw-bold brand-blue">Account Number</label>
                  <input className="form-control" id="accountnumber" value={formik.values.accountNumber} onBlur={formik.handleBlur} onChange={formik.handleChange} maxLength="80" name="accountnumber" size="20" type="text" />
                </div>
                <div className="col-12 col-md-6 mt-4 mt-lg-0">
                  <label className="form-label fw-bold brand-blue">DOB*</label>
                  <input className="form-control" id="dob" value={formik.values.dob} onBlur={formik.handleBlur} onChange={formik.handleChange} name="dob" size="20" type="date" required />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-12">
                  <label className="form-label fw-bold brand-blue">Address*</label>
                  <textarea className="form-control" id="address" name="address" rows="3" type="text" wrap="soft" value={formik.values.address} onBlur={formik.handleBlur} onChange={formik.handleChange}></textarea>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-12">
                  <label className="form-label fw-bold brand-blue">Comment*</label>
                  <textarea className="form-control" id="comment" name="comment" rows="3" type="text" wrap="soft" value={formik.values.comment} onBlur={formik.handleBlur} onChange={formik.handleChange}></textarea>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12 col-md-6">
                  <button className="btn btn-primary custom-btn disabled-spinner" disabled={loading} type="submit" name="submit">
                    {t('frontend.contact.form.submit')}
                    <i class="spinner-border spinner-border-sm"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>

          <div style={{ justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', backgroundPosition: 'left top', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundAttachment: 'scroll', borderStyle: 'none', borderWidth: '1px', borderRadius: '0px', margin: '0px 0px 10px' }}>
            <div data-appearance="default" data-content-type="text" data-element="main" style={{ borderStyle: 'none', borderWidth: '1px', borderRadius: '0px', margin: '0px', padding: '0px' }}>
              <p>
                &nbsp;
                <a rel="noreferrer" href={downloadPDFLink} target="_blank">
                  Click here&nbsp;
                </a>
                to download a copy of our Dispute Resolution brochure
              </p>

              <h2 style={{ color: '#0d50a0' }}>External Dispute Resolution</h2>

              <p>If an issue has not been resolved to your satisfaction, you can lodge a complaint with the Australian Financial Complaints Authority (AFCA). AFCA provides fair and independent financial services complaint resolution that is free to consumers.</p>

              <p>&nbsp;</p>

              <p>
                <strong>Website</strong>:&nbsp;
                <a href="https://www.afca.org.au/" rel="noreferrer" target="_blank">
                  www.afca.org.au
                </a>
              </p>

              <p>
                <strong>Telephone</strong>: 1800 931 678 (free call)
              </p>

              <p>
                <strong>Email</strong>:&nbsp;<a href="mailto:info@afca.org.au">info@afca.org.au</a>
              </p>

              <p>
                <strong>In writing to</strong>: Australian Financial Complaints Authority, GPO Box 3, Melbourne VIC 3001
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export { ComplaintsForm }
