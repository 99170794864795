import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { getUser, getWishLists } from '@ten24/slatwall-storefront-react-private/actions'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import App from './App'
import { logout } from '@ten24/slatwall-storefront-react-private/actions'

const AppSwitcher = () => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const [safeToLoad, setSafeToLoad] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (document.readyState === 'loading') {
      if (localStorage.getItem('closeTrigger') && Date.now() - localStorage.getItem('closeTrigger') > 10000) {
        dispatch(logout())
      }
      localStorage.removeItem('closeTrigger')
    }

    function closeTrigger() {
      localStorage.setItem('closeTrigger', Date.now())
    }

    window.addEventListener('beforeunload', closeTrigger)
    return () => window.removeEventListener('beforeunload', closeTrigger)
  }, [dispatch])

  useEffect(() => {
    if (pathname.startsWith('/ssoLogin')) {
      const authCode = new URLSearchParams(search).get('token')
      const redirect = new URLSearchParams(search).get('redirect') || '/my-account'
      localStorage.setItem('token', authCode)
      dispatch(getUser()).then(() => {
        dispatch(getWishLists())
        navigate(redirect)
        toast.success(t('frontend.account.auth.success'))
        setSafeToLoad(true)
      })
    } else {
      setSafeToLoad(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (safeToLoad) return <App />
  return null
}

export { AppSwitcher }
