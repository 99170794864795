import { REQUEST_REDIRECT_URL, RECEIVE_REDIRECT_URL } from '../actions'

const initialState = {
    refreshToken: '',
    redirectURL: '',
    accessToken: '',
    isLoaded: false,
    isFetching: false
}

const redirectInfo = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_REDIRECT_URL:
            return { ...state, isFetching: true }
        case RECEIVE_REDIRECT_URL:
            return { ...state, ...action.redirectData, isFetching: false, isLoaded: true }
        default:
            return { ...state, isFetching: false }
    }
}

export default redirectInfo