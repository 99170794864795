import { useNavigate, useLocation } from 'react-router-dom'
import { processQueryParameters } from '@ten24/slatwall-storefront-react-private/utils'
import { Modal } from '@ten24/slatwall-storefront-react-private/components'
import { useState } from 'react'
import { t } from 'i18next'
import { ReactComponent as SortIcon } from '../../assets/images/sort.svg'

const CheckBox = ({ option, facetKey, facetValue, setFilterData, facetFilters }) => {
  const [checked, setChecked] = useState(facetFilters[facetKey]?.includes(facetValue) || false)

  return (
    <div className="d-flex justify-content-between">
      <div className="form-check pb-0 pe-3">
        <input
          className="form-check-input"
          type="checkbox"
          checked={checked}
          onChange={() => {
            setFilterData(facetKey, facetValue)
            setChecked(!checked)
          }}
          id={option.name}
          defaultValue={`${facetKey}=${facetValue}`}
        />
        <label
          className="form-check-label"
          onClick={() => {
            setFilterData(facetKey, facetValue)
            setChecked(!checked)
          }}
        >
          {option.name}
        </label>
      </div>
      <div className="pt-1">
        {option.count && (
          <span style={{ fontSize: 12 }} className="text-muted ml-3 text-right">
            {option.count}
          </span>
        )}
      </div>
    </div>
  )
}

const ListingToolBar = ({ orderBy, setSort, recordsCount, filtering }) => {
  const loc = useLocation()
  const navigate = useNavigate()
  const qs = processQueryParameters(loc.search)
  const [showModal, setModal] = useState(false)
  var result = {}
  const { sorting } = filtering

  const [facetFilters, setFacetFilters] = useState(result)

  const applyFilter = () => {
    var queryString = Object.keys(facetFilters)
      .map(key => key + '=' + facetFilters[key])
      .join('&')

    const keywordSearch = new URLSearchParams(window.location.search.substring(1)).get('keyword')
    queryString += !!keywordSearch ? `&keyword=${keywordSearch}` : ''

    navigate({
      pathname: loc.pathname,
      search: queryString,
    })
    setModal(false)
  }

  const clearFilter = () => {
    setFacetFilters({})
    navigate({
      pathname: loc.pathname,
      search: '',
    })
    setModal(false)
  }

  const setFilterData = (facetIdentifier, filterData) => {
    if (facetIdentifier in facetFilters) {
      if (facetFilters[facetIdentifier].includes(filterData)) {
        let newfiltersArr = facetFilters[facetIdentifier].filter(identifier => identifier !== filterData)
        facetFilters[facetIdentifier] = newfiltersArr
      } else {
        let newfiltersArr = [...facetFilters[facetIdentifier], filterData]
        facetFilters[facetIdentifier] = newfiltersArr
      }
    } else {
      facetFilters[facetIdentifier] = [filterData]
    }
    setFacetFilters({ ...facetFilters })
  }

  if (qs.orderBy) {
    orderBy = qs.orderBy
  }
  let dropdownLabel = []
  if (sorting) {
    dropdownLabel = sorting.options.filter(data => data.value === orderBy)
  }
  if (recordsCount < 1) return null

  return (
    <div className="sort-options-button d-flex justify-content-center px-sm-0">
      <Modal show={showModal} setShow={setModal} title={t('frontend.product.listing.filter')} modalClass="addReviewModal" size="small">
        <div className="overflow-auto mb-5">
          {filtering &&
            Object.values(filtering)
              .filter(item => item.facetKey !== 'orderBy')
              .map((item, key) => {
                if (item.facetKey === 'category') item.name = 'Category'
                return (
                  <div key={`${item.facetKey}-${key}`}>
                    {item?.options?.length > 0 && (
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${item.facetKey}`} aria-expanded="true" aria-controls={item.facetKey}>
                            {item.name}
                          </button>
                        </h2>
                        <div id={item.facetKey} className="accordion-collapse collapse" aria-labelledby="">
                          <div className="accordion-body">
                            {item.options.map(option => {
                              let facetKey = option?.slug ? `${item.facetKey}_slug` : item.facetKey
                              let facetValue = option?.slug ? option.slug : option.value
                              return <CheckBox option={option} key={`${facetKey}-${facetValue}`} facetKey={facetKey} facetValue={facetValue} setFilterData={setFilterData} facetFilters={facetFilters} />
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                    {item?.subFacets &&
                      Object.values(item.subFacets).map(facetoption => {
                        return (
                          <div className="accordion-item" key={facetoption.subFacetKey}>
                            <h2 className="accordion-header">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${facetoption.subFacetKey}`} aria-expanded="true" aria-controls={facetoption.subFacetKey}>
                                {facetoption.name}
                              </button>
                            </h2>
                            <div id={facetoption.subFacetKey} className="accordion-collapse collapse" aria-labelledby="">
                              <div className="accordion-body">
                                {facetoption?.options?.length &&
                                  facetoption.options.map(option => {
                                    return <CheckBox option={option} key={option.id} facetKey={`${item.facetKey}_${facetoption.subFacetKey}`} facetValue={option.name} setFilterData={setFilterData} facetFilters={facetFilters} />
                                  })}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                )
              })}
          <div className="row text-center m-2">
            <button type="button" className="btn border-transparent mb-2 brand-blue" onClick={clearFilter}>
            <i className="bi bi-x"></i>
              {t('frontend.product.listing.clearfilter')}
            </button>
            <button type="button" className="btn btn-primary mb-2" onClick={applyFilter}>
              {t('frontend.product.listing.apply')}
            </button>
          </div>
        </div>
      </Modal>
      <div className="text-right w-100">
        <div className="btn-group w-100">
          <button type="button" className="btn btn-secondary dropdown-toggle brand-navy col-6 px-0 mx-0" onClick={() => setModal(true)}>
            <span className="filter-text">{t('frontend.product.listing.filter')}</span>
            <span>
              &nbsp;<i className="bi bi-sliders rotate-90 px-2"></i>
            </span>
          </button>
          <button type="button" className="btn btn-secondary dropdown-toggle brand-navy col-6 px-0 mx-0" data-bs-toggle="dropdown" aria-expanded="false">
            <span className="sort-text">{dropdownLabel.length > 0 ? dropdownLabel[0].name : sorting?.name ? sorting.name : 'Sort'}</span>
            <span className="px-1 d-flex align-items-center">
              &nbsp;
              <SortIcon height={20} width={20} />
            </span>
          </button>
          <ul className="dropdown-menu dropdown-menu-end">
            {sorting &&
              sorting.options &&
              sorting.options.length > 1 &&
              sorting.options.map(({ name, value }) => {
                return (
                  <li
                    key={name}
                    onClick={() => {
                      setSort(value)
                    }}
                    className={dropdownLabel.length && dropdownLabel[0].name === name ? 'dropdown-item active' : 'dropdown-item'}
                  >
                    {name}
                  </li>
                )
              })}
          </ul>
        </div>
      </div>
    </div>
  )
}
export { ListingToolBar }
