import { useState,useEffect } from 'react';
import { SlatwalApiService } from '@ten24/slatwall-storefront-react-private/services'
import { getErrorMessage } from '@ten24/slatwall-storefront-react-private/utils'
import { toast } from 'react-toastify'

export const useGetLegacyOrderList=()=>{
  let [request, setRequest] = useState({ isFetching: false, isLoaded: false, makeRequest: false, data: {}, error: '', params: {} })
  useEffect(() => {
    if (request.makeRequest) {
      SlatwalApiService.sdkScope.httpService.slatwallResponse('get', `api/ccg/getlegacyOrders`, request.params, {}, null).then(response => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
        if (response.isSuccess()) {
          setRequest({ data: response.success().data, isFetching: false, isLoaded: true, makeRequest: false, params: {} })
        } else {
          setRequest({ data: {}, isFetching: false, makeRequest: false, isLoaded: true, params: {}, error: 'Something was wrong' })
        }
      })
    }
  }, [request, setRequest])

  return [request, setRequest]
}

export const useLegacyOrderList = ({ pageRecordsShow = 10, params = '{}' }) => {
  const [keyword, setSearchTerm] = useState('')
  let [orders, setRequest] = useGetLegacyOrderList()  
  const search = (currentPage = 1) => {
    const preFilter = JSON.parse(params)
    setRequest({ ...orders, params: { ...preFilter, currentPage, pageRecordsShow: 10, keyword }, makeRequest: true, isFetching: true, isLoaded: false })
  }
  useEffect(() => {
    const preFilter = JSON.parse(params)
    let didCancel = false
    if (!orders.isFetching && !orders.isLoaded && !didCancel) {
      setRequest({ ...orders, isFetching: true, isLoaded: false, params: { ...preFilter, pageRecordsShow: pageRecordsShow, keyword }, makeRequest: true })
    }
    return () => {
      didCancel = true
    }
  }, [orders, keyword, setRequest, pageRecordsShow, params])

  return { search, setSearchTerm, keyword, orders, totalPages: Math.ceil(orders?.data?.records / pageRecordsShow) }
    
}