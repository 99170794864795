import { useTranslation } from 'react-i18next'

const InterestDisclaimer = ({background = 'bg-lightgray'}) => {
  const { t } = useTranslation()
  return (
    <>
    <div className={background+` p-2 text-center container mb-2`}>
      <div className="row d-flex justify-content-center">
        <div className="col-6">
          <p className="fw-bold fs-18 disclaimer">{t('frontend.interestDisclaimer.interest')}</p>
          <p className="fw-bold fs-20 mb-0 disclaimer">{t('frontend.interestDisclaimer.rate')}</p>
        </div>
        <div className="col-6">
          <p className="fw-bold fs-18 disclaimer">{t('frontend.interestDisclaimer.comparison')}</p>
          <p className="fw-bold fs-20 mb-0 disclaimer">{t('frontend.interestDisclaimer.rate')}</p>
        </div>
       
      </div>
    </div>
     <div>
     <p className="fs-12">{t('frontend.copyright.terms.ComparisonRate')}</p>
     <p className="fs-12"><span className="fw-bold">{t('frontend.copyright.WarningTitle')}</span> {t('frontend.copyright.terms.WarningContent')}</p>
     </div>
     </>
  )
}

export { InterestDisclaimer }
