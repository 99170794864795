import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRedirectUrl } from '../../actions'
import { isAuthenticated } from '@ten24/slatwall-storefront-react-private/utils'
import { Modal, Button, SimpleImage } from '@ten24/slatwall-storefront-react-private/components'
import { useTranslation } from 'react-i18next'
import { CreateAccount } from '../Account/CreateAccount/CreateAccount'
import { useNavigate } from 'react-router'

const ApplyNow = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const user = useSelector(state => state.userReducer)
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [applyNowLoading, setApplyNowLoading] = useState(false)
  const [modalPage, setModalPage] = useState('page1')
  const callbackCreateAccount = () => {
    dispatch(getRedirectUrl())
    setShowModal(false)
  }

  const btnClass = props?.btnClass ? props.btnClass : 'btn btn-apply'
  const redirectUrl = props?.isCartPage ? '/my-account/login?redirect=/shopping-cart' : '/my-account/login'
  const { globalconfig = {} } = useSelector(state => state.content)
  const { contentColumns } = globalconfig
  return (
    <>
      {!isAuthenticated() && (
        <>
          <Button
            classList={btnClass}
            onClick={() => {
              if (props?.isCartPage) navigate('/my-account/createAccount')
              else setShowModal(true)
            }}
          >
            {t('frontend.applynow.first')}
          </Button>
          <Modal
            show={showModal}
            setShow={() => {
              setShowModal(false)
              setModalPage('page1')
            }}
            title={''}
            backgroundstatic={true}
          >
            {modalPage === 'page1' && (
              <div className="row align-items-center text-center gy-4 px-lg-4">
                <div className="col-12 h3 text-primary mt-0">{contentColumns?.title}</div>
                <p>{t('frontend.account.applyNow.blurb')}</p>
                {contentColumns &&
                  contentColumns?.columns?.map((item,index) => {
                    return (
                      <div className="col-6" key={`applynow_${index}`}>
                        <SimpleImage className="pb-2" src={item.imagePath} alt={item.title} />
                        <div
                          className="fs-14  m-auto"
                          dangerouslySetInnerHTML={{
                            __html: item.contentSummary || '',
                          }}
                        />
                      </div>
                    )
                  })}
                <div className="col-12">
                  <Button classList="btn btn-primary btn-full w-100" onClick={() => setModalPage('page2')}>
                    {t('frontend.applymodal.goShopping')}
                  </Button>
                </div>
                <div className="col-12 mt-4">
                  <Button
                    classList="btn brand-blue btn-full w-100"
                    onClick={() => {
                      setShowModal(false)
                      setModalPage('page1')
                      window.location.pathname = '/how-it-works'
                    }}
                  >
                    {t('frontend.applymodal.howitworks')}
                  </Button>
                </div>
                <div className="col-12 mt-4">
                  <Button
                    classList="btn brand-blue btn-full w-100"
                    onClick={() => {
                      setShowModal(false)
                      setModalPage('page1')
                      navigate(redirectUrl)
                    }}
                  >
                    {t('frontend.applymodal.haveAnAccount')}
                  </Button>
                </div>
              </div>
            )}
            {modalPage === 'page2' && <CreateAccount successCallbackHandler={callbackCreateAccount} />}
          </Modal>
        </>
      )}
      {isAuthenticated() && user.termAccountAvailableCredit === 0 && !user?.eligibilityInfo?.Data && (
        <>
          <div>
            <Button
              classList={btnClass}
              onClick={() => {
                setApplyNowLoading(true)
                dispatch(getRedirectUrl()).then(() => setApplyNowLoading(false))
              }}
              isLoading={applyNowLoading}
            >
              {t('frontend.applynow.first')}
            </Button>
          </div>
        </>
      )}
    </>
  )
}
export { ApplyNow }
