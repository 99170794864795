import React from 'react'
import { SimpleImage } from '@ten24/slatwall-storefront-react-private/components'
import { useUtilities } from '@ten24/slatwall-storefront-react-private/hooks'
import { useMediaQuery } from 'react-responsive'

const HomeSlider = ({ slider }) => {
  let { eventHandlerForWSIWYG } = useUtilities()
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const generateUrlString = (urlString) => {
    if (urlString !== undefined && urlString.length > 0) {
      if (urlString.indexOf('http') === -1) {
        return '/' + urlString
      } else {
        return urlString;
      }
    } else {
      return "/#"
    }
  }

  if (!slider) return null
  return (
    <div className="hero content-slider">
      {slider?.slides && slider.slides.length > 0 && (
        <div id="carousel" className="carousel">
          <div className="carousel-inner">
            {slider?.slides?.map(({ contentBody, title, imagePath, linkUrl, linkLabel }, key) => {
              return (
                <div key={title} className={key === 0 ? `carousel-item ${!isTabletOrMobile ? " active " : ""} d-none d-lg-block ` : `${isTabletOrMobile ? " active " : ""}carousel-item d-block d-lg-none`}>
                  <div className="carousel-image">
                    <a onClick={eventHandlerForWSIWYG} href={generateUrlString(linkUrl)}>
                      <SimpleImage src={imagePath} alt="carouselImage" />
                    </a>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}
export { HomeSlider }
