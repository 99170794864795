import { default as preload } from '@ten24/slatwall-storefront-react-private/src/preload'

const data = {
  ...preload,
  site: {
    ...preload.site,
    settings: {
      siteDefaultCountry: 'au',
    },
    currencyCode: 'AUD',
    hibachiInstanceApplicationScopeKey: '',
    siteName: process.env.REACT_APP_NAME,
    siteID: '',
    defaultCountry: 'AU',
    siteCode: process.env.REACT_APP_SITE_CODE,
  },
  cmsProvider: 'slatwallCMS',
  products: {
    fallbackImageCall: false,
    loginRequiredForPrice: false,
  },
  seo: {
    title: 'CCG Storefront',
    titleMeta: '',
  },
  footer: {
    formLink: '',
  },
  theme: {
    ...preload.theme,
    host: process.env.REACT_APP_HOST_URL,
    primaryColor: '#2478CC',
  },
  forms: {
    contact: '8ab1b2de829f6eec0182a652a36313d2',
    complaint: '8a8286b9869192a5018693c48d0a02c0',
    goGreen : '8a83878686b989400186c36601171247',
    refundForm: '8a8385d8903d97620190536f05fb1332',
    serviceApplicationForm: '8a8385d8903d9762019053ba30eb136e'
  },
  currencies: {
    AUD: {
      currencySymbol: '$',
      formatMask: ' ',
    },
  },
  defaultCCGPostalcode: {
    name: 'CCG_USER_PREF_POSTALCODE',
    streetAddress: '--NA--',
    city: 'SURRY HILLS',
    stateCode: 'NSW',
    postalCode: '2010',
    countryCode: 'AU',
    isDefaultAddress: true,
  },
  defaultRRPostalcode: {
    name: 'CCG_USER_PREF_POSTALCODE',
    streetAddress: '--NA--',
    city: 'ADELAIDE',
    stateCode: 'SA',
    postalCode: '5000',
    countryCode: 'AU',
    isDefaultAddress: true,
  },
  listings: {
    productListing: {
      isSales: true,
      showInput: true,
      viewMode: 'GRID',
      viewModeOptions: ['GRID', 'LISTING'],
      forcedFilterOptions: ['productType_slug', 'brand_slug', 'category_slug'],
      headings: [
        { heading: 'Product Name', value: 'product_productName' },
        { heading: 'Sku Code', value: 'sku_skuCode' },
      ],
      buttonLabel: 'frontend.product.add_to_cart',
      params: {
        propertyIdentifierList: '',
        includeSKUCount: true,
        includeResizedImages: false,
        applyStockFilter: false,
        productsListingFlag: true,
        includeOptions: true,
        includeSettings: true,
        includePagination: true,
        includePotentialFilters: true,
      },
      filters: {
        brand_slug: '',
        orderBy: 'product.essentialFlag|DESC',
        pageSize: '12',
        currentPage: '1',
        keyword: '',
        productType_slug: '',
        category_slug: '',
      },
      returnFacetList: 'brand,sorting,category',
      returnFacetListWithFilter: 'brand,option,category,attribute,sorting,priceRange,productType',
    },
    skuListing: {
      isSales: true,
      showInput: true,
      viewMode: 'GRID',
      viewModeOptions: ['GRID', 'LISTING'],
      forcedFilterOptions: ['productType_slug', 'brand_slug', 'category_slug'],
      headings: [
        { heading: 'Product Name', value: 'product_productName' },
        { heading: 'Sku Code', value: 'sku_skuCode' },
      ],
      buttonLabel: 'frontend.product.add_to_cart',
      params: {
        propertyIdentifierList: '',
        includeSKUCount: true,
        includeResizedImages: false,
        applyStockFilter: false,
        productsListingFlag: true,
        includeSettings: true,
        includePagination: true,
        includePotentialFilters: true,
      },
      filters: {
        brand_slug: '',
        orderBy: 'product.essentialFlag|DESC',
        pageSize: '12',
        currentPage: '1',
        keyword: '',
        productType_slug: '',
        category_slug: '',
      },
    },
    bulkOrder: {
      isSales: true,
      showInput: true,
      viewMode: 'LISTING',
      viewModeOptions: ['GRID', 'LISTING', 'LISTINGLINEBYLINE'],
      forcedFilterOptions: [],
      headings: [
        { type: 'property', heading: '', value: 'product_productName' },
        { type: 'data', heading: 'Product Name', value: 'product_productName' },
        { type: 'data', heading: 'Sku Code', value: 'sku_skuCode' },
        { type: 'button', buttonType: 'viewProduct', linkLabel: 'View' },
      ],
      buttonLabel: 'frontend.bulkorder.add_to_list',
      params: {
        propertyIdentifierList: '',
        includeSKUCount: false,
        includeResizedImages: true,
        applyStockFilter: false,
        productsListingFlag: false,
        includeSkus: true,
        includeOptions: true,
        includePagination: true,
        includePotentialFilters: true,
        includeSettings: true,
      },
      filters: {
        brand_slug: '',
        orderBy: '',
        pageSize: '12',
        currentPage: '1',
        keyword: '',
        productType_slug: '',
        category_slug: '',
      },
    },
  },
}
export default data
