import { getWishLists, receiveSubscriptionCart, receiveUser, reciveConfiguration, requestConfiguration, requestSubscriptionCart, requestUser } from "@ten24/slatwall-storefront-react-private/actions"
import { SlatwalApiService } from "@ten24/slatwall-storefront-react-private/services"
import { getErrorMessage } from "@ten24/slatwall-storefront-react-private/utils"
import { toast } from "react-toastify"
import { receiveCart, requestCart } from "./orderActions"

export const getCookie = name => {
  return document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=')
    return parts[0] === name ? decodeURIComponent(parts[1]) : r
  }, '')
}

export const setCookie = (name, value, days = 7, path = '/') => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString()
  document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path
}

export const getCurrentSite = () => {
  let appConfiguration = JSON.parse(localStorage.getItem('appConfiguration') || '{}')
  return appConfiguration.currentSite
}

export const getConfiguration = (returnJSONObjects = 'cart,account,orderTemplateCart') => {
  return async (dispatch, getState) => {
    dispatch(requestConfiguration())
    dispatch(requestSubscriptionCart())
    dispatch(requestUser())
    dispatch(requestCart())

    return await SlatwalApiService.content
      .getConfiguration({
        siteCode: process.env.REACT_APP_SITE_CODE,
        returnJSONObjects,
      })
      .then(response => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
        if (response.isSuccess()) {
          let serverConfig = response.success().config
          // retail and local routes not found on the server
          // const legacyRoutes = localRoutes.filter(localRoute => serverConfig.router.filter(route => route.URLKeyType === localRoute.URLKeyType).length === 0)
          // serverConfig.router = [...serverConfig.router, ...legacyRoutes]

          //let appConfiguration = { currentSite: process.env.REACT_APP_SITE_CODE, sites: [] }
          let appConfiguration = { currentSite: window.location.hostname.includes('rr') ? 'rr' : 'creditcorpgroup', sites: [] }
          const currentConfiguration = JSON.parse(localStorage.getItem('appConfiguration') || '{}')
          // merge old settings with new only for sites that are still valid
          appConfiguration.sites = serverConfig?.sites?.map(site => {
            const cachedSite = currentConfiguration?.sites?.filter(cachedSite => cachedSite.siteCode === site.siteCode)?.at(0)
            if (!!cachedSite) return { siteCode: site.siteCode, currencyCode: site.currencyCode, locale: site.settings.siteDefaultCountry, settings: site?.settings, ...cachedSite }
            return { siteCode: site.siteCode, currencyCode: site.currencyCode, locale: site?.settings?.siteDefaultCountry || 'en', settings: site?.settings }
          })
          // if we have a current site bring it over
          if (!!currentConfiguration.currentSite) appConfiguration.currentSite = currentConfiguration.currentSite
          // validate currentSite is valid for list
          if (!appConfiguration.sites.find(site => site.siteCode === appConfiguration.currentSite) && appConfiguration.sites.length) appConfiguration.currentSite = appConfiguration.sites.at(0).siteCode
          localStorage.setItem('appConfiguration', JSON.stringify(appConfiguration))
          localStorage.setItem('siteCode', appConfiguration.currentSite)
          dispatch(reciveConfiguration(serverConfig))
          dispatch(receiveUser(response.success().account))
          if (response.success()?.account?.accountID?.length) dispatch(getWishLists())
          dispatch(receiveCart(response.success().cart))
          if (response.success()?.orderTemplateCart) {
            dispatch(receiveSubscriptionCart(response.success()?.orderTemplateCart))
          } else {
            dispatch(receiveSubscriptionCart({}))
          }
        } else {
          dispatch(reciveConfiguration({}))
        }
        return response
      })
  }
}