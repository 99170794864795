import { Link, useNavigate } from 'react-router-dom'
import { Layout } from '../Layout/Layout'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import { SlatwalApiService, sdkURL, axios as SwAxios } from '@ten24/slatwall-storefront-react-private/services'
import { toast } from 'react-toastify'
import axios from 'axios'
import { getErrorMessage } from '@ten24/slatwall-storefront-react-private/utils'
import { memo, useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import ReCAPTCHA from 'react-google-recaptcha'
import { Modal } from '@ten24/slatwall-storefront-react-private/components'

export const formatPayload = payload => {
  const newPayload = { ...payload }
  for (const [key, value] of Object.entries(payload)) {
    if (key.includes('-filename')) {
      var newKey = key.replace('-filename', '')
      newPayload[newKey] = value
    }
  }
  return newPayload
}

const FullScreenLoader = () => (
  <div className="full-screen-loader">
    <div className="loading-text">
      Uploading Files, please wait...
      <br />
      Do not close the window
    </div>
    <br/>
    <div className="loader"></div>
  </div>
)

const FieldLayout = memo(({ className = 'col-12 col-md-6 mt-4 mt-lg-2', label, name, children, errors, required }) => {
  return (
    <div className={className}>
      <label htmlFor={name} className="form-label brand-blue ">
        {label} {label && required ? <span className="text-danger">*</span> : null}
      </label>
      {children}
      {errors && <span className="form-error-msg">{errors}</span>}
    </div>
  )
})

const InputField = memo(({ className = 'col-12 col-md-6 mt-4 mt-lg-2', label, name, errors, setFieldValue, required, type = 'text', ...rest }) => {
  useEffect(() => {
    return () => {
      setFieldValue(name, '')
      if (type === 'file') {
        setFieldValue(`${name}-filename`, undefined)
        setFieldValue(`${name}-file`, undefined)
        setFieldValue(`${name}-path`, undefined)
      }
    }
  }, [name, setFieldValue, type])
  return (
    <FieldLayout className={className} label={label} name={name} errors={errors} required={required}>
      {type === 'textarea' ? (
        <textarea className="form-control" name={name} type={'text'} required={required} {...rest} />
      ) : (
        <input
          className="form-control"
          name={name}
          type={type}
          required={required}
          multiple={type === 'file'}
          {...rest}
          {...(type === 'file'
            ? {
                onChange: e => {
                  setFieldValue(name, e.target.value)
                  setFieldValue(
                    `${name}-filename`,
                    Array.from(e.target.files).map(file => file.name)
                  )
                  setFieldValue(`${name}-file`, Array.from(e.target.files))
                  setFieldValue(
                    `${name}-path`,
                    Array.from(e.target.files).map(file => e.target.value)
                  )
                },
              }
            : {})}
        />
      )}
    </FieldLayout>
  )
})

const RadioField = memo(({ className = 'col-12 col-md-6 mt-4 mt-lg-2', label, name, errors, options, value, required, optionsClassname = 'd-flex flex-wrap', optionClassname = 'm-2 d-flex align-items-baseline', setFieldValue, ...rest }) => {
  useEffect(() => {
    return () => {
      setFieldValue(name, '')
    }
  }, [name, setFieldValue])
  return (
    <FieldLayout className={className} label={label} name={name} errors={errors} required={required}>
      <div className={optionsClassname}>
        {options.map((option, index) => (
          <div key={option} className={optionClassname}>
            <input type="radio" id={`${name}-${index}`} name={name} value={option} checked={option === value} required={required} {...rest} />
            <label htmlFor={`${name}-${index}`} className="ps-2 mb-0">
              {option}
            </label>
          </div>
        ))}
      </div>
    </FieldLayout>
  )
})

const SelectField = memo(({ className = 'col-12 col-md-6 mt-4 mt-lg-2', label, name, errors, options, value, required, optionsClassname = 'd-flex flex-wrap', optionClassname = 'm-2 d-flex align-items-baseline', setFieldValue, ...rest }) => {
  useEffect(() => {
    return () => {
      setFieldValue(name, '')
    }
  }, [name, setFieldValue])
  return (
    <FieldLayout className={className} label={label} name={name} errors={errors}>
      <select className="form-control" name={name} required={required} {...rest}>
        <option value="">Choose one...</option>
        {options.map(option => (
          <option value={option}>{option}</option>
        ))}
      </select>
    </FieldLayout>
  )
})

const REPAIR_TYPE_FAULTY = 'Item Faulty / Item Services Required'
const REPAIR_TYPE_LOSS = 'Loss / Damage'
const REPAIR_TYPES = [REPAIR_TYPE_FAULTY, REPAIR_TYPE_LOSS]

const LOCATIONS = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA']

const LOSS_DAMAGE_FIRE = 'Fire/Smoke'
const LOSS_DAMAGE_ROBBERY = 'Robbery, Burglary or House Break-in'
const LOSS_DAMAGE_LOST = 'Lost'
const LOSS_DAMAGE_FLOOD = 'Flood'
const LOSS_DAMAGE_LIGHTNING = 'Lightning'
const LOSS_DAMAGE_DAMAGE = 'Damage'
const LOSS_DAMAGE_WINDSTORM = 'Windstorm'
const LOSS_DAMAGE_BURSTING = 'Bursting of a fixed water installation'
const LOSS_DAMAGE_THEFT = 'Theft'
const LOSS_DAMAGE_OTHER = 'Other'

const LOSS_DAMAGE_REASONS = [LOSS_DAMAGE_FIRE, LOSS_DAMAGE_ROBBERY, LOSS_DAMAGE_LOST, LOSS_DAMAGE_FLOOD, LOSS_DAMAGE_LIGHTNING, LOSS_DAMAGE_DAMAGE, LOSS_DAMAGE_WINDSTORM, LOSS_DAMAGE_BURSTING, LOSS_DAMAGE_THEFT, LOSS_DAMAGE_OTHER]

const HOW_OFFENDER_OPTIONS = ['Door Jemmied', 'Window smashed', 'Roof forced', 'Other']

const BOOLEAN_YES = 'Yes'
const BOOLEAN_NO = 'No'
const BOOLEAN_OPTIONS = [BOOLEAN_YES, BOOLEAN_NO]

async function postFilesToAWSS3(formSubmitResponse, values, setFilesSubmittedLength, setIsLoading) {
  const xmlHeaderLengthArr = [];
  localStorage.setItem('filesUploadArray', JSON.stringify([]))
  for (const fileKey of Object.keys(formSubmitResponse?.uploadDetails || {})) {
    const xmlHeaderInputs = formSubmitResponse.uploadDetails[fileKey]
    let localStorageFilesUploaded = JSON.parse(localStorage.getItem('filesUploadArray')) || []
     
    for (let i = 0; i < xmlHeaderInputs.length; i++) {
      const item = xmlHeaderInputs[i]
      xmlHeaderLengthArr.push(item)
      const fileHeader = { file: values[`${fileKey}-file`][i] }
        
      try {
        await axios({
          method: 'POST',
          url: item?.url,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: { ...item?.inputs, ...fileHeader },
        })
        localStorageFilesUploaded.push(i)
        localStorage.setItem('filesUploadArray', JSON.stringify(localStorageFilesUploaded))
        window.dispatchEvent(new Event('storage')) // Manually trigger storage event
      } catch (err) {
        console.error(err)
      }
    }
  }
  setFilesSubmittedLength(xmlHeaderLengthArr.length)
    
}

const RepairReplacementForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { serviceApplicationForm } = useSelector(state => state.configuration.forms)
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const navigate = useNavigate()
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const customerAccountNumberRegex = /^\d{8}$/
  const [validRecaptcha, setValidRecaptcha] = useState(false)
  const [validateModal, setValidateModal] = useState(false)
  const recaptchaSitekey = useSelector(state => state?.configuration?.site?.settings?.siteStorefrontConfig?.siteRecaptchaSiteKey)
  const [emailSend, setEmailSend] = useState(false)
  const [formResponseID, setFormResponseID] = useState()
  const [repairType, setRepairType] = useState(REPAIR_TYPE_FAULTY)
  const [filesSubmittedLength, setFileSubmittedlength] = useState()
  const [isChecked, setIsChecked] = useState(false)
  const repairSchema = Yup.object().shape({
    customerAccountNumber: Yup.string().matches(customerAccountNumberRegex, 'Kindly enter a valid customer account number'),
    firstName: Yup.string().required('Complete this field.'),
    surname: Yup.string().required('Complete this field.'),
    emailAddress: Yup.string().email('Kindly enter valid email').required('Complete this field.'),
    postalCode: Yup.string().required('Complete this field.'),
    state: Yup.string().required('Complete this field.'),
    address: Yup.string().required('Complete this field.'),
    dob: Yup.date().max(new Date(), 'Date of birth cannot be in the future').required('Complete this field.'),
    homeOrWorkNumber: Yup.string().matches(phoneRegExp, 'Kindly enter valid contact number').min(10, 'Minimum 10 digits').max(10, 'Maximun 10 digits'),
    mobileNumber: Yup.string().matches(phoneRegExp, 'Kindly enter valid contact number').required('Complete this field.').min(10, 'Minimum 10 digits').max(10, 'Maximun 10 digits'),
    insuranceCompany: Yup.string().when('insurance', { is: 'Yes', then: Yup.string().required('Complete this field.') }),
    policyNumber: Yup.string().when('insurance', { is: 'Yes', then: Yup.string().required('Complete this field.') }),
    claimNumber: Yup.string().when('insurance', { is: 'Yes', then: Yup.string().required('Complete this field.') }),
    itemRequiringService: Yup.string().required('Complete this field.'),
    brand: Yup.string().required('Complete this field.'),
    dateOfIncident: Yup.date()
      .max(new Date(), 'Date of incident cannot be in the future')
      .when('repairType', { is: REPAIR_TYPE_LOSS, then: Yup.date().required('Complete this field.') }),
    hasServicedBefore: Yup.string().when('repairType', { is: REPAIR_TYPE_FAULTY, then: Yup.string().required('Complete this field.') }),
    serviceDescription: Yup.string().when('repairType', { is: REPAIR_TYPE_FAULTY, then: Yup.string().required('Complete this field.') }),
    repairType: Yup.string().required('Complete this field.'),
    lossDamageReason: Yup.string(),
    describeWhatHappened: Yup.string().when('lossDamageReason', { is: lossDamageReason => !!lossDamageReason, then: Yup.string().required('Complete this field.') }),
  })

  const formik = useFormik({
    initialValues: {
      formResponse: {
        formID: serviceApplicationForm,
      },
      context: 'addFormResponse',
      customerAccountNumber: '',
      firstName: '',
      surname: '',
      emailAddress: '',
      postalCode: '',
      state: '',
      address: '',
      dob: '',
      homeOrWorkNumber: '',
      mobileNumber: '',
      itemRequiringService: '',
      brand: '',
      modelNumber: '',
      serialNumber: '',
      repairType: '',
      lossDamageReason: '',
      insurance: '',
      insuranceCompany: '',
      addressOfInsuranceCompany: '',
      policyNumber: '',
      claimNumber: '',
      hasServicedBefore: '',
      serviceDescription: '',
      faultImage: '',
    },
    validationSchema: repairSchema,
    validateOnChange: submitted,
    validateOnBlur: submitted,
    onSubmit: async values => {
      setLoading(true)
      const payload = formatPayload(values)
      try {
        const response = await SlatwalApiService.content.addFormResponse(payload)

        if (response.isSuccess()) {
          const errors = response.success()?.errors
          if (errors && Object.keys(errors).length) {
            toast.error(getErrorMessage(errors))
          } else {
            const formSubmitResponse = response.success().data
            setValidateModal(false)
            setIsLoading(true)
            try {
              setRepairType(values?.repairType)
              setFormResponseID(formSubmitResponse?.formResponseID)
              // Wait for files to be uploaded to S3
              if (Object.keys(formSubmitResponse?.uploadDetails).length > 0) {
                postFilesToAWSS3(formSubmitResponse, values, setFileSubmittedlength, setIsLoading)
              } else {
                setEmailSend(true)
              }
            } catch (error) {
              toast.error(error.message)
            }
          }
        } else {
          console.error('Add Form Response Error:', response.success().errors) // Debugging log to check add form response error
          toast.error(getErrorMessage(response.success().errors))
        }
      } catch (error) {
        toast.error('An unexpected error occurred.')
        console.error('Unexpected Error:', error) // Debugging log to check unexpected error
      } finally {
        setLoading(false)
      }
    },
  })

  const getInputProps = useCallback(
    name => {
      return {
        value: formik.values[name],
        errors: formik.errors[name],
        name,
        onChange: formik.handleChange,
        setFieldValue: formik.setFieldValue,
        onBlur: formik.handleBlur,
      }
    },
    [formik]
  )
  const onChange = recaptchaToken => {
    SwAxios({
      method: 'POST',
      withCredentials: true,
      url: `${sdkURL}api/scope/verifyRecaptcha`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        recaptchaToken,
      },
    }).then(response => {
      if (response?.status === 200 && response?.data?.captchaResult) {
        setValidRecaptcha(true)
      }
    })
  }

  const onExpired = () => {
    setValidRecaptcha(false)
  }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  const handleClick = () => {
    setLoading(true); // Disable the button
    // Simulate an async operation (e.g., form submission)
    setTimeout(() => {
      // After async operation is complete, you can reset loading to false if needed
      setLoading(false);
    }, 3000); // Simulate a 3-second delay
  };

  const checkLocalStorageAndSetEmailSend = () => {
    let localStorageFilesUploaded = JSON.parse(localStorage.getItem('filesUploadArray')) || []
    if (filesSubmittedLength === localStorageFilesUploaded.length) {
      setEmailSend(true)
    } else {
      setEmailSend(false)
    }
  }

  useEffect(() => {
    checkLocalStorageAndSetEmailSend()

    const handleStorageChange = event => {
      checkLocalStorageAndSetEmailSend()
    }

    window.addEventListener('storage', handleStorageChange)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
    // eslint-disable-next-line
  }, [filesSubmittedLength])

  useEffect(() => {
    if (emailSend && isLoading) {
      axios.post(
        `${sdkURL}api/scope/sendEmailResponse`,
        {
          formResponseID,
          repairType,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      )
      setEmailSend(false)
      localStorage.removeItem('filesUploadArray')
      setIsLoading(false)
      toast.success('Form Submitted Successfully')
      // Optionally reset the form and navigate after a delay
      setTimeout(() => {
        navigate('/')
      }, 2000)
      
    }
    // eslint-disable-next-line
  }, [emailSend])

  return (
    <Layout>
      {isLoading && <FullScreenLoader />}
      <>
        <div className="container-xl p-0 d-none d-lg-block">
          <div className="row">
            <div className="col-md-6 col-sm-12 py-3 mx-2">
              <div className="d-block mb-3 mb-lg-0 pt-lg-2">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item ">
                      <Link className="text-nowrap" to="/repair-replacement-application">
                        Service Application Form
                      </Link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="container-xl contact-info p-4 p-lg-5 mb-3">
          <div className="row px-2 px-lg-0">
            <h2 className="brand-blue">Service Application Form</h2>
            <div className="brand-navy m-0">
              This form should be completed if:
              <ul>
                <li>You are experiencing an issue with your product or</li>
                <li>If it has been lost, stolen or damaged</li>
              </ul>
            </div>

            <p className="brand-navy m-0">By completing the following information you can help us understand the circumstances and extent of the assistance required for your product.</p>
            <p className="brand-navy">In some instances we will require the goods for inspection so please hold onto your items.</p>
          </div>
          <form className="contact-form" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="row">
                <InputField {...getInputProps('customerAccountNumber')} className="mt-4 mt-lg-2" label={'Customer Account Number (Should be 8 digits)'} maxLength="8" />

                <InputField {...getInputProps('firstName')} label={'First Name'} required />
                <InputField {...getInputProps('surname')} label={'Surname'} required />

                <InputField {...getInputProps('dob')} label={'Date of birth'} type={'date'} required />
                <InputField {...getInputProps('address')} label={'Address'} required maxLength="255" />

                <RadioField {...getInputProps('state')} options={LOCATIONS} label={'State'} required />
                <InputField {...getInputProps('postalCode')} label={'Postcode'} required maxLength="4" />

                <InputField {...getInputProps('mobileNumber')} label={'Mobile Phone Number'} required />
                <InputField {...getInputProps('homeOrWorkNumber')} label={'Home or Work Phone Number'} />

                <InputField {...getInputProps('emailAddress')} className="mt-4 mt-lg-2" label={'Email'} type={'email'} required />
                <RadioField {...getInputProps('repairType')} className="mt-4 mt-lg-2" options={REPAIR_TYPES} optionsClassname="d-flex flex-column" required />

                <InputField {...getInputProps('itemRequiringService')} label={'Item requiring service'} className="col-12 col-md-3 mt-4 mt-lg-2" required />
                <InputField {...getInputProps('serialNumber')} label={'Serial Number'} className="col-12 col-md-3 mt-4 mt-lg-2" />
                <InputField {...getInputProps('brand')} label={'Brand'} className="col-12 col-md-3 mt-4 mt-lg-2" required />
                <InputField {...getInputProps('modelNumber')} label={'Model Number'} className="col-12 col-md-3 mt-4 mt-lg-2" />
              </div>
              {formik.values['repairType'] === REPAIR_TYPE_FAULTY && (
                <>
                  <div className="row">
                    <RadioField {...getInputProps('hasServicedBefore')} options={BOOLEAN_OPTIONS} label={'Has item been serviced before ?'} required />
                    <InputField type="textarea" {...getInputProps('serviceDescription')} label={'Description of issue with item requiring service'} required />
                    <InputField {...getInputProps('faultImage')} label={'Attach photos displaying the fault if visible'} type="file" />
                  </div>
                </>
              )}
              {formik.values['repairType'] === REPAIR_TYPE_LOSS && (
                <>
                  <div className="row">
                    <InputField {...getInputProps('dateOfIncident')} label={'Date of incident occurred from above address'} type={'date'} required />
                    <InputField type="textarea" {...getInputProps('addressOfIncident')} label={'Address the incident occurred (if different from the above)'} />
                  </div>
                  <div className="row">
                    <RadioField {...getInputProps('lossDamageReason')} options={LOSS_DAMAGE_REASONS} label={'Please select the event that led to the loss or damage'} optionsClassname="d-flex flex-column" />
                  </div>
                </>
              )}
              {!!formik.values['lossDamageReason'] && (
                <>
                  <div className="row">
                    <InputField {...getInputProps('describeWhatHappened')} label={'Please Describe What Happened?'} required />
                    {[LOSS_DAMAGE_FIRE, LOSS_DAMAGE_ROBBERY, LOSS_DAMAGE_THEFT].includes(formik.values['lossDamageReason']) && (
                      <>
                        <SelectField {...getInputProps('howOffenderEnteredHome')} options={HOW_OFFENDER_OPTIONS} label={'If burglary or house breaking, how did the offender enter your home ?'} />
                        <InputField {...getInputProps('robberyReason')} label={'If Robbery, how did this occur?'} />
                        <SelectField {...getInputProps('didPoliceAttend')} options={BOOLEAN_OPTIONS} label={'Did the police or fire brigade attend ?'} />
                        <InputField {...getInputProps('eventNumber')} label={'Enter the police or fire report/event number?'} />
                        <InputField {...getInputProps('station')} label={'Enter Police or Fire station reported to ?'} />
                        <InputField {...getInputProps('officerName')} label={'Enter the police or fire officer name?'} />
                        <InputField {...getInputProps('notifierName')} label={'Enter the name of the person who notified the police or fire'} />
                        <InputField {...getInputProps('reportedDate')} type="date" label={'Enter the date reported to the police or fire?'} />
                        <InputField {...getInputProps('otherPersonalGoods')} label={'What other personal goods were stolen ?'} />
                        <InputField {...getInputProps('goods')} label={`What Goods have been subject to Loss or Damage ? For example, LG55'' TV, Playstation4 console`} />
                        <InputField {...getInputProps('serialNumbers')} label={'What is the serial numbers ?'} />
                        <InputField {...getInputProps('damagedImage')} label={'If damaged by flood, fire, windstorm, lightning, accidental damage or other, attach photos of the damage'} type="file" />
                        <InputField {...getInputProps('incidentReport')} label={'If applicable, attach Police report or Fire Report, statutory declaration Report or Incident Report'} type="file" />
                        <InputField {...getInputProps('serialNumbersImage')} label={'Attach photos displaying the Goods serial numbers'} type="file" />
                      </>
                    )}
                  </div>
                  <div className="row">
                    {[LOSS_DAMAGE_FIRE, LOSS_DAMAGE_ROBBERY, LOSS_DAMAGE_FLOOD, LOSS_DAMAGE_LIGHTNING, LOSS_DAMAGE_WINDSTORM, LOSS_DAMAGE_BURSTING].includes(formik.values['lossDamageReason']) && (
                      <div className="brand-navy m-0 col-12 mt-4">
                        If Fire/Smoke, Robbery, Burglary or House Break-in, Flood, Lightning, Windstorm or Bursting of a fixed water installation, please advise us if you would like to:
                        <ul>
                          <li>Repair – If the Repair and Replacement program (R&R) is unsuccessful, you will be liable for the repairs of the Goods. Note any credits on your account will be used towards the repair costs, or if you are unable to pay for the repair costs in full, a billing plan will be set up.</li>
                          <li>End your lease agreement – If your R&R is unsuccessful, you will be liable for the repairs or the costs of the Goods and the early termination fee may by applicable, depending on the circumstances.</li>
                        </ul>
                      </div>
                    )}
                    {[LOSS_DAMAGE_DAMAGE, LOSS_DAMAGE_THEFT, LOSS_DAMAGE_LOST, LOSS_DAMAGE_OTHER].includes(formik.values['lossDamageReason']) && (
                      <div className="brand-navy m-0 col-12 mt-4">
                        If damaged, lost, theft or other, please advise us if you would like to:
                        <ul>
                          <li>Repairs – If repairable, you will be liable for the repairs of the Goods. Note any credits on your account will be used towards the repair costs, or if you are unable to pay for the repair costs in full, a billing plan will be set up.</li>
                          <li>End your lease agreement – you will be liable for the repairs or the costs of the Goods and the early termination fee may by applicable, depending on the circumstances.</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </>
              )}
              <div className="row">
                <RadioField {...getInputProps('insurance')} className="col-12 mt-4 mt-lg-2" label="Do you have your own insurance ?" options={BOOLEAN_OPTIONS} optionsClassname="d-flex flex-column" />
              </div>
              {formik.values['insurance'] === 'Yes' && (
                <div className="row">
                  <InputField {...getInputProps('insuranceCompany')} label={'Name of Insurance Company'} required />
                  <InputField {...getInputProps('addressOfInsuranceCompany')} label={'Address of Insurance Company'} />
                  <InputField {...getInputProps('policyNumber')} label={'Policy Number'} required />
                  <InputField {...getInputProps('claimNumber')} label={'Claim Number'} required />
                </div>
              )}
              <div className="brand-navy m-0 mt-2">
                By completing this declaration you:
                <ol>
                  <li>Are confirming that the information provided is true and correct;</li>
                  <li>Understand that your Repair and Replacement Application will be considered based on the information provided in line with the Terms and Conditions of your Rental Agreement; and</li>
                  <li>Acknowledge that following our determination, contribution from you may be required to complete repairs or for the Replacement costs of the Goods.</li>
                </ol>
              </div>
              <div style={{ flex: '1' }}>
                <div>
                  * <input type="checkbox" id="agree" checked={isChecked} onChange={handleCheckboxChange} />
                  <label className="ms-2" htmlFor="agree">
                    I agree to the above.
                  </label>
                </div>
                <button
                  style={{ float: 'right' }}
                  className="btn btn-primary custom-btn disabled-spinner text-right"
                  name="submit"
                  type="button"
                  disabled={loading}
                  onClick={async () => {
                    const errors = await formik.validateForm()
                    setSubmitted(true)
                    if (!isChecked) {
                      toast.error('Please tick the declaration checkbox')
                      return
                    }
                    if (Object.keys(errors).length === 0) {
                      setValidateModal(true)
                    }
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
            <Modal
              show={validateModal}
              setShow={() => {
                setValidateModal(false)
              }}
              title={''}
              backgroundstatic={true}
            >
              <div className="col-12 col-md-12 mb-3 recaptcha">
                <p>We may not be able to determine the circumstances surrounding the loss of damage of the Goods. In accordance with the Terms and Conditions of your Rental Agreement, you may be liable for full costs of the Goods. Early Termination Fees may be applicable and any other fees.</p>
                <div className="">{!!recaptchaSitekey && <ReCAPTCHA sitekey={recaptchaSitekey} onChange={onChange} onExpired={onExpired} />}</div>
                <button style={{ float: 'right' }} className="btn btn-primary custom-btn disabled-spinner text-right" disabled={loading || !validRecaptcha} type="submit" name="submit" onClick={()=>handleClick}>
                  Submit
                </button>
                <button
                  style={{ float: 'right' }}
                  className="btn btn-default custom-btn disabled-spinner text-right"
                  disabled={loading}
                  onClick={e => {
                    e.preventDefault()
                    setValidateModal(false)
                  }}
                >
                  Close
                </button>
              </div>
            </Modal>
          </form>
          <p className="text-center pt-2">Credit Corp Financial Services Pty Limited | ABN 39 146 525 706 | Australian Credit Licence 400871</p>
        </div>
      </>
    </Layout>
  )
}

export { RepairReplacementForm }
