import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const ContactCard = ({background = 'bg-lightgray'}) => {
  const { t } = useTranslation()
  return (
    <div className={background+` p-3 text-center container`}>
      <h5 className="fs-16">
        <strong>{t('frontend.order.helpText')}</strong>
      </h5>
      <div className="d-flex justify-content-center">
        <p className="mb-0 lh-sm fw-normal fs-16">{t('frontend.order.helpSubText')}</p>
        <Link className="text-primary lh-sm px-1 fs-16" to="tel:131181">
          {' ' + t('frontend.social.phone')}
        </Link>
      </div>
    </div>
  )
}

export { ContactCard }
