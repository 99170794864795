import { useSelector } from 'react-redux'

const ShippingAddressDetails = () => {
  const user = useSelector(state => state.userReducer)
  const { streetAddress, city, stateCode, postalCode, emailAddress } = user?.primaryAddress?.address || {}
  
  return (
    <>
      <p>
        {streetAddress} <br />
        {`${city}, ${stateCode} ${postalCode}`} <br />
        <span className="text-truncate text-dark d-block">{emailAddress && emailAddress}</span>
      </p>
    </>
  )
}

export { ShippingAddressDetails }