import { useTranslation } from 'react-i18next'

const EndOfContractTerm = () => {
  const { t } = useTranslation()

  return (
    <div className=" p-3 rounded mb-4 main-content bg-light">
      <h5 className="brand-blue text-uppercase">{t('frontend.endofcontract.endofcontract')}</h5>
      <div class="col-sm-12 col-md-12 col-lg-8 order-sm-0">
        <h2 class="h3 brand-navy d-lg-block pt-1">{t('frontend.endofcontract.contractid')} : 1234</h2>
        <p class="mt-2 mt-sm-4 mb-3">{t('frontend.endofcontract.endofcontractprocess')}</p>
      </div>
      <div class="container p-3 bg-white rounded">
        <div class="row">
          <div className="col-6">
            <h5 class="brand-blue">{t('frontend.endofcontract.rentaldetails')}</h5>
          </div>
          <div className="col-6 text-right">
            <span className="badge alert-success">Delivered</span>
          </div>
        </div>
        <div class="row align-right-responsive">
          <div class="col-6 py-2">
            <h5 className="brand-blue text-uppercase">{t('frontend.endofcontract.seally')}</h5>
            <span>5086-000 Queen Bed</span>
          </div>
          <div class="col-6 py-2 d-table text-right text-muted">
            <span class="fs-14">{t('frontend.endofcontract.contracttermends')}</span>
            <br />
            <span>30th Nov 2022</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 my-2">
        <label class="form-label">{t('frontend.endofcontract.makeanoffer')}</label>
        <input class="form-control" id="offeronitem" maxlength="255" name="offeronitem" size="20" type="text" required="" value="" />
      </div>
      <div className="col-sm-12 font-weight-medium">
        <input className="form-check-input me-2" type="checkbox" value="" id="marketing-consent" />
        {t('frontend.endofcontract.returnthisitem')}
      </div>
      <div className="col-12">
        <button className="btn btn-bordered btn-primary w-100 mt-2">{t('frontend.endofcontract.submit')}</button>
        <button className="btn btn-bordered border-primary w-100 mt-2 text-primary">{t('frontend.endofcontract.cancel')}</button>
      </div>
      <div class="bg-white p-4 mt-2">
        <div class="col-md-12">{t('frontend.endofcontract.allowtwodays')}</div>
      </div>
    </div>
  )
}

export { EndOfContractTerm }
