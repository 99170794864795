import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { CartLineItem } from '../../Cart/CartLineItem'
import { ShippingAddressDetails } from './ShippingAddressDetails'
import { Navigate } from 'react-router-dom'

const ReviewSlide = () => {
  const cart = useSelector(state => state.cart)
  const { t } = useTranslation()
  const user = useSelector(state => state.userReducer)
  if (cart.hasErrors) {
    return <Navigate to={'/shopping-cart'} />
  }
  if (cart.isPlaced) {
    return <Navigate to={'/order-confirmation'} />
  }

  return (
    <>
      <h1 className="page-title mb-4">{t('frontend.account.quote.checkout')}</h1>
      <div className="border p-3 rounded mb-4">
        <span className="call-out bg-lightgray d-block p-2 mb-3 rounded text-center">
          {t('frontend.account.makeAPayment.pleaseContact')}{' '}
          <a href="tel:131181" className="brand-navy">
            {' '}
            {t('frontend.account.makeAPayment.phoneNumber')}
          </a>{' '}
          {''}
          {t('frontend.account.checkout.updateContact')}
        </span>
        <div>
          <h3 className="h5 text-primary">{t('frontend.checkout.my_information')}</h3>
          <p className="p-0 small">
            {user?.firstName + ' ' + user?.lastName}
            <br />
            {user?.primaryEmailAddress?.emailAddress}
          </p>
        </div>
        <hr />
        <div>
          <h3 className="h5 text-primary">{t('frontend.checkout.billing_information')}</h3>
          <p className="small">
            <ShippingAddressDetails />
          </p>
        </div>
        <hr />
        <div>
          <h3 className="h5 text-primary">{t('frontend.checkout.shipping')}</h3>
          <p className="small">
            <strong>{user?.firstName + ' ' + user?.lastName}</strong>
            <br />
            {user?.primaryPhoneNumber?.phoneNumber}
          </p>
          <p className="small m-0">
            <strong>Standard Delivery Included</strong>
            <ShippingAddressDetails />
          </p>
        </div>
      </div>

      <div className="bg-lightgray p-3 rounded mb-4">
        <div className="d-flex justify-content-between mt-2 mb-3">
          <h2 className="h5 text-primary">{t('frontend.checkout.review.order')}</h2>
          <span className="small">
            {cart.orderItems.length}
            {cart.orderItems.length !== 1 ? <span> items</span> : <span> item</span>}
          </span>
        </div>
        {cart.orderItems &&
          cart.orderItems
            .filter(item => item.parentOrderItemID === '')
            .map(orderItem => {
              return <CartLineItem key={orderItem.orderItemID} orderItem={orderItem} isDisabled={true} childBundleItems={cart.orderItems?.filter(item => item?.parentOrderItemID === orderItem.orderItemID)} /> // this cannot be index or it wont force a rerender
            })}
      </div>
    </>
  )
}

export { ReviewSlide }
