import React from 'react'
import { useUtilities } from '@ten24/slatwall-storefront-react-private/hooks'

const RowBlock = ({ contentBody = '', title = '', imagePath, linkLabel, linkUrl }) => {
  let { eventHandlerForWSIWYG } = useUtilities()
  if (!contentBody.length) {
    return null
  }
  return (

        <div className="row py-4">
            <div className="col-12 row-block text-center">
               {title && <h3 className="block-title mb-3 mt-1">{title}</h3>}
                <div onClick={eventHandlerForWSIWYG} dangerouslySetInnerHTML={{ __html: contentBody }} />
            </div>
        </div>

  )
}

export { RowBlock }