import React, { Suspense, useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ErrorBoundary } from 'react-error-boundary'
import { Loading } from '@ten24/slatwall-storefront-react-private/components'
import { Blog, NotFound, ThreeDSHandover, Category, GuestOrderConfirmation, BlogPost, Manufacturer, ErrorFallback } from '@ten24/slatwall-storefront-react-private/pages'
import { useCMSWrapper, useScrollToTop } from '@ten24/slatwall-storefront-react-private/hooks'
import { getBlogRoute } from '@ten24/slatwall-storefront-react-private/selectors'
import Home from './pages/Home/Home'
import MyAccount from './pages/MyAccount/MyAccount'
import Account from './pages/Account/Account'
import Brand from './pages/Brand/Brand'
import Product from './pages/Product/Product'
import ProductType from './pages/ProductType/ProductType'
import ProductDetail from './pages/ProductDetail/ProductDetail'
import Search from './pages/Search/Search'
import HowItWorks from './pages/HowItWorks/HowItWorks'
import SuccessfulApplication from './pages/SuccessfulApplication/SuccessfulApplication'
import { Checkout, Cart, ContentPage, OrderConfirmation } from './pages'
import Contact from './pages/Contact/Contact'
import { getConfiguration } from './actions/configActions'
import { RepairReplacementForm } from './components/RepairReplacementForm/RepairReplacementForm'
import { MakeAPayment } from './components/MakeAPayment/MakeAPayment'
import { ComplaintsForm } from './components/ComplaintsForm/ComplaintsForm'
import { GoGreenForm } from './components/GoGreenForm/GoGreenForm'
import { RefundForm } from './components/RefundForm/RefundForm'

const pageComponents = {
  SuccessfulApplication: <SuccessfulApplication />,
  Blog: <Blog />,
  Home: <Home />,
  Checkout: <Checkout />,
  Cart: <Cart />,
  Manufacturer: <Manufacturer />,
  MyAccount: <MyAccount />,
  Search: <Search />,
  ProductDetail: <ProductDetail />,
  NotFound: <NotFound />,
  ContentPage: <ContentPage />,
  Product: <Product />,
  ProductType: <ProductType />,
  Category: <Category />,
  Contact: <Contact />,
  Brand: <Brand />,
  Account: <Account />,
  OrderConfirmation: <OrderConfirmation />,
  GuestOrderConfirmation: <GuestOrderConfirmation />,
  BlogPost: <BlogPost />,
  ErrorFallback: <ErrorFallback />,
  HowItWorks: <HowItWorks />,
}

//https://itnext.io/react-router-transitions-with-lazy-loading-2faa7a1d24a
export default function App() {
  const routing = useSelector(state => state.configuration.router)
  const blogUrlTitle = useSelector(getBlogRoute)
  const loc = useLocation()
  const shopByManufacturer = useSelector(state => state.configuration.shopByManufacturer)
  // eslint-disable-next-line no-unused-vars
  const cms = useCMSWrapper()
  // eslint-disable-next-line no-unused-vars
  const scroll = useScrollToTop()

  const dispatch = useDispatch()

  localStorage.setItem('siteCode', window.location.hostname.includes('rr') ? 'rr' : 'creditcorpgroup')

  useEffect(() => {
    dispatch(getConfiguration())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Suspense fallback={<Loading />}>
      <ErrorBoundary
        key={loc.pathname}
        FallbackComponent={ErrorFallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
      >
        {/* <SEO /> */}
        <Routes>
          <Route path="/404" element={<NotFound />} />
          <Route path="/Error" element={<ErrorFallback />} />
          <Route path="/go-green" element={<GoGreenForm />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/complaints" element={<ComplaintsForm />} />
          <Route path="/make-a-payment" element={<MakeAPayment />} />
          <Route path="/repair-replacement-application" element={<RepairReplacementForm />} />
          <Route path="/refunds" element={<RefundForm />} />
          <Route path={`/${blogUrlTitle}`}>
            <Route index element={<Blog />} />
            <Route path={`*`} element={<BlogPost />} />
          </Route>
          {routing.length &&
            routing.map(({ URLKey, URLKeyType }, index) => {
              if (URLKey === 'my-account') return null
              return <Route key={index} path={`/${URLKey}/:id`} element={pageComponents[URLKeyType]} />
            })}
          <Route path={shopByManufacturer.slug} element={<Manufacturer />} />
          <Route path="/threeDSHandover" element={<ThreeDSHandover />} />
          <Route path={'*'} element={<ContentPage />} />
          <Route index element={<Home />} />
        </Routes>
      </ErrorBoundary>
    </Suspense>
  )
}
