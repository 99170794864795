import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { SWForm,SWInput } from '@ten24/slatwall-storefront-react-private/components'
import { useCreateAccount } from '../../../hooks'
import { PostCodeSelection } from '../../Header/PostCodeSelection'

const CreateAccount = ({successCallbackHandler}) => {
  const { formik } = useCreateAccount(successCallbackHandler)
  const { t } = useTranslation()
  
  return (
    <div className="container py-4 my-4">
      <div className="row d-flex justify-content-center">
        <div className="mb-5 bg-white col-md-7 apply-now">
          <h3 className="brand-navy text-center">{t('frontend.account.createAccount')}</h3>
          <p className=" text-center">
            {t('frontend.account.old_account')}
            <Link className="ms-1 link" to="/my-account">
              {t('frontend.account.here')}
            </Link>
            .
          </p>
          <SWForm formik={formik} primaryButtontext="Create Account & Continue" title="" >
            <div className="row">
              <div className="col-md-6">
                <SWInput formik={formik} token="firstName" label="First Name" wrapperClasses="" />
              </div>
              <div className="col-md-6">
                <SWInput formik={formik} token="lastName" label="Last Name" wrapperClasses="" />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <SWInput formik={formik} token="phoneNumber" label="Phone Number" type="phone" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <SWInput formik={formik} token="emailAddress" label="Email" type="email" />
              </div>
              <div className="col-md-6">
                <SWInput formik={formik} token="emailAddressConfirm" label="Confirm Email" type="email" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <SWInput formik={formik} token="password" label="Password" type="password" />
              </div>
              <div className="col-md-6">
                <SWInput formik={formik} token="passwordConfirm" label="Confirm Password" type="password" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 create-account pb-2">
                <PostCodeSelection />
              </div>
            </div>
          </SWForm>
        </div>
      </div>
    </div>
  )
}

export { CreateAccount }