import { Link } from 'react-router-dom'
import { Button, ListingPagination, Overlay } from '@ten24/slatwall-storefront-react-private/components'
import { useTranslation } from 'react-i18next'
import { useFormatCurrency, useOrderHistoryList } from '@ten24/slatwall-storefront-react-private/hooks/'
import { AccountLayout } from '../../../components/Account/AccountLayout/AccountLayout'
import { AccountContent } from '../../../components/Account/AccountContent/AccountContent'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useState } from 'react'
import { SimpleImage } from '@ten24/slatwall-storefront-react-private/components'
import { getProductRoute, isVatCountry } from '@ten24/slatwall-storefront-react-private/selectors'
import { useGetOrderDetails } from '@ten24/slatwall-storefront-react-private/hooks/'
// import { useState } from 'react'
import { ShippingAddressDetails } from '../..'
import { useEffect } from 'react'

// const OrderStatus = ({ type = 'light', text }) => {
//   return <div className={`badge bg-${type} text-success`}>{text}</div>
// }

const OrderCard = ({ orderItem, orderInfo }) => {
  const { sku_product_productName, images, sku_product_urlTitle, sku_skuID, price } = orderItem
  const Orderclass = orderInfo.orderStatusType_typeName === 'Processing' ? 'badge bg-light font-weight-semibold order-status' : 'badge bg-light text-success'
  const productRouting = useSelector(getProductRoute)
  const [formatCurrency] = useFormatCurrency({})
  return (
    <div className="row py-3">
      <div className="col-2">
        <Link to={`/${productRouting}/${sku_product_urlTitle}?skuid=${sku_skuID}`} className="mx-auto mr-sm-4">
          {images && images?.length > 0 && <SimpleImage className="img-fluid  m-auto image_container productImage" src={images?.at(0)} alt={sku_product_productName} type="product" />}
        </Link>
      </div>
      <div className="col-6 w-full">
        <Link className="link" to={`/${productRouting}/${sku_product_urlTitle}?skuid=${sku_skuID}`}>
          {sku_product_productName}
        </Link>
        <br />
        <br />
        <span className={Orderclass}>{orderInfo.orderStatusType_typeName.toUpperCase()}</span>
      </div>
      <div className="col-4 text-right">
        <span>
          <b className="brand-navy">{formatCurrency(price)}</b> per week
        </span>
      </div>
    </div>
  )
}

const OrderDetails = ({ orderID }) => {
  let [order, setRequest] = useGetOrderDetails()
  const [formatCurrency] = useFormatCurrency({})
  const user = useSelector(state => state.userReducer)
  const showVat = useSelector(isVatCountry)
  const { t } = useTranslation()

  useEffect(() => {
    let didCancel = false
    if (!order.isFetching && !order.isLoaded && !didCancel) {
      setRequest({ ...order, isFetching: true, isLoaded: false, params: { orderID }, makeRequest: true })
    }
    return () => {
      didCancel = true
    }
  }, [order, orderID, setRequest])

  // let orderFulfillmentGroups = formatOrderDetails(order.data)
  let orderInfo = order?.data.orderInfo?.at(0)
  let orderItems = order?.data.orderItems

  return (
    <Overlay active={!order.isLoaded} spinner>
      {orderItems &&
        orderItems.length > 0 &&
        orderItems.map(orderItem => {
          return <OrderCard orderItem={orderItem} orderInfo={orderInfo} key={orderItem.sku_skuID} />
        })}
      <div className="row">
        <div className="col-md-6">
          <div className="bg-white p-3 rounded mb-4">
            <h3 className="h5 text-primary">{t('frontend.checkout.shipping')}</h3>
            <p className="small">
              <strong>{user?.firstName + ' ' + user?.lastName}</strong>
              <br />
              {user?.primaryPhoneNumber?.phoneNumber}
            </p>
            <span className="small m-0">
              <strong>Standard Delivery Included</strong>
              <ShippingAddressDetails />
            </span>
          </div>
        </div>
        <div className="col-md-6">
          <ul className="list-group list-group-flush">
            <li className="d-flex justify-content-between align-items-center px-0 py-2">
              <h6 className="my-0 brand-navy subtotal fs-20"> {t('frontend.checkout.subTotal')}</h6>
              <span className="float-end subtotal brand-navy">
                {orderInfo?.calculatedSubTotal > 0 ? formatCurrency(orderInfo?.calculatedSubTotal) : '--'} <span className="cart-per-Week text-muted">{t('frontend.cart.per_week')}</span>
              </span>
            </li>
            <li className="d-flex justify-content-between align-items-center px-0 py-2">
              <h6 className="my-0 brand-navy small"> {t('frontend.cart.shippingDelivery')}</h6>
              <span className="float-end text-end small">
                <span className="postcode-included text-muted"> {t('frontend.cart.included')}</span>
              </span>
            </li>
            <li className="d-flex justify-content-between px-0 py-2 border-top">
              <h6 className="my-0 total brand-navy font-weight-bold fs-20">{t('frontend.cart.total')}</h6>
              <span className="subtotal brand-navy small">
                <span className="font-weight-bold fs-20">{orderInfo?.calculatedTotal > 0 ? formatCurrency(orderInfo?.calculatedTotal) : '--'}</span> <span className="cart-per-Week text-muted">{t('frontend.cart.per_week')}</span>
              </span>
            </li>
            {showVat && (
              <li className="d-flex justify-content-between px-0 py-2">
                <h6 className="my-0">{t('frontend.cart.vat')}</h6>
                <span className="float-end">
                  <strong>{orderInfo?.calculatedVATTotal > 0 ? formatCurrency(orderInfo?.calculatedVATTotal) : '--'}</strong>
                </span>
              </li>
            )}
          </ul>
          <Button classList="btn btn-secondary w-100">View Invoice</Button>
        </div>
      </div>
    </Overlay>
  )
}

const OrderListItem = props => {
  const [formatCurrency] = useFormatCurrency({})
  let isMobile = useMediaQuery({ query: '(max-width: 992px)' })
  // const [formateDate] = useFormatDateTime()
  const [isLoaded, setIsLoaded] = useState(false)
  const { t } = useTranslation()
  const { calculatedTotalItemQuantity, orderNumber, orderID, createdDateTime, orderStatusType_typeName, calculatedTotal } = props
  var Orderdate = new Date(createdDateTime)
  const Orderclass = orderStatusType_typeName === 'Processing' ? 'badge bg-light font-weight-semibold order-status' : 'badge bg-light text-success'
  const formattedDate = Orderdate.toLocaleDateString('en-GB', {
    day: 'numeric', month: 'short', year: 'numeric'
  }).replace(/ /g, ' ');
  return (
    <>
      <div className="accordion-item bg-white my-3 border-0">
        <div className="accordion-header">
          <div className="d-flex justify-content-between">
            <Link className="w-100 d-flex justify-content-between pt-3 px-3 pb-2 pb-lg-0 nav-link-style font-weight-medium font-size-sm" to={isMobile ? `/my-account/orders/${orderID}` : ''} state={{ pageTitleText: 'Order History' }}>
              <h5 className="mb-0 col brand-navy">
                {t('frontend.account.orderID')} : {orderNumber}
              </h5>
              {isMobile && <i className='bi bi-chevron-right fw-bold brand-navy d-flex align-items-center order-select-right-chevron'></i>}

              {!isMobile && <button className="accordion-button collapsed p-3 col pt-0 order-history-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#acc_${orderID}`} aria-expanded="true" aria-controls={`acc_${orderID}`} onClick={() => setIsLoaded(true)}></button>}
            </Link>
          </div>
          <div className='px-3'>
            <hr className='m-0 mb-1' style={{ height: "0.5px" }} />
          </div>
          <table className="table order-detail-table px-2 m-0">
            {isMobile &&
              <tbody>
                <tr>
                  <td className="border-0 py-2 px-3 w-25">
                    <span className={Orderclass}>{orderStatusType_typeName.toUpperCase()}</span>
                  </td>
                  <td className="border-0 py-2 w-25">{formattedDate}</td>
                </tr>
                <tr>
                  <td className="border-0 py-2 px-3 w-25">
                    {calculatedTotalItemQuantity} {calculatedTotalItemQuantity === 1 ? 'item' : 'items'}
                  </td>
                  <td className="border-0 py-2">
                    <span className='d-flex align-items-baseline'>
                      <h5 className='me-2'>{formatCurrency(calculatedTotal)} </h5>
                      <span className='text-muted fw-normal'>{t('frontend.cart.per_week')}</span>
                    </span>

                  </td>
                </tr>
              </tbody>
            }
            {!isMobile &&
              <tbody>

                <tr>
                  <td className="border-0 py-2 px-3 w-25">
                    <span className={Orderclass}>{orderStatusType_typeName.toUpperCase()}</span>
                  </td>
                  <td className="border-0 py-2 px-3 w-25">
                    {calculatedTotalItemQuantity} {calculatedTotalItemQuantity === 1 ? 'item' : 'items'}
                  </td>
                  <td className="border-0 py-2 w-25">{formattedDate}</td>

                  <td className="border-0 py-2">
                    <span className='d-flex align-items-baseline'>
                      <h5 className='me-2 brand-navy'>{formatCurrency(calculatedTotal)} </h5>
                      <span className='text-muted fw-normal'>{t('frontend.cart.per_week')}</span>
                    </span>

                  </td>
                </tr>
              </tbody>

            }
          </table>

        </div>
        {!isMobile &&
          <div id={`acc_${orderID}`} className="accordion-collapse collapse" aria-labelledby="">
            {!isMobile &&
              <div className='px-3'>
                <hr className='my-1' style={{ height: "0.5px" }} />
              </div>
            }
            <div className="accordion-body">{isLoaded && <OrderDetails orderID={orderID} />}</div>
          </div>
        }
      </div>
    </>
  )
}

const OrderHistoryList = () => {
  const { search, orders, totalPages } = useOrderHistoryList({})
  const user = useSelector(state => state.userReducer)
  return (
    <>
      {orders.isLoaded &&
        orders.data.ordersOnAccount.map((order, index) => {
          return <OrderListItem key={index} {...order} />
        })}
      {orders?.data && orders.data.ordersOnAccount && orders.data.ordersOnAccount.length < 1 && <p>{user.isLegacyCustomer ? 'No recent rentals' : 'No recent purchases'}</p>}
      <ListingPagination recordsCount={orders.data.records} currentPage={orders.data.currentPage} totalPages={totalPages} setPage={search} />
    </>
  )
}

const AccountOrderHistory = ({ crumbs, title, orders }) => {
  return (
    <div className="container mt-0 mt-sm-4">
      <div className="row account-wrapper flex-lg-row flex-column-reverse justify-content-start">
        <AccountLayout />
        <div className="col-sm-12 col-md-12 order-history-container col-lg-8 bg-light order-sm-0 main-content p-3 h-100">
          <AccountContent />
          <OrderHistoryList orders={orders} />
        </div>
      </div>
    </div>
  )
}

export { AccountOrderHistory }
