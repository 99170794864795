import { ProductAdditionalInformation, RedirectWithReplace, BreadCrumb, Spinner, ProductTypeRadioList, HeartButton } from '@ten24/slatwall-storefront-react-private/components'
import { useGetEntityByUrlTitleAdvanced, useProductDetail, useReview, useFormatCurrency } from '@ten24/slatwall-storefront-react-private/hooks'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { disableInteractionSelector, getProductTypeRoute } from '@ten24/slatwall-storefront-react-private/selectors'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { useState } from 'react'
import { usePostalCode } from '../../hooks'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { ProductDetails } from '../../components/ProductDetail/ProductDetails'
import ProductInfo from '../../components/ProductDetail/ProductInfo'
import { ProductDetailGallery } from '../../components/ProductDetail/ProductDetailGallery'
import { ProductForm } from '../../components/ProductDetail/ProductForm'
import { ProductPageHeader } from '../../components/ProductDetail/ProductPageHeader'
import { ShopRadioRentalsMobile, ShopRadioRentalsProductDetail } from '../../components/ShopRadioRentals/ShopRadioRentals'
import { useTranslation } from 'react-i18next'
import { RelatedProductsSlider } from '../../components/RelatedProductsSlider/RelatedProductsSlider'
import { isAuthenticated } from '@ten24/slatwall-storefront-react-private/utils'
import { SkuOptions } from '../../components/ProductDetail/SkuOptions'
import { SkuSelector } from '../../components/ProductDetail/SkuOptions'
import { Layout } from '../../components/Layout/Layout'
import { Modal } from '../../components'
import { InterestDisclaimer } from '../../components/InterestDisclaimer/InterestDisclaimer'

const BuyItemOutrightModal = ({ product, postalCode, setShowBuyNowModel }) => {
  const { t } = useTranslation()
  const [formatCurrency] = useFormatCurrency({})
  return (
    <div className="container overflow-auto mb-5 bg-light">
      <h4 className="text-primary text-center buyItemHeading">{t('frontend.product.detail.buyThisItem')}</h4>
      <p className="text-left buyItemcontent">{t('frontend.product.detail.npUpfrontPaymentFirstHalf')}{t('frontend.product.detail.debit')}
     &nbsp;{t('frontend.product.detail.npUpfrontPaymentSecondHalf')}
      </p>
      <div className="row border border-grey rounded p-4 bg-white align-items-center">
        <div className="col-4">
          <img src={product?.defaultSku_imageFile ? `${process.env.REACT_APP_HOST_URL}/custom/assets/images/product/default/${product?.defaultSku_imageFile}` : ''} alt="" height={100} />
        </div>
        <div className="col-8">
          <p className="text-primary">{product?.productName}</p>
        </div>
      </div>
      <div className="row justify-content-between d-flex mt-4">
        <h5 className="text-primary w-auto">{t('frontend.product.detail.shippingAndHandling')}</h5>
        <h5 className="text-primary w-auto">
          {postalCode?.city} {postalCode?.postalCode}
        </h5>
      </div>
      <div className="row">
        <h5 className="text-muted text-right">{t('frontend.product.detail.included')}</h5>
      </div>
      <div className="row justify-content-between border-top">
        <h4 className="text-primary w-auto mt-2">{t('frontend.product.detail.total')}</h4>
        <h4 className="text-primary w-auto mt-2">{formatCurrency(product?.listPrice)}</h4>
      </div>
      <div className="row justify-content-center">
        <a href="tel:131181" className="btn btn-primary w-100 m-2">
          <i className="bi bi-telephone-fill px-2 text-white"></i>
          {t('frontend.product.detail.callUsOn') + ' ' + t('frontend.social.phone')}
        </a>
        <button className="btn btn-outline-primary border-0 w-100 m-2 nohove" onClick={() => setShowBuyNowModel(false)}>
          {t('frontend.product.detail.cancel')}
        </button>
      </div>
    </div>
  )
}

const ProductDetail = sku => {
  let location = useLocation()
  const productTypeRoute = useSelector(getProductTypeRoute)
  const productTypeBase = useSelector(state => state.configuration.filtering.productTypeBase)
  const cart = useSelector(disableInteractionSelector)
  const [showBuyNowModel, setShowBuyNowModel] = useState(false)
  const [inStock, setInStock] = useState(true)
  const { filterSkusBySelectedOptions, calculateAvaliableOptions, calculateAdditionalParamters, selectionToSku } = useProductDetail()
  const [editDelivery, setEditDelivery] = useState(false)
  const { handleSearch, isLoading, options, postalCodeData, setPostalCode } = usePostalCode()
  const [formatCurrency] = useFormatCurrency({})
  const { t } = useTranslation()
  // selection is an object of current paramters
  // optionGroupPairs is an array of current paramters key=value
  let params = queryString.parse(location.search, { arrayFormat: 'separator', arrayFormatSeparator: ',' })
  let optionGroupPairs = location.search
    .replace('?', '')
    .split('&')
    .filter(param => param.length)
  const urlTitle = location.pathname.split('/').reverse()
  const ratingData = useReview(urlTitle[0])
  let { isFetching, product, productOptions, skus, error } = useGetEntityByUrlTitleAdvanced(urlTitle[0])
  if (error.isError) return null

  let selectedSKu = selectionToSku(product, skus, optionGroupPairs, productOptions)

  if (params?.skuid && productOptions?.length) {
    // If we have a skuID we need to redirect to codes
    console.log('skuID found, waiting for skus', !product)
    const found = skus?.filter(sku => sku.skuID === params.skuid)
    if (!found?.length) return <ProductDetailLoading />
    if (found.length) {
      console.log('Redirect based on found sku')
      return <RedirectWithReplace pathname={location.pathname} search={found[0].slug} />
    }
  }
  if (!product) return <RedirectWithReplace pathname="/404" />
  if (optionGroupPairs.length === 0 && product.defaultSku_slug) {
    // This check is for no optionGroupPairs passed
    console.log('<------- product.defaultSku_slug', optionGroupPairs, product)
    return <RedirectWithReplace pathname={location.pathname} search={product.defaultSku_slug} />
  }
  //http://localhost:3006/product/demo-product

  const crumbs = product?.breadcrumbs
    ?.map(crumb => {
      return { title: crumb.productTypeName, urlTitle: `/${productTypeRoute}/${crumb.urlTitle}` }
    })
    .filter(crumb => crumb.urlTitle !== `/${productTypeRoute}/${productTypeBase}`)
  const matchingSkus = filterSkusBySelectedOptions(skus, optionGroupPairs)
  const updatedProductOptions = calculateAvaliableOptions(productOptions, params, matchingSkus).filter(option => option.optionGroupCode !== 'PaymentTerm')
  let updateParams = calculateAdditionalParamters(optionGroupPairs, updatedProductOptions)

  if (updateParams.length) {
    // http://localhost:3006/product/test-product?soccerBallSize=5 ==>  soccerBallColor=green is added
    console.log('Add additional optionGroupPairs because of option matrix')
    return <RedirectWithReplace pathname={location.pathname} search={[...optionGroupPairs, updateParams].join('&')} />
  }
  let isDisabled = isFetching || cart.isFetching || !selectedSKu?.skuID
  if (productOptions?.length === 0 && product.skus.length > 0) {
    console.log('This is a product with skus without option groups')
    if (params?.skuid) {
      selectedSKu = skus?.filter(sku => sku.skuID === params.skuid)?.at(0)
    } else {
      selectedSKu = skus?.filter(sku => sku.skuID === product.defaultSku_skuID)?.at(0)
    }
    isDisabled = false
  }
  if (!product?.productID) return <ProductDetailLoading />
  return (
    <Layout>
      <Modal show={showBuyNowModel} setShow={setShowBuyNowModel} title="" size="medium" modalClassList="bg-light">
        <BuyItemOutrightModal product={product} postalCode={postalCodeData} setShowBuyNowModel={setShowBuyNowModel} />
      </Modal>
      {product?.productID && (
        <ProductPageHeader title={product.productName}>
          <BreadCrumb crumbs={crumbs} />
        </ProductPageHeader>
      )}
      {product?.productID && <Helmet title={product.settings.productHTMLTitleString} />}
      <div className="container m-auto mb-4">
        <div className="row gx-5">
          <div className="col-sm-12 col-md-6 mb-6 mb-md-0">
            {product?.essentialFlag && <span className="badge bg-danger position-absolute zindex-1">{t('frontend.product.detail.essentialtag')}</span>}
            <ProductDetailGallery productUrlTitle={urlTitle[0]} skuID={selectedSKu?.skuID} />
            {product?.productID && <ProductAdditionalInformation additionalInformation={product.additionalInformation} />}
          </div>
          <div className="col-sm-12 col-md-6 mb-6 mb-md-0">
            <div className="container p-4 no-padding">
              {selectedSKu && <HeartButton skuID={selectedSKu.skuID} className="btn-wishlist mr-0 flex-end" />}
              {product?.productID && <ProductDetails sku={selectedSKu} product={product} {...ratingData} />}
              {!isFetching && product.skus.length > 1 && <SkuOptions selectedSKu={selectedSKu} selection={params} productOptions={updatedProductOptions} skus={skus} />}
              {!isFetching && product.skus.length > 1 && <SkuSelector sku={selectedSKu} selection={params} productOptions={updatedProductOptions} skus={skus} />}
            </div>

            <div className="container px-4 no-padding">
              <p className="light-gray-text mb-0">{isAuthenticated() ? t('frontend.productCard.ownItNow') : t('frontend.product.detail.ownItFrom')}</p>
              <div className="row">
                <div className="d-flex align-items-baseline">
                  <h1 className="fs-36">
                    <strong>{formatCurrency(product?.salePrice)}</strong>
                  </h1>
                  <p className="light-gray-text px-1 mb-0"> {t('frontend.product.detail.perWeekText')} </p>
                </div>
              </div>
              <div className="position-relative row m-0 bg-light d-flex p-2 justify-content-center">
                <div className="position-absolute w-auto d-flex align-items-center start-0">
                  <i className="bi bi bi-geo-alt-fill m-1 text-primary"></i>
                </div>
                <div className="w-100 align-items-center justify-content-between d-flex">
                  {!editDelivery && (
                    <>
                      <div className="align-items-center">
                        <p className="m-0 px-3">{t('frontend.product.detail.deliverTo') + '  '}</p>
                      </div>
                      <div className="d-flex align-items-center">
                        <h5 className="text-primary m-0 px-2">
                          {postalCodeData.city} {postalCodeData.postalCode}
                        </h5>
                        <button className="postcode btn-outline-primary mx-2" onClick={() => setEditDelivery(true)}>
                          {t('frontend.product.detail.edit')}
                        </button>
                      </div>
                    </>
                  )}
                </div>
                {editDelivery && (
                  <>
                    <div className="w-100 px-3 d-flex justify-content-between align-content-center">
                      <AsyncTypeahead
                        className="h-3 w-100"
                        id="postalCode"
                        isLoading={isLoading}
                        labelKey={option => `${option.address_postalCode} ${option.address_city}`}
                        minLength={4}
                        onSearch={handleSearch}
                        options={options}
                        placeholder="Enter suburb or postcode"
                        onChange={selected => {
                          var address = {
                            city: selected[0]?.address_city,
                            stateCode: selected[0]?.address_stateCode,
                            postalCode: selected[0]?.address_postalCode,
                            countryCode: selected[0]?.address_countryCode,
                            isDefaultAddress: false,
                          }
                          setPostalCode({ ...postalCodeData, ...address })
                          setEditDelivery(false)
                        }}
                      />
                    </div>
                    <div className="position-absolute w-auto d-flex align-items-center end-0">
                      <i className="bi bi bi-x-lg m-1 text-primary" onClick={() => setEditDelivery(false)}></i>
                    </div>
                  </>
                )}
              </div>

              <ProductForm sku={selectedSKu} isDisabled={isDisabled} isLoading={isFetching || cart.isFetching} productName={product.productName} setInStock={()=>setInStock} inStock={inStock}/>
              <ProductTypeRadioList selectedSKu={selectedSKu} product={product} isDisabled={isDisabled} isLoading={isFetching || cart.isFetching} />
              <InterestDisclaimer />
              <div className="row m-0 justify-content-center mb-4">
                <button className="btn text-primary" onClick={() => setShowBuyNowModel(true)}>
                  {t('frontend.product.detail.wantToBuyThis')}
                </button>
              </div>
            </div>
          </div>
          <ProductInfo {...selectedSKu} {...product} {...urlTitle} {...ratingData} />
        </div>
      </div>
      {isAuthenticated() && (
        <div className="container">
          <div className="accordion-item bg-lightgray my-3 mb-5 border-0 radio-rentals-accordion">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed bg-lightgray" type="button" data-bs-toggle="collapse" data-bs-target="#accordion_shopping_rentals" aria-expanded="true" aria-controls="accordion_shopping_rentals">
                How it Works
              </button>
            </h2>
            <div id="accordion_shopping_rentals" className="accordion-collapse collapse" aria-labelledby="">
              <div className="accordion-body shopping-rental-product-page p-0">{<ShopRadioRentalsMobile />}</div>
            </div>
          </div>
        </div>  
      )}
      {!isAuthenticated() && (
        <section className="d-block d-sm-none mb-4">
          <ShopRadioRentalsProductDetail />
        </section>
      )}
      {<RelatedProductsSlider productUrlTitle={urlTitle[0]} />}
    </Layout>
  )
}

const ProductDetailLoading = () => {
  return (
    <Layout>
      <div className="container mt-5">
        <Spinner />
        <div className="row">
          <div className="col-sm-6 col-md-6 "></div>
          <div className="col-sm-6 col-md-6 offset-md-1">
            <div className="row mb-4">
              <div className="col-md-12"></div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ProductDetail
