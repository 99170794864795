import React, { useEffect } from 'react'
import { SimpleImage } from '@ten24/slatwall-storefront-react-private/components'
import { useUtilities } from '@ten24/slatwall-storefront-react-private/hooks'
import * as bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min'

const ContentSlider = ({ slider }) => {
  let { eventHandlerForWSIWYG } = useUtilities()

  const generateUrlString = (urlString) => {
    if (urlString !== undefined && urlString.length > 0) {
      if (urlString.indexOf('http') === -1) {
        return '/' + urlString
      } else {
        return urlString;
      }
    } else {
      return "/#"
    }
  }

  useEffect(() => {
    if (slider && Object.keys(slider).length !== 0) {
      var carousel = new bootstrap.Carousel(document.getElementById('carousel'), {
        interval: 5000,
        ride: true,
        pause: false,
      })
      carousel.cycle()
    }
  }, [slider])
  if (!slider) return null
  return (
    <div className="hero content-slider">
      {slider?.slides && slider.slides.length > 0 && (
        <div id="carousel" className="carousel slide" data-bs-ride="carousel">
          {slider?.slides?.length > 1 && (
            <div className="carousel-indicators d-md-none d-block-sm">
              {slider.slides.map(({ title }, idx) => {
                return <button key={title} type="button" data-bs-target="#carousel" data-bs-slide-to={idx} className={idx === 0 ? 'active' : ''} aria-current="true" aria-label={`Slide ${idx}`}></button>
              })}

            </div>
          )}
          <div className="carousel-inner">
            {slider.slides.map(({ contentBody, title, imagePath, linkUrl, linkLabel }, key) => {
              return (
                <div key={title} className={key === 0 ? 'carousel-item active' : 'carousel-item'}>
                  <div className="carousel-image">
                    <a onClick={eventHandlerForWSIWYG} href={generateUrlString(linkUrl)}>
                      <SimpleImage src={imagePath} alt="carouselImage" />
                    </a>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}
export { ContentSlider }
