import { useDispatch, useSelector } from 'react-redux'
import { SimpleImage, ProductImage, HeartButton } from '@ten24/slatwall-storefront-react-private/components'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getProductRoute } from '@ten24/slatwall-storefront-react-private/selectors'
import { addToCart } from '../../actions/cartActions'
import { useState } from 'react'
import { ProductPrice } from '../../components/ProductPrice/ProductPrice'
import { ButtonWithIcon } from '../ButtonWithIcon/ButtonWithIcon'

const ProductCard = props => {

  const { productName, salePrice, urlTitle, imagePath, imageFile, listPrice, images, productClearance, qualifiedQATS, sku_qualifiedQATS, skuID = '', essentialFlag, product_essentialFlag = false, skus } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isFetching, setFetching] = useState(false)
  const isEssential = (typeof essentialFlag === 'undefined') ? product_essentialFlag : essentialFlag
  const inStock = (typeof qualifiedQATS === 'undefined') ? sku_qualifiedQATS : qualifiedQATS

  const product = useSelector(getProductRoute)
  const productLink = `/${product}/${urlTitle}` + (skuID.length ? `?skuid=${skuID}` : '')
  const useResizedImage = images && images?.length > 0
  const [addToCartTitle, setAddToCartTitle] = useState(t('frontend.product.add_to_cart'))
  const [showCheckIcon, setCheckIcon] = useState(false)
  return (
    <div className="card p-2 pt-2 h-100">
      {productClearance === true && <span className="badge">{t('frontend.core.special')}</span>}
      {isEssential && <span className='badge bg-danger position-absolute zindex-1'>{t('frontend.product.detail.essentialtag')}</span>}
      <HeartButton skuID={skuID} />
      <Link to={`/${product}/${urlTitle}?skuid=${skuID}`}>
        {useResizedImage && <SimpleImage className="img-fluid card-image-height productImage" src={images[0]} alt={productName} type="product" />}
        {!useResizedImage && imagePath && <ProductImage customClass="img-fluid card-image-height" imageFile={imagePath} skuID={skuID} customPath="/" />}
        {!useResizedImage && imageFile && <ProductImage customClass="img-fluid card-image-height" imageFile={imageFile} skuID={skuID} />}
      </Link>
      <div className="card-body d-flex flex-column justify-content-end px-1">
        <h2>
          <Link to={productLink} className="product-name d-inline-block w-100 mt-2 brand-navy">
            {productName}
          </Link>
        </h2>
        <span className="light-gray-text mb-0">{t('frontend.productCard.ownItNow')}</span>
        <ProductPrice salePrice={salePrice} listPrice={listPrice} />
      </div>
      <div className="text-center card-footer border-0 bg-transparent p-1">
        {(skus && skus.length === 0) || (inStock < 1) ? (
          <div className='mt-2'>
            <span className="text-accent btn border border-secondary"> {t('frontend.checkout.stock')}</span>
          </div>
        ) : (
          <ButtonWithIcon
            disabled={isFetching}
            isLoading={isFetching}
            showIconOnSuccess={showCheckIcon}
            iconClass="bi bi bi-check2 m-1 text-white font-weight-bold"
            className="btn btn-primary btn-block my-3 w-100"
            label={addToCartTitle}
            onClick={e => {
              e.preventDefault()
              setFetching(true)
              dispatch(addToCart(skuID, productName)).then(response => {
                if (response.isSuccess && response.success()) {
                  setCheckIcon(true)
                  setAddToCartTitle(t('frontend.product.addToCartAdded'))
                  setTimeout(() => {
                    setCheckIcon(false)
                    setAddToCartTitle(t('frontend.product.add_to_cart'))
                  }, 5000)
                }
                setFetching(false)
              })
            }}
          />

        )}

      </div>
    </div>
  )
}

export { ProductCard }