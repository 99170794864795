import { useTranslation } from 'react-i18next'
import { useLocation, Link } from 'react-router-dom'
import { SimpleImage } from '@ten24/slatwall-storefront-react-private/components'
import { useSelector } from 'react-redux'

const ShopRadioRentals = ({ bg = '' }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const ishowItWorksPage = location.pathname.includes('/how-it-works')
  const { globalconfig = {} } = useSelector(state => state.content)
  const { contentColumns } = globalconfig

  return (
    <section className={`section-shopping-radio-rentals p-4 ${bg ? `bg-white` : ''}`}>
      <div className="container p-4">
        <div className="row pb-4">
          <div className="col-12 text-center pb-2">
            <h2 className="d-block">{`${t('frontend.home.shopRadioRentals.titleMobile')}`}</h2>
          </div>
        </div>

        <div className="row justify-content-sm-start pt-2">
          {contentColumns &&
            contentColumns?.columns?.map(item => {
              return (
                <div key={item.contentID} className="col-lg-2 col-md-3 col-sm-4 col-md-offset-1 col-12 d-flex">
                  <div className="col-md-3 col-sm-3">
                    <i className="bi-check-circle-fill d-block d-sm-none"></i>
                    <SimpleImage className="img-fluid card-image-height productImage d-none d-md-block" src={item.imagePath} alt={item.title} />
                  </div>
                  <div className="col-lg-10 col-md-9 col-sm-8">
                    <h6 className="category-text-black">{item.title}</h6>
                    <div
                      className="content-body"
                      dangerouslySetInnerHTML={{
                        __html: item.contentBody || '',
                      }}
                    />
                  </div>
                </div>
              )
            })}
        </div>
        {!ishowItWorksPage && (
          <div className="row justify-content-center py-4">
            <div className="col-md-12 col-sm-12 text-center shop-button d-lg-flex justify-content-evenly">{!ishowItWorksPage && <Link to={`/how-it-works`} className="btn btn-primary col-12 col-lg-4 mt-2">{`${t('frontend.home.shopCategory.howItWorks.button')}`}</Link>}</div>
          </div>
        )}
      </div>
    </section>
  )
}

const ShopRadioRentalsMobile = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const ishowItWorksPage = location.pathname.includes('/how-it-works')
  const isProductDetailPage = location.pathname.includes('/product/')
  const isProductDetailPageVersion2 = location.pathname.includes('/sp/')
  const { globalconfig = {} } = useSelector(state => state.content)
  const { contentColumns } = globalconfig

  return (
    <section className="section-shopping-radio-rentals section-bg-color-grey p-2">
      <div className="container-fluid">
        {contentColumns && (
          <div className="row p-4 shopping-rental-product-page-title">
            <div className="col-12 text-center">
              <h2 className="mobile-title">{contentColumns?.title}</h2>
            </div>
          </div>
        )}
        <div className="row justify-content-sm-start justify-content-md-center gy-4">
          <span className="text-center shopping-rental-product-page-description">{`${t('frontend.home.shopRadioRentals.description')}`}</span>
          {contentColumns &&
            contentColumns?.columns?.map(item => {
              return (
                <div key={item.contentID} className="col-lg-2 col-md-3 col-sm-4 col-md-offset-1 col-12 w-50 text-center d-md-flex">
                  <div className="col-md-2 col-sm-3">
                    <i className="bi-check-circle-fill d-none d-lg-none"></i>
                    <SimpleImage className="img-fluid card-image-height productImage d-lg-block" src={item.imagePath} alt={item.title} />
                  </div>
                  <div className="col-lg-10 col-md-10 col-sm-8 d-md-flex flex-md-column justify-content-start align-items-start">
                    <h6 className="category-text d-none d-lg-block">{item.title}</h6>
                    <div
                      className="content-body"
                      dangerouslySetInnerHTML={{
                        __html: item.contentSummary || '',
                      }}
                    />
                  </div>
                </div>
              )
            })}
        </div>
        <div className="row justify-content-center py-4 shopping-rental-product-page-button">
          <div className="col-md-12 col-sm-12 text-center shop-button">
            <Link to={`/product-type`} className="btn btn-primary w-100 mb-2">{`${t('frontend.shopRadioRentals.shopNow')}`}</Link>
            {!ishowItWorksPage && !isProductDetailPage && !isProductDetailPageVersion2 && <Link to={`/how-it-works`} className="btn bg-transparent text-primary border-primary w-100 mb-2">{`${t('frontend.home.shopCategory.howItWorks.button')}`}</Link>}
          </div>
        </div>
      </div>
    </section>
  )
}

const ShopRadioRentalsProductDetail = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const ishowItWorksPage = location.pathname.includes('/how-it-works')
  const isProductDetailPage = location.pathname.includes('/product/')
  const isProductDetailPageVersion2 = location.pathname.includes('/sp/')
  const { globalconfig = {} } = useSelector(state => state.content)
  const { contentColumns } = globalconfig

  return (
    <section className="section-shopping-radio-rentals section-bg-color-grey p-2 mx-2">
      <div className="container-fluid">
        <div className="row p-4">
          <div className="col-12 text-center">
            <h2 className="mobile-title">{t('frontend.howitworks.title')}</h2>
          </div>
        </div>
        <div className="row justify-content-sm-start justify-content-md-center gy-4">
          <span className="text-center">{`${t('frontend.home.shopRadioRentals.description')}`}</span>
          {contentColumns &&
            contentColumns?.columns?.map((item,index) => {
              return (
                <div key={`howitworks_${index}`} className="col-lg-2 col-md-3 col-sm-4 col-md-offset-1 col-12 text-left d-flex">
                  <div className="col-md-3 col-sm-1 col-1">
                    <i className="bi-check-circle-fill d-none d-lg-block"></i>
                    <SimpleImage className="img-fluid card-image-height productImage d-lg-block" src={item.imagePath} alt={item.title} />
                  </div>
                  <div className="col-lg-10 col-md-9 col-sm-8">
                    <h6 className="category-text d-none d-lg-block">{item.title}</h6>
                    <div
                      className="content-body ps-2"
                      dangerouslySetInnerHTML={{
                        __html: item.contentBody || '',
                      }}
                    />
                  </div>
                </div>
              )
            })}
        </div>
        <div className="row justify-content-center py-4">
          <div className="col-md-12 col-sm-12 text-center shop-button">
            <Link to={`/product-type`} className="btn btn-primary w-100 mb-2">{`${t('frontend.shopRadioRentals.shopNow')}`}</Link>
            {!ishowItWorksPage && !isProductDetailPage && !isProductDetailPageVersion2 && <Link to={`/how-it-works`} className="btn bg-transparent text-primary border-primary w-100 mb-2">{`${t('frontend.home.shopCategory.howItWorks.button')}`}</Link>}
          </div>
        </div>
      </div>
    </section>
  )
}

export { ShopRadioRentals, ShopRadioRentalsMobile, ShopRadioRentalsProductDetail }
