import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import './checkout.css'
import { getErrorMessage, isAuthenticated } from '@ten24/slatwall-storefront-react-private/utils'
import { useEffect, useState } from 'react'
import { PageHeader, ThreeDSRedirect } from '@ten24/slatwall-storefront-react-private/components'
import { clearUser, receiveCart, receiveUser, requestCart, requestLogOut } from '@ten24/slatwall-storefront-react-private/actions'
import { SlatwalApiService } from '@ten24/slatwall-storefront-react-private/services'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { validateCart } from '../../actions'
import { ReviewSlide, CheckoutSideBar, ContractSlide } from '../../components'
import { AccountLogin } from '../../components/Account/AccountLogin/AccountLogin'
import { Layout } from '../../components/Layout/Layout'

const Checkout = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  // const currentStep = getCurrentStep(path)
  const { verifiedAccountFlag, isFetching, accountID, calculatedGuestAccountFlag = false } = useSelector(state => state.userReducer)
  const enforceVerifiedAccountFlag = useSelector(state => state.configuration.enforceVerifiedAccountFlag)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const cartState = useSelector(state => state.cart) // check if there is some change in state , just to run use effect
  const [threeDSRedirect, setThreeDSRedirect] = useState()
  const placeOrder = event => {
    event.preventDefault()
    dispatch(requestCart())
    // Store the hash for guest Checkout
    const payload = !!calculatedGuestAccountFlag ? 'cart,account' : 'cart'
    SlatwalApiService.sdkScope.httpService.slatwallResponse('post',
    `api/scope/postPlaceOrder`,
    {
      context: "validateCart,prePlaceOrder,addShippingAddressUsingAccountAddress,addOrderPayment,placeOrder",
      returnJSONObjects: payload
      , transactionInitiator: 'ACCOUNT'
    },
    {}, null).then(response => {
      if (response.isSuccess()) {
        const placeOrderResp = response.success()
        const orderHasError = Object.keys(placeOrderResp?.errors || {})?.length > 0
        if (placeOrderResp?.redirectUrl) {
          const { redirectUrl, redirectPayload, redirectMethod } = placeOrderResp
          setThreeDSRedirect({ redirectUrl, redirectPayload, redirectMethod })
        } else {
          if (orderHasError) {
            toast.error(getErrorMessage(response.success().errors))
          } else {
            dispatch(receiveCart(placeOrderResp.cart))
            dispatch(receiveUser(placeOrderResp.account))
            // TODO: verify isGuest

            if (!!calculatedGuestAccountFlag) {
              navigate(`/guest-order-confirmation?token=${cartState.orderID}:${accountID}`)
            } else {
              navigate('/order-confirmation')
            }
          }
        }
      } else {
        toast.error(t('frontend.core.error.network'))
        dispatch(receiveCart())
      }
    })
  }

  useEffect(() => {
    if (!isAuthenticated()) {
      dispatch(clearUser())
      dispatch(requestLogOut())
    }else{
      dispatch(validateCart())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (enforceVerifiedAccountFlag && !verifiedAccountFlag && isAuthenticated() && !isFetching && accountID.length > 0) return <Navigate to="/account-verification" />

  return (
    <Layout>
      <PageHeader />
      <div className="container pb-5 mb-2 mb-md-4">
        {!isAuthenticated() && (
          <div className="row">
            <section className="col">
              {/* <!-- Steps--> */}
              <Routes>
                {/* <Route path={`cart`} element={<Navigate to={`${pathname}/shopping-cart`} />} /> */}
                <Route path={`*`} element={<AccountLogin isCheckout={true} />} />
              </Routes>
            </section>
            {/* <!-- Sidebar--> */}
          </div>
        )}
        {isAuthenticated() && (
          <div className="row">
            <section className={pathname !== '/checkout/contract' ? 'col-lg-8' : 'col-lg-12'}>
              {/* <!-- Steps--> */}
              <Routes>
                <Route path={`contract`} element={<ContractSlide placeOrder={placeOrder} />} />
                <Route path={`review`} element={<ReviewSlide />} />
                <Route path={`*`} element={<Navigate to={`review`} />} />
              </Routes>
            </section>
            {/* <!-- Sidebar--> */}
            {pathname !== '/checkout/contract' && <CheckoutSideBar />}
            {threeDSRedirect && <ThreeDSRedirect url={threeDSRedirect.redirectUrl} payload={threeDSRedirect.redirectPayload} method={threeDSRedirect.redirectMethod} />}
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Checkout