import React from 'react'
import { useLocation } from 'react-router-dom'
import { useUtilities } from '@ten24/slatwall-storefront-react-private/hooks'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ShopRadioRentals, ShopRadioRentalsMobile } from '../../components/ShopRadioRentals/ShopRadioRentals'
import { Layout } from '../../components/Layout/Layout'

const HowItWorks = () => {
  const { t } = useTranslation()
  let { eventHandlerForWSIWYG } = useUtilities()
  let { pathname } = useLocation()
  const pageData = useSelector(state => state.content[pathname.substring(1)] || {})
 
  return (
    <>
      <Layout>
        <div className="container mb-4 mt-0 mt-sm-4 p-4 p-sm-2">
          <div className="row">
            <div className="col-12">
              <h3 className="brand-navy mb-4">{t('frontend.howitworks.title')}</h3>
              <div
                onClick={eventHandlerForWSIWYG}
                dangerouslySetInnerHTML={{
                  __html: pageData.contentBody || '',
                }}
              />
            </div>
          </div>
        </div>
        <section className="d-block d-md-none mb-4">
          <ShopRadioRentalsMobile title={t('frontend.home.shopRadioRentals.title')} />
        </section>
        <ShopRadioRentals bg="white" />
        <div className="container mb-4 mt-0 mt-sm-4 p-4 p-sm-2">
          <div className="row">
            <div className="col-12">
              <div
                onClick={eventHandlerForWSIWYG}
                dangerouslySetInnerHTML={{
                  __html: pageData.contentSummary || '',
                }}
              />
            </div>
          </div>
        </div>
      </Layout>
      
    </>
  )
}

export default HowItWorks
