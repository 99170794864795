import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom'
import { isAuthenticated } from '@ten24/slatwall-storefront-react-private/utils'
import queryString from 'query-string'
import { AccountOverview } from '../../components/Account/AccountOverview/AccountOverview'
import { AccountLegacyOrder, AccountLegacyOrderDetail } from '../../components'
import { AccountCarts, AccountAddresses, CreateOrEditAccountAddress, AccountPaymentMethods, CreateOrEditAccountPaymentMethod, AccountSubscriptionOrders, AccountSubscriptionOrderDetail, AccountImpersonation, AccountQuotes, AccountQuoteDetail, GiftCardList, GiftCardView } from '@ten24/slatwall-storefront-react-private/components'
import { AccountProfile } from '../../components/Account/AccountProfile/AccountProfile'
import { AccountFavorites } from '../../components/Account/AccountFavorites/AccountFavorites'
import { AccountOrderHistory } from '../../components/Account/AccountOrderHistory/AccountOrderHistory'
import { AccountTermsConditions } from '../../components/Account/AccountTermsConditions/AccountTermsConditions'
import { EndOfContractTerm } from '../../components/Account/EndOfContractTerm/EndOfContractTerm'
import { AccountFaqs } from '../../components/Account/AccountFaqs/AccountFaqs'
import { AccountProfileCenter } from '../../components/Account/AccountProfileCenter/AccountProfileCenter'
import { UpdatePassword } from '../../components/Account/UpdatePassword/UpdatePassword'
import { Layout } from '../../components/Layout/Layout'
import { AccountOrderDetail } from '../../components/Account/AccountOrderDetail/AccountOrderDetail'
import { AccountSummary } from '../../components/Account/AccountSummary/AccountSummary'
import { AccountMakePayment } from '../../components/Account/AccountMakePayment/AccountMakePayment'

const MyAccount = () => {
  let loc = useLocation()
  const user = useSelector(state => state.userReducer)
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated() && loc.search.includes('redirect=')) {
      const params = queryString.parse(loc.search)
      navigate(params.redirect)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.accountID])

  const path = loc.pathname.split('/').reverse()

  return (
    <Layout>
      {isAuthenticated() && (
        <Routes>
          <Route path={`addresses/:id`} element={<CreateOrEditAccountAddress path={path?.at(0)} />} />
          <Route path={`addresses`} element={<AccountAddresses />} />
          <Route path={`cards/:id`} element={<CreateOrEditAccountPaymentMethod path={path?.at(0)} />} />
          <Route path={`cards`} element={<AccountPaymentMethods />} />
          <Route path={`orders`} element={<AccountOrderHistory />} />
          <Route path={`orders/:id`} element={<AccountOrderDetail path={path?.at(0)} />} />
          <Route path={`legacy-orders`} element={<AccountLegacyOrder />} />
          <Route path={`legacy-orders/:id`} element={<AccountLegacyOrderDetail path={path?.at(0)} />} />
          <Route path={`subscription-orders/:id`} element={<AccountSubscriptionOrderDetail path={path?.at(0)} forwardState={loc.state} />} />
          <Route path={`subscription-orders`} element={<AccountSubscriptionOrders />} />
          <Route path={`quotes:id`} element={<AccountQuoteDetail path={path?.at(0)} forwardState={loc.state} />} />
          <Route path={`quotes`} element={<AccountQuotes />} />
          <Route path={`gift-cards/:id`} element={<GiftCardView id={path?.at(0)} />} />
          <Route path={`gift-cards`} element={<GiftCardList />} />
          <Route path={`profile`} element={<AccountProfile />} />
          <Route path={`carts`} element={<AccountCarts />} />
          <Route path={`favorites`} element={<AccountFavorites />} />
          <Route path={`updatePassword`} element={<UpdatePassword />} />
          <Route path={`impersonation`} element={<AccountImpersonation />} />
          <Route path={`overview`} element={<AccountOverview />} />
          <Route path={`terms-and-conditions`} element={<AccountTermsConditions />} />
          <Route path={`end-of-contract-term`} element={<EndOfContractTerm />} />
          <Route path={`faqs`} element={<AccountFaqs />} />
          <Route path={`profile-center`} element={<AccountProfileCenter />} />
          <Route path={`account`} element={<AccountSummary />} />
          <Route path={`make-a-payment`} element={<AccountMakePayment />} />
          <Route path={`login`} element={<Navigate to={`/`} />} />
          <Route path={`*`} element={<Navigate to={`/my-account/overview`} />} />
        </Routes>
      )}
      {!isAuthenticated() && (
        <Routes>
          <Route path={`*`} element={<Navigate to={`/`} />} />
        </Routes>
      )}
    </Layout>
  )
}

export default MyAccount
