import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AccountLayout } from '../../../components/Account/AccountLayout/AccountLayout'
import { AccountContent } from '../../../components/Account/AccountContent/AccountContent'
import { Link } from 'react-router-dom'
import { ShippingAddressDetails } from '../../'
import lockIcon from '../../../assets/images/lock-icon.svg'
import { getTranslatedMessage } from '../../../utils'

const AccountProfile = ({ crumbs, title, contentBody, contentTitle }) => {
  const { t } = useTranslation()
  const user = useSelector(state => state.userReducer)
  return (
    <div className="container mt-0 mt-sm-4">
      <div className="row account-wrapper flex-lg-row flex-column-reverse">
        <AccountLayout crumbs={crumbs} title={title} />

        <div className="col-sm-12 col-md-12 col-lg-8 order-sm-0 main-content p-3 bg-light">
          <AccountContent />

          <div className="container p-3 bg-white rounded">
            <h5 className="brand-blue">{t('frontend.account.personalDetails')}</h5>

            <div className="row align-right-responsive">
              <div className="col-4 py-1">
                <span className="fs-14">{t('frontend.account.form.name')}</span>
              </div>
              <div className="col-8 py-1">
                <span className="fw-bold">{user?.firstName + ' ' + user?.lastName}</span>
              </div>
              <div className="col-4 py-1">
                <span className="fs-14">{t('frontend.contact.form.phoneNumber.title')}</span>
              </div>
              <div className="col-8 py-1">
                <span className="fs-14">{user?.primaryPhoneNumber?.phoneNumber}</span>
              </div>
              <div className="col-4 py-1">
                <span className="fs-14">{t('frontend.profile.address')}</span>
              </div>
              <div className="col-8 py-1">
                <ShippingAddressDetails className="fs-14" />
              </div>
              <div className="col-4 py-1">
                <span className="fs-14">{t('frontend.contact.form.emailAddress')}</span>
              </div>
              <div className="col-8 py-1">
                <span className="fs-14">{user?.primaryEmailAddress?.emailAddress}</span>
              </div>
            </div>

            <p className="mb-0 text-muted fs-12">{t(getTranslatedMessage('frontend.account.personal.disclaimer'))}</p>
          </div>
          <div className="row my-2">
            <div className="col-md-6 col-sm-12 justify-content-start">
              <Link className="btn btn-primary mt-2" to="/my-account/profile-center" state={{ pageTitleText: 'MY ACCOUNT' }}>
                {t('frontend.account.updateProfilePreferences')}
              </Link>
            </div>
            <div className="col-md-6 col-sm-12 justify-content-start">
              <Link className="btn btn-primary mt-2" to="/my-account/updatePassword">
                <img className="pe-2 my-account-coins-icon" src={lockIcon} alt="lock icon" />
                {t('frontend.account.password_update')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { AccountProfile }
