import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import creditCorpLogo from '../../assets/images/radiorentals-logo.png'
import rrLogo from '../../assets/images/rr-logo.png'
import iconRepair from '../../assets/images/icon_repair.png'
import iconRefund from '../../assets/images/icon_refund.png'
import { getCurrentSite } from '../../actions/configActions'

const UtilityBar = ({ socialItems = [] }) => {
  if (!socialItems) {
    return null
  }

  return (
    <nav className="navbar-default top-bar bg-brand-navy text-white">
      <div className="container-xl">
        <div className="row justify-content-md-start justify-content-end">
          <div className="col-auto">
            <ul className="nav ms-auto justify-content-start">
              <li>
                <Link to="/faq" className="nav-link text-white">
                  FAQS
                </Link>
              </li>
              <li>
                <Link to="/contact" className="nav-link text-white">
                  CONTACT US
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}

const Header = () => {
  const social_items = useSelector(state => state.content?.header?.social_menu?.social_items)
  const location = useLocation()
  const currentSiteCode = getCurrentSite()
  const logoBranding = location.pathname.includes('/privacy') || currentSiteCode === 'rr' ? rrLogo : creditCorpLogo
  const logoAltText = location.pathname.includes('/privacy') || currentSiteCode === 'rr' ? 'RR Logo' : 'Radio Rentals Logo'
  const logoWidth = location.pathname.includes('/privacy') || currentSiteCode === 'rr' ? '80px' : '300px'
  const content = useSelector(state => state.content[location.pathname.substring(1)])
  let title = ''
  if (location.state?.pageTitleText) {
    title = location.state.pageTitleText
  } else if (content?.title) {
    // eslint-disable-next-line
    title = content.title
  }

  return (
    <>
      <UtilityBar socialItems={social_items} />
      <nav className="main-header my-3 no-print mt-0 mb-0 navbar-expand-md">
        <div className="container-xl container-fluid justify-content-between align-items-center py-2 my-0 d-flex m-auto w-100">
          <div className="order-xl-1">
            <Link className="d-block" to="/">
              <span className="navbar-brand d-block">
                <img src={logoBranding} className="img-fluid" alt={logoAltText} width={logoWidth} />
              </span>
            </Link>
          </div>
          <div className="order-xl-2">
            <a href="tel:131 181" className="text-white">
              <i className="bi bi-telephone-fill px-2 text-white" />
              131 181
            </a>
          </div>
        </div>
      </nav>
      <div className="wrap-top-nav">
        <div className="top-banner-main">
          <ul className="align-items-center p-0 m-0 d-flex justify-content-center list-unstyled">
            <li className="top-banner_right">
              <img src={iconRepair} width="30px" alt="Repair Replacement Included" />
              &nbsp;{' '}
              <Link to="/repair-replacement-application" style={{ color: '#595959' }}>
                Repair &amp; Replacement Included**
              </Link>
            </li>
            &nbsp;&nbsp;&nbsp;
            <li className="top-banner_right">
              <img src={iconRefund} width="30px" alt="Refunds Application Form" />
              &nbsp;
              <Link to="/refunds" style={{ color: '#595959' }}>
                Refunds Application Form
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export { Header }
