import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { getBrandRoute, getCategoryRoute } from '@ten24/slatwall-storefront-react-private/selectors'

const ProductDetailHeading = ({ sku, product, total, averageRating }) => {
  const categoryRoute = useSelector(getCategoryRoute)
  const { t } = useTranslation()
  return (
    <>
      {!!product.categories?.length && (
        <div className="row">
          <div className="col category-links">
            <strong className="mb-2 text-muted text-uppercase small">{t('frontend.product.category')}: </strong>
            {product.categories.map(category => (
              <Link key={category.categoryID} className="link text-decoration-none" to={`/${categoryRoute}/${category.urlTitle}`}>
                {category.categoryName}
              </Link>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

const ProductDetails = ({ sku, product }) => {
  const brand = useSelector(getBrandRoute)
  return (
    <>
      {!!product.brand_brandName?.trim()?.length && (
        <div className="row">
          <div className="col px-0">
            <Link className="link text-decoration-none" to={`/${brand}/${product.brand_urlTitle}`}>
              {product.brand_brandName}
            </Link>
          </div>
        </div>
      )} 
      <h1 className="fw-bold mb-3 mt-2">{product.productName}</h1>
      <ProductDetailHeading product={product} /> 
    </>
  )
}
export { ProductDetails, ProductDetailHeading }