import { ProductCard } from '../../components/ProductCard/ProductCard'
import ContentLoader from 'react-content-loader'
import { NoProductFound } from './NoProductFound'
import { InterestDisclaimer } from '../../components/InterestDisclaimer/InterestDisclaimer'
const ListingGridLoader = props => (
  <ContentLoader viewBox="0 0 1200 500" className="listingGridLoader" {...props}>
    <rect x="100" y="20" rx="8" ry="8" width="300" height="300" />
    <rect x="100" y="350" rx="0" ry="0" width="300" height="32" />
    <rect x="100" y="400" rx="0" ry="0" width="180" height="36" />

    <rect x="500" y="20" rx="8" ry="8" width="300" height="300" />
    <rect x="500" y="350" rx="0" ry="0" width="300" height="36" />
    <rect x="500" y="400" rx="0" ry="0" width="180" height="30" />

    <rect x="900" y="20" rx="8" ry="8" width="300" height="300" />
    <rect x="900" y="350" rx="0" ry="0" width="300" height="32" />
    <rect x="900" y="400" rx="0" ry="0" width="180" height="36" />
  </ContentLoader>
)

const ListingGrid = ({ isFetching, pageRecords }) => {
  return (
    <div className="col gx-sm-0 gx-md-5">
      {isFetching && (
        <>
          <ListingGridLoader /> <ListingGridLoader /> <ListingGridLoader />
        </>
      )}
      {!isFetching && pageRecords.length > 0 && (
        <>   
        <div className="product-grid py-sm-0 py-md-4">
          {pageRecords.map((product, index) => {
            return (
              <div key={`${product.productName}${index}`} className="productCard mb-md-0 mb-sm-2">
                <ProductCard {...product} />
              </div>
            )
          })}
        </div>
        <div className="container px-1">
          <div className="row">
            <div className="col-12 px-3">
            <InterestDisclaimer />
            </div>
          </div> 
        </div>
        </>
      )}
      {!isFetching && pageRecords.length === 0 && <NoProductFound />}
    </div>
  )
}
export { ListingGrid }
