import { useGetProductsByEntityModified } from '@ten24/slatwall-storefront-react-private/hooks'
import { useEffect } from 'react'
import ContentLoader from 'react-content-loader'
import { useTranslation } from 'react-i18next'
import { ProductSliderWithConfig } from '../../components/ProductSlider/ProductSlider'
import { NoProductFound } from '../Listing/NoProductFound'
import { ProductCard } from '../ProductCard/ProductCard'
// import { Button } from '@ten24/slatwall-storefront-react-private/components'

const FeaturedSection = () => {
  const { t } = useTranslation()
  let [request, setRequest] = useGetProductsByEntityModified()
  let [requestEssential, setRequestEssential] = useGetProductsByEntityModified()

  useEffect(() => {
    let didCancel = false
    if (!didCancel && !request.isFetching && !request.isLoaded) {
      setRequest({
        ...request,
        params: {
          includeImages: true,
          'f:publishedFlag': 1,
          'f:productFeaturedFlag': 1,
          'includeSkus': true
        },
        entity: 'product',
        makeRequest: true,
        isFetching: true,
        isLoaded: false,
      })
    }
    if (!didCancel && !requestEssential.isFetching && !requestEssential.isLoaded) {
      setRequestEssential({
        ...requestEssential,
        params: {
          includeImages: true,
          'f:publishedFlag': 1,
          'f:essentialFlag': 1,
          'includeSkus': true
        },
        entity: 'product',
        makeRequest: true,
        isFetching: true,
        isLoaded: false,
      })
    }
    return () => {
      didCancel = true
    }
  }, [requestEssential, setRequestEssential, request, setRequest])

  const ListingGridLoader = props => (
    <ContentLoader viewBox="0 0 1200 500" className="listingGridLoader" {...props}>
      <rect x="100" y="20" rx="8" ry="8" width="300" height="300" />
      <rect x="100" y="350" rx="0" ry="0" width="300" height="32" />
      <rect x="100" y="400" rx="0" ry="0" width="180" height="36" />

      <rect x="500" y="20" rx="8" ry="8" width="300" height="300" />
      <rect x="500" y="350" rx="0" ry="0" width="300" height="36" />
      <rect x="500" y="400" rx="0" ry="0" width="180" height="30" />

      <rect x="900" y="20" rx="8" ry="8" width="300" height="300" />
      <rect x="900" y="350" rx="0" ry="0" width="300" height="32" />
      <rect x="900" y="400" rx="0" ry="0" width="180" height="36" />
    </ContentLoader>
  )

  if (!request.data.length) {
    return null
  }

  return (
    <section>
    <div className="container">
      <ul className="nav row nav-pills text-center d-flex justify-content-center px-0 pb-2" id="pills-tab" role="tablist">
        <li className="col-md-3 col-6 nav-item d-inline-block" style={{}} role="presentation">
          <a className="featured featured-selection-tab-link nav-link active" href="#pills-featured" id="pills-featured-tab" data-bs-toggle="pill" data-bs-target="#pills-featured" type="button" role="tab" aria-controls="pills-featured" aria-selected="true" >
            {`${t('frontend.productType.featuredTitle')}`}
          </a>
        </li>
        <li className="col-md-3 col-6 nav-item d-inline-block" style={{}} role="presentation">
          <a className="featured featured-selection-tab-link nav-link" href="#pills-profile" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" >
            {`${t('frontend.productType.everyDayEssentials')}`}
          </a>
        </li>
      </ul>
    </div>
      <div className="tab-content" id="pills-tabContent">
        <div className="tab-pane fade show active" id="pills-featured" role="tabpanel" aria-labelledby="pills-featured-tab">
          <div className="d-none d-md-block">
          <ProductSliderWithConfig
            params={{
              'f:publishedFlag': 1,
              'f:productFeaturedFlag': 1,
              'includeSkus': true
            }}
          />
          </div>
          <div className="col gx-5 d-md-none d-block-sm">
            {request?.isFetching && (
              <>
                <ListingGridLoader /> <ListingGridLoader /> <ListingGridLoader />
              </>
            )}
            {!request?.isFetching && request?.data.length > 0 && (
              <>
                <div className="product-grid-homepage pb-5">
                  {request?.data.map((product, index) => {
                    return (
                      <div key={`${product.productName}${index}`} className="productCard px-2">
                        <ProductCard {...product} />
                      </div>
                    )
                  })}
                </div>

              </>
            )}
            {!request?.isFetching && request?.data.length === 0 && <NoProductFound />}
          </div>
          {/* <ListingGrid isFetching={request?.isFetching} pageRecords={request?.data} /> */}
        </div>

        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
        <div className="d-none d-md-block">
          <ProductSliderWithConfig
            params={{
              'f:publishedFlag': 1,
              'f:essentialFlag': 1,
              'includeSkus': true
            }}
          />
          </div>
            {requestEssential?.isFetching && (
              <>
               <div className="d-none d-md-block">
                <ListingGridLoader /> <ListingGridLoader /> <ListingGridLoader />
                </div>
              </>
            )}
            {!requestEssential?.isFetching && requestEssential?.data.length > 0 && (
              <>  
                <div className="product-grid-homepage pb-5 d-sm-block d-md-none">
                  {requestEssential?.data.map((product, index) => {
                    return (
                      <div key={`${product.productName}${index}`} className="productCard px-2">
                        <ProductCard {...product} />
                      </div>
                    )
                  })}
                </div>
              </>
            )}
            {!requestEssential?.isFetching && requestEssential?.data.length === 0 && <NoProductFound />}
          </div>
        </div>
   
    </section>
  )
}

export { FeaturedSection }