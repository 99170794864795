import React from 'react'
import { useSelector } from 'react-redux'
import styles from './Footer.module.css'
import rightArrow from '../../assets/images/right-arrow.svg'
import { RedirectForm } from '../Header/RedirectForm'

function Footer() {
  const content = useSelector(state => state.content)
  const footer = content['footer']
  return (
    <footer className="footer">
      <div className="container-xl pt-4">
        <div className="d-none d-md-flex row justify-content-between pt-2">
          <div className="px-0">
            <div className="container p-0 mb-4">
              <small className={styles.footerColumns}>
                <ul className="d-flex">
                  <li className="my-2 pe-3">
                    <strong>
                      <a href="/privacy" className="justify-content-between align-items-center d-flex">
                        <span>PRIVACY POLICY</span>
                      </a>
                    </strong>
                  </li>

                  <li className="my-2 pe-3">
                    <strong>
                      <a href="/terms-and-conditions" className={`justify-content-between align-items-center d-flex ${styles.footerTCLink}`}>
                        <span>TERMS AND CONDITIONS</span>
                      </a>
                    </strong>
                  </li>
                  <li className="my-2 pe-3">
                    <strong>
                      <a href="/contact" className="justify-content-between align-items-center d-flex">
                        <span>CONTACT US</span>
                      </a>
                    </strong>
                  </li>
                  <li className="my-2 pe-3">
                    <strong>
                      <a href="/faq" className="justify-content-between align-items-center d-flex">
                        <span>FAQ'S</span>
                      </a>
                    </strong>
                  </li>
                  <li className="my-2 pe-3">
                    <strong>
                      <a href="/make-a-payment" className="justify-content-between align-items-center d-flex">
                        <span>PAY MY ACCOUNT</span>
                      </a>
                    </strong>
                  </li>
                </ul>
              </small>
            </div>
          </div>
          {footer?.children?.filter(item => item.urlTitle.includes('column')).map(column => {
            return (
              <div key={column.urlTitle} className="main-links px-0">
                <div className="container p-0 mb-4">
                  <small className={styles.footerColumns} dangerouslySetInnerHTML={{ __html: column?.contentBody }} />
                </div>
              </div>
            )
          })}

          <div className="px-0 py-4">
            <div className="container p-0">
              <div className="justify-content-space-between">
                <div className="col-md-12 col-sm-12 p-0">
                  <div className={styles.copyrightText}>
                    {footer?.children?.filter(item => item.urlTitle.includes('static')).map(column => {
                    return (
                    <div key={column.urlTitle} className="col-md-12 px-0">
                      <div className="pb-2 mb-4">
                      <p className={styles.footerColumns} dangerouslySetInnerHTML={{ __html: column?.contentBody }} />
                    </div>
                  </div>
                  )
                })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex d-md-none row pt-2">
          <div className={styles.footerColumnOneMobile}>
            <div>
              <small className={styles.footerColumns}>
                <ul className="p-0 container border-0">
                  <li>
                    <strong>
                      <a href="/privacy" className="justify-content-between align-items-center d-flex ">
                        <span>
                          PRIVACY <br className="d-block d-md-none " />
                          POLICY
                        </span>
                        <img src={rightArrow} alt="Arrow icon" className="text-white d-block d-md-none" />
                      </a>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <a href="/faq" className="justify-content-between align-items-center d-flex ">
                        <span>FAQ'S</span>
                        <img src={rightArrow} alt="Arrow icon" className="text-white d-block d-md-none" />
                      </a>
                    </strong>
                  </li>
                  {/* <br className="d-block d-md-none " /> */}
                  <li className="border-0 pb-0">
                    <strong>
                      <a href="/make-a-payment" className="justify-content-between align-items-center d-flex ">
                        <span>PAY MY ACCOUNT</span>
                        <img src={rightArrow} alt="Arrow icon" className="text-white d-block d-md-none" />
                      </a>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <a href="/terms-and-conditions" className="justify-content-between align-items-center d-flex ">
                        <span>
                          TERMS AND <br className="d-block d-md-none " />
                          CONDITIONS
                        </span>
                        <img src={rightArrow} alt="Arrow icon" className="text-white d-block d-md-none" />
                      </a>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <a href="/contact" className="justify-content-between align-items-center d-flex ">
                        <span>CONTACT US</span>
                        <img src={rightArrow} alt="Arrow icon" className="text-white d-block d-md-none" />
                      </a>
                    </strong>
                  </li>
                  {/* <br className="d-block d-md-none " /> */}

                  {/* <br className="d-block d-md-none " /> */}
                </ul>
              </small>
            </div>
          </div>

          {footer?.children?.filter(item => item.urlTitle.includes('column')).map(column => {
            return (
              <div key={column.urlTitle} className="py-4">
                <div className="p-0 container main-links">
                  <small className={styles.footerColumns} dangerouslySetInnerHTML={{ __html: column?.contentBody }} />
                </div>
              </div>
            )
          })}

          <hr className={styles.divider}></hr>

          <div className="py-4 px-md-0 terms-text">
            <div className="container p-0">
              <div className="justify-content-space-between">
                <div className="col-md-12 col-sm-12">
                  <div className={`footer-copyright ${styles.copyrightText}`}>
                    {footer?.children?.filter(item => item.urlTitle.includes('static')).map(column => {
                    return (
                    <div key={column.urlTitle} className="col-md-12 px-0">
                      <div className="pb-2 mb-4">
                      <p className={styles.footerColumns} dangerouslySetInnerHTML={{ __html: column?.contentBody }} />
                    </div>
                  </div>
                  )
                })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-none">
        <RedirectForm />
      </div>
    </footer>
  )
}

export { Footer }
