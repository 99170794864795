import { Blocks, ContentBlock, ContentColumns, ListingGrid, ListingPagination, ListItem, SimpleTabs } from '@ten24/slatwall-storefront-react-private/components'
import { useBasicPage, useUtilities } from '@ten24/slatwall-storefront-react-private/hooks'

const BasicPage = () => {
  const { content, request, setPage } = useBasicPage()
  let { eventHandlerForWSIWYG } = useUtilities()
  return (
    <div className={`p-0 ${content.urlTitle === 'faq' ? 'bg-lightblue' : ''}`}>
      <div className="pt-4 d-md-none d-sm-block">
        <div className="container py-4">
          <div className="order-lg-1 pr-lg-4 text-center">
            <h1 className="h2 mb-0 font-accent text-black">{content.title || ''}</h1>
          </div>
        </div>
      </div>
      <div className="container-xl container-custom-lg py-4">
        {content.isMarkup && (
          <div
            className="content-body"
            onClick={eventHandlerForWSIWYG}
            dangerouslySetInnerHTML={{
              __html: content.contentBody || '',
            }}
          />
        )}
        {content.isMarkup && (
          <div
            className="content-body"
            onClick={eventHandlerForWSIWYG}
            dangerouslySetInnerHTML={{
              __html: content.contentSummary || '',
            }}
          />
        )}
        {!content.isMarkup &&
          content.sections &&
          content.sections.map(({ title, text, imageUrl }) => {
            return (
              <div key={title}>
                <h2>{title}</h2>
                <div className="row">
                  <div className="col-md-8">{text}</div>
                  <div className="col-md-4">{imageUrl !== '' && <img className="float-end ml-md-2 mb-md-2 about-Img" src={imageUrl} alt={title} />}</div>
                </div>
              </div>
            )
          })}
        {content?.tabs?.length > 0 && <SimpleTabs data={content.tabs} />}
        {content?.contentColumns?.columns?.length > 0 && (
          <ContentColumns title={content.contentColumns?.title}>
            <div className="row">
              <div className="col">
                <div onClick={eventHandlerForWSIWYG} dangerouslySetInnerHTML={{ __html: content?.contentColumns?.contentBody }} />
              </div>
            </div>
            <div className="row justify-content-center">
              {content.contentColumns.columns?.map((column, index) => {
                return (
                  <div key={`${column.title}-${index}`} className={`col-lg-${12 / content?.contentColumns?.columns?.length} pr-4-lg`}>
                    <ContentBlock {...column} />
                  </div>
                )
              })}
            </div>
          </ContentColumns>
        )}

        {content.listItems.map(item => {
          return <ListItem {...item} />
        })}
        {content?.blocks?.length > 0 && <Blocks blocks={content.blocks} />}

        {content?.productListingPageFlag && (
          <>
            <hr />
            <div className="col">
              <ListingGrid isFetching={request.isFetching} pageRecords={request.data.pageRecords} />
              <ListingPagination recordsCount={request.data.recordsCount} currentPage={request.data.currentPage} totalPages={request.data.totalPages} setPage={setPage} />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default BasicPage
