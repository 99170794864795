import { useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { usePostalCode } from '../../hooks'
import { useTranslation } from 'react-i18next'


const PostCodeSelection = () => {
  const { t } = useTranslation()

  const [isShow, show] = useState(false);
  const { handleSearch, isLoading, options, selected, setSelected, postalCodeData, setPostalCode } = usePostalCode();

  const editLocation = () => {
    show(true)
  }
  
  const filterBy = () => true

  return (
    <>{isShow &&
      <div className="row">
        <div className="col-md-6">
          <AsyncTypeahead
            className="border"
            filterBy={filterBy}
            id="postalCode"
            isLoading={isLoading}
            labelKey={option => `${option.address_postalCode} ${option.address_city}`}
            minLength={3}
            onSearch={handleSearch}
            options={options}
            placeholder="Enter Postal Code..."
            onChange={setSelected}
          />
        </div>
        <div className='col-md-3'><button type="button" className="btn btn-primary"

          onClick={() => {
            var address = {
              city: selected[0].address_city,
              stateCode: selected[0].address_stateCode,
              postalCode: selected[0].address_postalCode,
              countryCode: selected[0].address_countryCode,
              isDefaultAddress: false
            };

            setPostalCode(
              { ...postalCodeData, ...address }
            )
            show(false)
          }}
        >
          {t('frontend.core.save')}
        </button></div>
        <div className='col-md-3'><button type="button" className="btn btn-primary"

          onClick={() => {
            show(false)
          }}
        >
           {t('frontend.core.cancel')}
        </button></div>
      </div>
    } {!isShow &&
        <button className="btn btn-secondary w-100" onClick={editLocation} >
          <i className="bi bi-geo-alt-fill px-2"></i>{t('frontend.postalcodeselection.deliverto')} {postalCodeData.city} {postalCodeData.postalCode}
        </button>
      }</>
  )
}
/* example-end */


export { PostCodeSelection }