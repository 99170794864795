import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFormatCurrency } from '@ten24/slatwall-storefront-react-private/hooks/'
import { disableInteractionSelector, isVatCountry } from '@ten24/slatwall-storefront-react-private/selectors'
import { removePromoCode } from '@ten24/slatwall-storefront-react-private/actions'

const OrderSummary = () => {
  const cart = useSelector(state => state.cart)
  const { total, VATTotal, subtotal, discountTotal, promotionCodes } = cart
  const [formatCurrency] = useFormatCurrency({})
  const showVat = useSelector(isVatCountry)
  const { t } = useTranslation()
  const disableInteraction = useSelector(disableInteractionSelector)
  const dispatch = useDispatch()
  return (
    <>
      <div className="mb-4 border-none">

        <ul className="list-group list-group-flush">
          <li className="d-flex justify-content-between px-0 py-2">
            <h6 className="my-0 brand-navy subtotal fs-20"> {t('frontend.checkout.subTotal')}</h6>
            <span className="float-end subtotal brand-navy">
              {subtotal > 0 ? formatCurrency(subtotal) : '--'} <span className="cart-per-Week text-muted">{t('frontend.cart.per_week')}</span>
            </span>
          </li>
          <li className="d-flex justify-content-between px-0 py-2">
            <h6 className="my-0 brand-navy small"> {t('frontend.cart.shippingDelivery')}</h6>
            <span className="float-end text-end small">
              <span className="postcode-included text-muted"> {t('frontend.cart.included')}</span>
            </span>

          </li>
          {promotionCodes.length > 0 && (
            <>
              <li className="d-flex justify-content-between px-0 py-2">
                <div className="text-success">
                  <h6 className="my-0 brand-navy"> {t('frontend.cart.discount')}</h6>
                </div>
                <span className="float-end align-center">
                  <span className="text-success">{discountTotal > 0 ? formatCurrency(discountTotal) : '--'}</span>
                </span>
              </li>
              <li className="d-flex justify-content-between px-0 py-2">
                {promotionCodes.map(promotionCodeItem => {
                  //TODO: Review
                  const { promotionCode } = promotionCodeItem
                  return (
                    <div key={promotionCode}>
                      <button
                        className="btn badge bg-success promo-btn"
                        type="button"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Remove Promotion"
                        key={promotionCode}
                        disabled={disableInteraction}
                        onClick={event => {
                          event.preventDefault()
                          dispatch(removePromoCode(promotionCode, undefined, t('frontend.cart.promo_code_removed')))
                        }}
                      >
                        <i className="bi bi-x"></i>
                        <span className="font-size-sm">{promotionCode}</span>
                      </button>
                    </div>
                  )
                })}
              </li>
            </>
          )}
          <li className="d-flex justify-content-between px-0 py-2 border-top">
            <h6 className="my-0 total brand-navy font-weight-bold fs-20">{t('frontend.cart.total')}</h6>
            <span className="subtotal brand-navy small">
              <span className="font-weight-bold fs-20">{total > 0 ? formatCurrency(total) : '--'}</span> <span className="cart-per-Week text-muted">{t('frontend.cart.per_week')}</span>
            </span>
          </li>
          {showVat && (
            <li className="d-flex justify-content-between px-0 py-2">
              <h6 className="my-0">{t('frontend.cart.vat')}</h6>
              <span className="float-end">
                <strong>{VATTotal > 0 ? formatCurrency(VATTotal) : '--'}</strong>
              </span>
            </li>
          )}
        </ul>
      </div>
    </>
  )
}

export { OrderSummary }
