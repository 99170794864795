import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import * as Yup from 'yup'
import { SlatwalApiService } from '@ten24/slatwall-storefront-react-private/services'
import { toast } from 'react-toastify'
import { errorLogin, receiveLogin, receiveUser, receiveCart, getWishLists, requestLogin, requestUser, requestCart } from '@ten24/slatwall-storefront-react-private/actions/'
import { getErrorMessage } from '@ten24/slatwall-storefront-react-private/utils'
const useLoginForm = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  // eslint-disable-next-line
  const sites = useSelector(state => state.configuration.sites)
  // const rrSite = sites?.filter(site => site?.siteCode === 'rr')
  // const creditcorpgroupSite = sites?.filter(site => site?.siteCode === 'creditcorpgroup')
  const getCookie = name => {
    return document.cookie.split('; ').reduce((r, v) => {
      const parts = v.split('=')
      return parts[0] === name ? decodeURIComponent(parts[1]) : r
    }, '')
  }

  const setCookie = (name, value, days = 7, path = '/') => {
    const expires = new Date(Date.now() + days * 864e5).toUTCString()
    document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path
  }

  const formik = useFormik({
    initialValues: {
      loginEmail: getCookie('username'),
      loginPassword: '',
      rememberMe: false,
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      loginEmail: Yup.string().email('Invalid email').required('Required'),
      loginPassword: Yup.string().required('Required'),
    }),
    onSubmit: values => {
      dispatch(requestLogin())
      dispatch(requestUser())
      dispatch(requestCart())

      const payload = {
        emailAddress: values.loginEmail,
        password: values.loginPassword,
        returnJSONObjects: 'account,cart,orderTemplateCart',
      }

      SlatwalApiService.auth.login(payload).then(response => {
        if (response.isSuccess() && (response.success().errors === undefined || response.success().errors === null)) {
          dispatch(receiveLogin({ isAuthenticanted: true }))
          dispatch(receiveUser(response.success().account))

          dispatch(receiveCart(response.success().cart))
          dispatch(getWishLists())
          toast.success(t('frontend.account.auth.success'))
          if (values.rememberMe) {
            setCookie('username', values.loginEmail)
          } else {
            setCookie('username', '')
          }
        } else {
          let errorMessage = response.isSuccess() && response.success() && Object.keys(response.success()?.errors || {}).length ? getErrorMessage(response.success().errors) : t('frontend.account.auth.failure')
          if (errorMessage) toast.error(errorMessage)
          dispatch(errorLogin())
        }
        formik.setSubmitting(false)
      })
    },
  })

  return { formik }
}

export { useLoginForm }
