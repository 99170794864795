import { useUtilities } from '@ten24/slatwall-storefront-react-private/hooks'
import { useTranslation } from 'react-i18next'
const ProductInfo = product => {
  const { t } = useTranslation()
  const { eventHandlerForWSIWYG } = useUtilities()
  return (
    <>
      <div className="container my-4">
        <h3 className="accordion-heading">{`${t('frontend.product.detail.accordion.title')}`}</h3>
        <div className="accordion" id="productDetailAccordion">
          {product?.productDescription && (
            <div className="accordion-item">
              <h2 className="accordion-header" id="productDescription">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  {`${t('frontend.product.detail.accordion.descriptionTabTitle')}`}
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="productDescription" data-bs-parent="#productDetailAccordion">
                <div
                  className="accordion-body"
                  dangerouslySetInnerHTML={{
                    __html: product?.productDescription,
                  }}
                ></div>
              </div>
            </div>
          )}
          {(product?.Manuals.trim() !== '' || product?.Specifications.trim() !== '' || product?.Warranty.trim() !== '') && (
            <div className="accordion-item">
              <h2 className="accordion-header" id="specificationTab">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  {`${t('frontend.product.detail.accordion.specificationTabTitle')}`}
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="specificationTab" data-bs-parent="#productDetailAccordion">
                <div className="accordion-body">
                  <div
                    onClick={eventHandlerForWSIWYG}
                    dangerouslySetInnerHTML={{
                      __html: product?.Specifications || '',
                    }}
                  />
                  <div
                    onClick={eventHandlerForWSIWYG}
                    dangerouslySetInnerHTML={{
                      __html: product?.Manuals || '',
                    }}
                  />
                  <div
                    onClick={eventHandlerForWSIWYG}
                    dangerouslySetInnerHTML={{
                      __html: product?.Warranty || '',
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ProductInfo