
import { SlatwalApiService } from '@ten24/slatwall-storefront-react-private/services'
import { requestCart, requestUser, receiveCart, receiveUser } from '@ten24/slatwall-storefront-react-private/actions'
import { getErrorMessage } from '@ten24/slatwall-storefront-react-private/utils'
import { toast } from 'react-toastify'

export const REQUEST_REDIRECT_URL = 'REQUEST_REDIRECT_URL'
export const RECEIVE_REDIRECT_URL = 'RECEIVE_REDIRECT_URL'

export const requestRedirectUrl = () => {
  return {
    type: REQUEST_REDIRECT_URL,
  }
}

export const receiveRedirectUrl = (redirectData = {}) => {
  return {
    type: RECEIVE_REDIRECT_URL,
    redirectData,
  }
}

export const getApplyNowMsg = () => {
  return async dispatch => {
    dispatch(requestCart())
    dispatch(requestUser())
    return await SlatwalApiService.sdkScope.httpService.slatwallResponse('get', `api/ccg/getApplyNowMsg`, {
      "returnJSONObjects": "cart,account"
    }, {}, null).then(response => {
      if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
      if (response.isSuccess()) {
        dispatch(receiveUser(response.success().account))
        dispatch(receiveCart(response.success().cart))
      } else {
        dispatch(receiveCart())
        dispatch(receiveUser())
      }
    })
  }
}


export const validateCart = (params = {}) => {
  return async dispatch => {
    dispatch(requestCart())
    dispatch(requestUser())

    return await SlatwalApiService.sdkScope.httpService.slatwallResponse('post',
      `api/scope/postValidateCCGCart`,
      {
        "context": "validateCart",
        "returnJSONObjects": "cart,account"
      },
      {}, null).then(response => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
        if (response.isSuccess()) {
          dispatch(receiveUser(response.success().account))
          dispatch(receiveCart(response.success().cart))
        } else {
          dispatch(receiveCart())
          dispatch(receiveUser())
        }
      })
  }
}


export const getRedirectUrl = () => {
  return async dispatch => {
    dispatch(requestRedirectUrl())
    return await SlatwalApiService.sdkScope.httpService.slatwallResponse('get', `api/ccg/getCCGRedirectURL`, {}, {}, null).then(response => {
      if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors));
      if (response.isSuccess()) {
        dispatch(receiveRedirectUrl(
          {
            redirectURL: response.success().redirectURL,
            accessToken: response.success().accessToken,
            refreshToken: response.success().refreshToken
          }))
      } else {
        receiveRedirectUrl()
      }
    })
  }
}