import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from '@ten24/slatwall-storefront-react-private/components'
import { useTranslation } from 'react-i18next'
import { HomeSlider } from '../../components/ContentSlider/HomeSlider'
import { RowBlock } from '../../components/RowBlock/RowBlock'
import { Layout } from '../../components/Layout/Layout'
import { getCookie, getCurrentSite, setCookie } from '../../actions/configActions'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { usePostalCode } from '../../hooks'
import { hasValidPostalCode } from '../../utils'

function Home() {
  const { home = {} } = useSelector(state => state.content)
  const { slider, blocks } = home
  const [showPostalCodeModal, setPostalCodeModal] = useState(false)
  const filterBy = () => true
  const currentSite = getCurrentSite()
  const { handleSearch, isLoading, options, selected, setSelected, postalCodeData, setPostalCode, isRedirectRequired } = usePostalCode()

  useEffect(() => {
    if (!hasValidPostalCode() && blocks?.length > 0) {
      setPostalCodeModal(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocks])
  if (getCookie('firsttimeuser') !== 'true') setCookie('firsttimeuser', true, 9999)

  const { t } = useTranslation()
  return (
    <Layout>
      <div className={`${currentSite}-modal`}>
        <Modal show={showPostalCodeModal} setShow={setPostalCodeModal} title="" modalclassName="rr-modal" size="medium">
          <div id="modal-overlay">
            {!isRedirectRequired && (
              <div className="popup-form">
                <div className="content post-code-">
                  <h3>Enter Your Postcode or Suburb</h3>
                  <p>Please enter your postcode or suburb and we will personalise your renting experience</p>
                </div>
                <div className="d-flex ow justify-content-md-center pb-2">
                  <div className="col-10 pe-1">
                    <AsyncTypeahead style={{ padding: '0px 0px' }} filterBy={filterBy} id="postalCode" isLoading={isLoading} labelKey={option => `${option.address_postalCode} ${option.address_city}`} minLength={4} onSearch={handleSearch} options={options} placeholder="Enter Postal Code..." onChange={setSelected}></AsyncTypeahead>
                  </div>
                  <div className="col-2 m-auto">
                    <button
                      type="button"
                      className="btn bg-brand-blue text-white btn-full"
                      onClick={() => {
                        var address = {
                          city: selected[0].address_city,
                          stateCode: selected[0].address_stateCode,
                          postalCode: selected[0].address_postalCode,
                          countryCode: selected[0].address_countryCode,
                          isDefaultAddress: false,
                        }
                        setPostalCode({ ...postalCodeData, ...address })
                      }}
                      style={{ padding: '14px' }}
                    >
                      {t('frontend.endofcontract.submit')}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {/* {postalCodeData.stateCode} */}
            {isRedirectRequired && selected[0].address_stateCode === 'SA' && (
              <h5 className="text-danger text-center">
                Redirecting to
                <a href="https://www.rr.com.au/" className="brand-navy">
                  {' '}
                  https://www.rr.com.au/
                </a>{' '}
                as you entered a postcode in South Australia.
                <a href="https://www.rr.com.au/" className="brand-navy mt-2">
                  {' '}
                  https://www.rr.com.au/
                </a> {' '}
                services customers in South Australia as well as some other parts of Australia.
              </h5>
            )}
            {isRedirectRequired && selected[0].address_stateCode !== 'SA' && (
              <h5 className="text-danger text-center">
                Redirecting to
                <a href="https://www.radio-rentals.com.au/" className="brand-navy">
                  {' '}
                  https://www.radio-rentals.com.au/
                </a>{' '}
                as you entered a postcode outside of South Australia.
                
                <a href="https://www.radio-rentals.com.au/" className="brand-navy mt-2">
                  {' '}
                  https://www.radio-rentals.com.au/
                </a> {' '}
                 services customers in all states other than South Australia.
              </h5>
            )}
          </div>
        </Modal>
      </div>
      <HomeSlider slider={slider} />
      {blocks?.length > 0 &&
        blocks?.map((block, index) => {
          block.title = ''
          return (
            <section key={index}>
              <div className="container py-4 image-block">
                <RowBlock {...block} />
              </div>
            </section>
          )
        })}
    </Layout>
  )
}

export default Home
